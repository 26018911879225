import React from 'react';
import styled from 'styled-components';
import VRService from '../../../models/VRService';
import SelectableDot from './SelectableDot';
import { ISelectable } from '../../../interfaces/interfaces';
import { isMobile } from 'react-device-detect';

interface SelectableCardProps {
  account?: VRService;
  selectable?: ISelectable;
  preselected?: boolean;
  delegate: (selectable: ISelectable, selected: boolean) => void;
}

const Card = styled.div`
  position: relative;
  margin-top: 32px;
  width: ${isMobile ? 165 : 202}px;
  height: ${isMobile ? 140 : 236}px;
  margin-right: ${isMobile ? 8 : 20}px;
  display: flex;
  flex-basis: 1;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  border-style: solid;
  border-color: ${(props) => props.theme.outline};
  border-width: 1px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.light};
  box-shadow: 0 8px 19px 2px ${(props) => props.theme.shadow};
`;

const Image = styled.img`
  -webkit-user-drag: none;
`;

const OtherText = styled.h2`
  font-size: 25px;
  font-weight: 600;
`;

const SelectableCard: React.FC<SelectableCardProps> = ({
  account,
  selectable,
  preselected = false,
  delegate,
}: SelectableCardProps) => {
  const renderCard = () => {
    if (account !== undefined) {
      const logoPath = require(`../../../images/${account.logo}.png`);
      const logoRetinaPath = require(`../../../images/${account.logo}@2x.png`);
      const imgWidth = isMobile ? account.width * 0.7 : account.width;
      const imgHeight = isMobile ? account.height * 0.7 : account.height;

      return (
        <Card
          onClick={() => {
            delegate(account, !preselected);
          }}
        >
          <SelectableDot selected={preselected} />
          <Image
            src={logoPath}
            srcSet={`${logoRetinaPath} 2x`}
            width={imgWidth}
            height={imgHeight}
            alt={account.name}
          />
        </Card>
      );
    } else if (selectable !== undefined) {
      return (
        <Card
          onClick={() => {
            delegate(selectable, !preselected);
          }}
        >
          <SelectableDot selected={preselected} />
          <OtherText>Other</OtherText>
        </Card>
      );
    }
  };

  return <>{renderCard()}</>;
};

export default SelectableCard;
