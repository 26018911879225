import React from 'react';
import styled from 'styled-components';

// Props

interface BadgeProps {
  badgeCount: string;
  style?: React.CSSProperties;
}

// Styles

const StyledBadge = styled.div`
  position: absolute;
  font-size: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.primary};
  width: 23px;
  height: 23px;
  right: 45px;
  top: 1px;
  color: white;
  display: flex;
  align-items: center;
  justify-items: center;
`;

const BadgeText = styled.div`
  width: 100%;
  text-align: center;
`;

// Component

const Badge: React.FC<BadgeProps> = ({ badgeCount, style }: BadgeProps) => {
  return (
    <StyledBadge
      style={{
        ...style,
      }}
    >
      <BadgeText>{badgeCount}</BadgeText>
    </StyledBadge>
  );
};

export default Badge;
