import React, { useState } from 'react';
import styled from 'styled-components';
import ActivityFeed from '../../../../../models/ActivityFeed';
import ActivityItem from '../../../../../models/ActivityItem';
import media from 'styled-media-query';
import UnitNameWithBar from '../../../../cleaning/UnitNameWithBar';
import ActivityMessage from '../../../../activity/ActivityMessage';
import { BrowserView, MobileView } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { apiService } from '../../../../../modules/services/apiService';

// Props

interface ActivityProps {
  feed?: ActivityFeed;
  visible: boolean;
  loadActivity: () => void;
}

// Styles

const ModuleContainer = styled.div`
  flex-direction: column;
  flex-grow: 1;
  margin-top: 40px;
  padding: 30px;
  box-shadow: 0 8px 19px 2px ${(props) => props.theme.shadow};
  border-radius: 6px;
  min-height: 200px;
  position: absolute;

  --width: 700px;
  min-width: var(--width);
  background: #fff;
  top: 10px;
  right: calc((var(--width) / 4 * -1) + 20px);
  width: 100%;
  z-index: 10;
  ${media.greaterThan('medium')`
    min-height: 400px;
  `}
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
    z-index: 10;
    top: -15px;
    right: calc((var(--width) / 4) - 1px);
  }
`;

const NotificationHeader = styled.h2`
  display: flex;
  justify-content: space-between;
`;

const SectionHeader = styled.h2`
  font-size: 18px;
  font-weight: 600;
`;

const ServiceContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  // added negative margin to show read status with overflow scroll
  margin: 12px -20px 0;
  padding: 0 20px;
  max-height: 408px;
  overflow-y: scroll;

  scrollbar-width: auto;
  scrollbar-color: #48b5cf #ffffff;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #48b5cf;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
`;

const ActivityRow = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;
  cursor: pointer;
  position: relative;
  &:hover {
    opacity: 0.6;
  }
  &[data-is-read='false']::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ff6748;
    left: -16px;
    top: calc(50% - 4px);
  }
`;

const ActivityTime = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 600;
  margin: auto;
  flex-grow: 1;
  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-grow: 1;
  ${media.lessThan('medium')`
    flex-direction: column;
    margin-top: 16px;
  `}
`;

const EmptyState = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: center;
  font-weight: 700;
  color: ${(props) => props.theme.emptyState};
`;

const MarkAllAsReadButton = styled.button`
  border: none;
  font-size: 12px;
  color: ${(props) => props.theme.secondary};
  cursor: pointer;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 4px;
  background: #fff;
  &:hover {
    background: #f6f6f6;
  }
`;

// Component

const Activity: React.FC<ActivityProps> = ({
  feed,
  visible,
  loadActivity,
}: ActivityProps) => {
  const history = useHistory();

  const emptyState = (): JSX.Element | null => {
    if (!feed?.activity?.length) {
      return <EmptyState>No activity yet</EmptyState>;
    }
    return null;
  };

  const handleRowClick = async (activity: ActivityItem) => {
    await markAsRead(activity?.id);
    history.push(`/unit/${activity?.unit?.id}`);
  };

  const markAsRead = async (id = '') => {
    const url = id ? `/activity/read/${id}` : '/activity/read';

    try {
      const res = await apiService(url, 'post');
      await res.json();
      loadActivity();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ModuleContainer style={{ display: visible ? 'flex' : 'none' }}>
      <NotificationHeader>
        <SectionHeader>Notifications</SectionHeader>
        {feed?.hasUnreadActivity && (
          <MarkAllAsReadButton onClick={() => markAsRead()}>
            Mark all as read
          </MarkAllAsReadButton>
        )}
      </NotificationHeader>
      {emptyState()}
      <ServiceContainer>
        {feed?.activity?.map((activity, index) => {
          return (
            <ActivityRow
              key={index}
              onClick={() => handleRowClick(activity)}
              data-is-read={activity.isRead}
            >
              <UnitNameWithBar unit={activity.unit} />
              <RightSideContainer>
                <ActivityMessage activity={activity} />
                <ActivityTime>
                  <BrowserView>{activity.activityTime()}</BrowserView>
                  <MobileView>{activity.activityTimeShort()}</MobileView>
                </ActivityTime>
              </RightSideContainer>
            </ActivityRow>
          );
        })}
      </ServiceContainer>
    </ModuleContainer>
  );
};

export default Activity;
