import React from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import moment, { Moment } from 'moment';
import Cleaning, {
  AssignmentType,
  CleaningStatus,
} from '../../../../../../models/Cleaning';
import { ReactComponent as GreenCheck } from '../../../../../../images/icon-check-complete.svg';
import CleaningInfoRow from '../../../../../cleaning/CleaningInfoRow';

// Props

interface MonthlyCellProps {
  day: number;
  currentDate: Moment | undefined;
  cleanings: Cleaning[] | undefined;
}

// Styles

const CalendarDay = styled.div`
  font-size: 12px;
`;

const CalendarCell = styled.td`
  border-top: 1px solid ${(props) => props.theme.border};
  vertical-align: top;
  text-align: center;
`;

const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 150px;
`;
const NumberCleanings = styled.div`
  font-weight: 700;
  font-style: italic;
  font-size: 35px;
  line-height: 35px;
`;

const ViewButton = styled(Button)`
  color: ${(props) => props.theme.secondary};
  font-style: italic;
  font-size: 12px;
`;

const CleaningPills = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: space-around;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
`;

const BasePill = styled.div`
  color: ${(props) => props.theme.light};
  font-weight: 700;
  font-size: 12px;
  font-style: italic;
  border-radius: 10px;
  vertical-align: middle;
  padding: 2px;
  min-height: 22px;
  flex-grow: 1;
`;

const RegularPill = styled(BasePill)`
  background-color: ${(props) => props.theme.secondarylight};
`;

const HotPill = styled(BasePill)`
  background-color: ${(props) => props.theme.primary};
`;

const Space = styled.div`
  width: 4px;
`;

const CleaningStats = styled.div`
  display: flex;
  justify-content: center;
`;

const UnitPopover = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-width: 500px;
  height: 250px;
`;

const PastSpace = styled.div`
  height: 10px;
`;

// Component

const MonthlyCell: React.FC<MonthlyCellProps> = ({
  day,
  currentDate,
  cleanings,
}: MonthlyCellProps) => {
  const cleaningsForDay: Cleaning[] = [];
  let regularCleanings = 0;
  let hotCleanings = 0;
  let completeCleanings = 0;
  let isToday = false;
  let isPast = false;
  let cleaningsDone = false;
  const momentToday = moment();
  const momentCurrentDate = moment(currentDate);

  cleanings?.map((cleaning) => {
    const cleaningDay = moment(cleaning.date);
    const currentDay = momentCurrentDate.set('date', day);
    isToday = momentCurrentDate.isSame(momentToday, 'day');
    isPast = momentCurrentDate.isBefore(momentToday, 'day');

    if (cleaningDay.isSame(currentDay, 'day')) {
      cleaningsForDay.push(cleaning);
    }
  });

  cleaningsForDay.map((cleaning) => {
    if (cleaning.type === AssignmentType.HOT_TURNOVER) {
      hotCleanings += 1;
    } else if (
      cleaning.type === AssignmentType.REGULAR ||
      cleaning.type === AssignmentType.DEEP_CLEANING
    ) {
      regularCleanings += 1;
    }

    if (cleaning.status === CleaningStatus.COMPLETE) {
      completeCleanings += 1;
    }
    return cleaning;
  });

  cleaningsDone = completeCleanings === cleaningsForDay.length;

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <UnitPopover>
          {cleaningsForDay.map((cleaning, index) => {
            return (
              <CleaningInfoRow
                key={index}
                label={cleaning.unit?.name}
                cleaning={cleaning}
              />
            );
          })}
        </UnitPopover>
      </Popover.Content>
    </Popover>
  );

  return (
    <CalendarCell
      className={`calendar-day-${day}`}
      style={{
        backgroundColor: `${isToday ? '#EBEEFF' : 'transparent'}`,
        border: `1px solid #EAEAEA`,
      }}
    >
      <CalendarDay>{day}</CalendarDay>
      <DayContainer>
        {cleaningsForDay.length > 0 && (
          <>
            <CleaningStats>
              {cleaningsDone && <GreenCheck />}
              <NumberCleanings
                style={{
                  color: cleaningsDone || isPast ? '#ADADAD' : '#000000',
                }}
              >
                {cleaningsForDay.length}
              </NumberCleanings>
            </CleaningStats>
            {!isPast && (
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popover}
                rootClose
              >
                <ViewButton variant="link">View all</ViewButton>
              </OverlayTrigger>
            )}
            {isPast && <PastSpace />}
            <CleaningPills>
              <RegularPill
                style={{
                  backgroundColor: regularCleanings > 0 ? '#B9C4FF' : '#7F7F7F',
                }}
              >
                {regularCleanings}
              </RegularPill>
              <Space />
              <HotPill
                style={{
                  backgroundColor: hotCleanings > 0 ? '#fb7e70' : '#7F7F7F',
                }}
              >
                {hotCleanings}
              </HotPill>
            </CleaningPills>
          </>
        )}
      </DayContainer>
    </CalendarCell>
  );
};

export default MonthlyCell;
