import 'reflect-metadata';
import ActivityItem from './ActivityItem';
import { Expose, Type } from 'class-transformer';

export default class ActivityFeed {
  @Type(() => ActivityItem)
  @Expose()
  activity?: ActivityItem[];

  hasUnreadActivity?: boolean;
}
