import React from 'react';
import ActivityItem, { ActorType } from '../../models/ActivityItem';
import DotText from '../common/ui/DotText';

// Props

interface ActivityMessageProps {
  activity: ActivityItem;
}

// Component

const ActivityMessage: React.FC<ActivityMessageProps> = ({
  activity,
}: ActivityMessageProps) => {
  const circleColor = (): string => {
    switch (activity.actorType) {
      case ActorType.CLEANING_COMPLETED:
        return '#27D9A1';
      case ActorType.CLEANING_INPROGRESS:
        return '#FF9748';
      case ActorType.CLEANING_SCHEDULED:
        return '#FB7E70';
      case ActorType.NEW_REPORT_ADDED:
        return '#6FD2FF';
      case ActorType.NEW_COMMENT_ADDED:
        return '#A948FF';
      default:
        return '#FB7E70';
    }
  };

  return <DotText color={circleColor()} message={activity.message} />;
};

export default ActivityMessage;
