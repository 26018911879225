import React from 'react';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

// Props

interface SettingsFormFieldProps {
  value: string;
  showError?: boolean;
  small?: boolean;
  secure?: boolean;
  placeholder?: string;
  renderTextArea?: boolean;
  style?: React.CSSProperties;
  readOnly?: boolean;
  onChange: (e: any) => void;
}

// Styles

const StyledFormControl = styled(Form.Control)`
  flex-grow: 1;
  box-shadow: 0 8px 19px 2px ${(props) => props.theme.shadow};
  font-size: 16px;
  color: ${(props) => props.theme.text}
  min-height: 42px;
  max-width: 420px;
`;

// Component

const SettingsFormField: React.FC<SettingsFormFieldProps> = ({
  value,
  showError = false,
  small = false,
  placeholder,
  secure = false,
  readOnly = false,
  renderTextArea = false,
  style,
  onChange,
}: SettingsFormFieldProps) => {
  // Methods

  const errorStyle = (): React.CSSProperties => {
    if (!showError) return {};
    return {
      borderColor: '#fb7e70',
    };
  };

  if (readOnly) {
    return <span>{value}</span>;
  }

  if (renderTextArea) {
    return (
      <Form.Control
        type={secure ? 'password' : 'text'}
        as="textarea"
        maxLength={240}
        placeholder=""
        security="s"
        style={{
          minWidth: small ? '118px' : '400px',
          ...errorStyle(),
          ...style,
        }}
        value={value}
        onChange={onChange}
      />
    );
  } else {
    return (
      <StyledFormControl
        size="lg"
        type={secure ? 'password' : 'text'}
        placeholder={placeholder}
        style={{
          minWidth: small ? '118px' : '400px',
          ...errorStyle(),
          ...style,
        }}
        value={value}
        onChange={onChange}
      />
    );
  }
};

export default SettingsFormField;
