import 'reflect-metadata';
import Cleaning from './Cleaning';
import { Expose, Type } from 'class-transformer';
import moment from 'moment';
import Unit from './Unit';

export default class UnitSchedule {
  @Type(() => Unit)
  @Expose()
  unit?: Unit;

  @Type(() => Cleaning)
  @Expose()
  cleanings?: Cleaning[];

  todayCleanings(): Cleaning[] {
    const today = moment();
    const filteredCleanings = this.cleanings?.filter((cleaning) => {
      const cleaningDate = moment(cleaning.date);
      return cleaningDate.isSame(today, 'day');
    });

    return filteredCleanings ?? [];
  }

  futureCleanings(): Cleaning[] {
    const today = moment();
    const filteredCleanings = this.cleanings?.filter((cleaning) => {
      const cleaningDate = moment(cleaning.date);
      return cleaningDate.isAfter(today, 'day');
    });

    return filteredCleanings ?? [];
  }
}
