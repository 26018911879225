import React from 'react';
import styled from 'styled-components';
import checkImage from '../../../images/icon-check-selected.png';
import checkImageRetina from '../../../images/icon-check-selected@2x.png';

interface SelectableDotProps {
  selected: boolean;
}

const Dot = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  border-radius: 50%;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.outline};
  text-align: center;
`;

const CheckImage = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const SelectableDot: React.FC<SelectableDotProps> = ({
  selected,
}: SelectableDotProps) => {
  return (
    <>
      {selected && (
        <CheckImage src={checkImage} srcSet={`${checkImageRetina} 2x`} />
      )}
      {!selected && <Dot />}
    </>
  );
};

export default SelectableDot;
