import React, { useState } from 'react';
import styled from 'styled-components';
import ScheduleContainer from '../../../../../models/ScheduleContainer';
import ToggleButton from '../../../../common/controls/ToggleButton';
import MonthlyCalendar from './views/MonthlyCalendar';
import WeeklyCalendar from './views/WeeklyCalendar';

export interface CalendarProps {
  schedule?: ScheduleContainer;
}

const ModuleContainer = styled.div`
  position: relative;
  margin-top: 40px;
  padding: 30px;
  box-shadow: 0 8px 19px 2px ${(props) => props.theme.shadow};
  border-radius: 6px;
`;

const StyledToggleButton = styled(ToggleButton)`
  position: absolute;
  right: 75px;
`;

const Calendar: React.FC<CalendarProps> = ({ schedule }: CalendarProps) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const [showWeekly, setShowWeekly] = useState<boolean>(false);
  const [showMonthly, setShowMonthly] = useState<boolean>(true);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateMedia);
  }, []);

  // Render

  return (
    <ModuleContainer>
      {isDesktop && (
        <StyledToggleButton
          label="Switch between month and weekly calendar view."
          style={{ position: 'absolute', right: '75px' }}
          leftActive={showWeekly}
          rightActive={showMonthly}
          leftContent="Week"
          rightContent="Month"
          leftClick={() => {
            setShowWeekly(true);
            setShowMonthly(false);
          }}
          rightClick={() => {
            setShowWeekly(false);
            setShowMonthly(true);
          }}
        />
      )}

      {(showWeekly || !isDesktop) && <WeeklyCalendar schedule={schedule} />}
      {showMonthly && isDesktop && (
        <MonthlyCalendar cleanings={schedule?.cleanings()} />
      )}
    </ModuleContainer>
  );
};

export default Calendar;
