import React from 'react';
import Cleaning, { CleaningStatus } from '../../models/Cleaning';
import ScheduleBar from './ScheduleBar';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

// Props

interface UnitNameWithScheduleBarProps {
  cleaning?: Cleaning;
  style?: React.CSSProperties;
}

// Styles

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UnitName = styled.div`
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Component

const UnitNameWithScheduleBar: React.FC<UnitNameWithScheduleBarProps> = ({
  cleaning,
  style,
}: UnitNameWithScheduleBarProps) => {
  const getScheduleBarColor = (status: CleaningStatus | undefined) => {
    switch (status) {
      case CleaningStatus.SCHEDULED:
        return '#f0f0f0';
      case CleaningStatus.IN_PROGRESS:
        return '#f5c02c';
      case CleaningStatus.COMPLETE:
        return '#27d9a1';
      default:
        return '#fff';
    }
  };
  return (
    <NameContainer>
      <ScheduleBar
        color={getScheduleBarColor(cleaning?.status)}
        style={{ marginRight: '8px' }}
      />
      <UnitName style={{ marginRight: isMobile ? '16px' : '50px', ...style }}>
        {cleaning?.unit?.name}
      </UnitName>
    </NameContainer>
  );
};

export default UnitNameWithScheduleBar;
