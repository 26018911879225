import 'reflect-metadata';
import OnboardStatus from './OnboardStatus';
import AccountStatus from './AccountStatus';
import { Expose } from 'class-transformer';

export default class Status {
  @Expose() onboardStatus?: OnboardStatus;
  @Expose() accountStatus?: AccountStatus;

  constructor() {
    this.onboardStatus = new OnboardStatus();
    this.accountStatus = new AccountStatus();
  }
}
