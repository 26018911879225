import 'reflect-metadata';
import UnitConnection from './UnitConnection';
import { Expose, Type } from 'class-transformer';

export default class UnitConnectionContainer {
  @Expose()
  totalReservations = 0;

  @Type(() => UnitConnection)
  @Expose()
  units: UnitConnection[] = [];
}
