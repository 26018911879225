import React, { useState } from 'react';
import { ISelectable } from '../../../../../../interfaces/interfaces';
import { plainToClass, serialize } from 'class-transformer';
import DefaultInstructions from '../../../../../../models/DefaultInstructions';
import styled from 'styled-components';
import media from 'styled-media-query';
import { isMobile } from 'react-device-detect';
import { ModalFooter, StyledButton } from './SelectAccounts';
import Unit from '../../../../../../models/Unit';
import Units from '../../../../../../models/Units';

import ShadowFormField from '../../../../../common/ui/FormField';
import { isUrlValid } from '../../../../../../modules/utils/validation';
import { apiService } from '../../../../../../modules/services/apiService';
import UnitConnectionContainer from '../../../../../../models/UnitConnectionContainer';

const defaultInstructionsJson = require(`../../../../../../modules/services/defaultInstructions.json`);

// Props

interface AccountInstructionProps {
  account?: ISelectable;
  goNext: (unitConnection: UnitConnectionContainer) => void;
}

// Styles

const MainHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding-top: 44px;
`;

const SectionHeader = styled.h2`
  font-size: 32px;
  font-weight: 600;
  margin: auto;
`;

const SectionSubHeader = styled.h2`
  font-size: 16px;
  margin: auto;
  margin-top: 18px;
`;

const InstructionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 80px;
  ${media.lessThan('medium')`
    margin-top: 20px;
  `}
`;

const InstructionSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  padding-left: 4%;
`;

const Step = styled.h2`
  font-size: 28px;
  font-weight: 600;
  min-width: 100px;
  ${media.lessThan('medium')`
      font-size: 14px;
      min-width: 50px;
    `}
`;

const Content = styled.div`
  font-size: 16px;
  margin-left: 65px;

  ${media.lessThan('medium')`
      margin-left: 20px;
    `}
`;

const Image = styled.img`
  -webkit-user-drag: none;
`;

const FormHeader = styled.div`
  display: flex;
  justify-content: stretch;
  margin-top: 35px;
`;

const FormHeaderText = styled.h3`
  font-weight: 600;
  font-size: 18px;
  flex-grow: 1;
  text-align: center;
`;

// Container of unit and calendar link fields, including title

const FormHolder = styled.div`
  padding-left: 60px;
  padding-right: 60px;
  ${media.lessThan('medium')`
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

const UnitScroller = styled.div`
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
`;

const UnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-items: center;
`;

const FormItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: stretch;
  align-items: center;
  margin-bottom: 14px;
`;

const FormName = styled.div`
  flex-grow: 1;
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.lessThan('medium')`
    font-size: 12px;
  `}
`;

const FormNotice = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 20px;
  padding-right: 40px;
  font-size: 14px;
`;

const AccountInstructions: React.FC<AccountInstructionProps> = ({
  account,
  goNext,
}: AccountInstructionProps) => {
  const defaultCompleted: Unit[] = [];
  const [units, setUnits] = useState<Unit[]>();
  const [completedUnits, setCompletedUnits] = useState<Unit[]>(
    defaultCompleted,
  );
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);

  const checkSubmitReady = (): boolean => {
    if (completedUnits?.length >= 1) {
      return true;
    }
    return false;
  };

  const defaultInstructions = (): string[] | undefined => {
    const mappedInstructions = plainToClass(
      DefaultInstructions,
      defaultInstructionsJson,
    );
    return mappedInstructions.instructions;
  };

  const instructionsComponent = (): JSX.Element => {
    if (account?.name !== undefined)
      return renderInstructions(account.instructions);
    return renderInstructions(defaultInstructions());
  };

  const renderInstructions = (instructions?: string[]): JSX.Element => {
    const renderedList: JSX.Element[] = [];
    instructions?.map((step, index) => {
      renderedList.push(
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <Step>{`Step ${index + 1}`}</Step>
          <Content dangerouslySetInnerHTML={{ __html: step }} />
        </div>,
      );
    });
    let logo = <></>;
    if (account?.logo && account.width && account.height) {
      const logoPath = require(`../../../../../../images/${account.logo}.png`);
      const logoRetinaPath = require(`../../../../../../images/${account.logo}@2x.png`);
      const imgWidth = isMobile ? account.width * 0.7 : account.width;
      const imgHeight = isMobile ? account.height * 0.7 : account.height;

      logo = (
        <Image
          src={logoPath}
          srcSet={`${logoRetinaPath} 2x`}
          width={imgWidth}
          height={imgHeight}
          alt={account.name}
        />
      );
    }
    return (
      <InstructionContainer>
        <InstructionSubContainer>{renderedList}</InstructionSubContainer>
        <LogoContainer>{logo}</LogoContainer>
      </InstructionContainer>
    );
  };

  const renderUnits = () => {
    const renderedList: JSX.Element[] = [];
    units?.map((unit, index) => {
      renderedList.push(
        <FormItem key={index}>
          <FormName>{unit.name}</FormName>
          <ShadowFormField
            key={index}
            style={{ maxWidth: '40%' }}
            onChange={(e) => {
              if (isUrlValid(e.target.value)) {
                unit.calendarLink = e.target.value;
                if (!completedUnits.includes(unit)) {
                  completedUnits.push(unit);
                }
              } else {
                if (completedUnits.includes(unit)) {
                  const unitIndex = completedUnits.indexOf(unit);
                  completedUnits.splice(unitIndex, 1);
                }
              }
              setCompletedUnits(completedUnits);
              setReadyToSubmit(checkSubmitReady());
            }}
          />
        </FormItem>,
      );
    });
    return (
      <FormHolder>
        <FormHeader>
          <FormHeaderText>Unit</FormHeaderText>
          <FormHeaderText>Calendar Link</FormHeaderText>
        </FormHeader>
        <UnitScroller>
          <UnitContainer>{renderedList}</UnitContainer>
        </UnitScroller>
        <FormNotice>Please repeat the steps above for each unit.</FormNotice>
      </FormHolder>
    );
  };

  // Networking

  React.useEffect(() => {
    loadUnits();
  }, []);

  const loadUnits = async () => {
    apiService('/units')
      .then((res) => res.json())
      .then((response) => {
        try {
          const unitsMapped = plainToClass(Units, response.data);
          setUnits(unitsMapped.units);
        } catch (e) {
          console.error(e);
        }
      });
  };

  const submitUnitLinks = () => {
    const unitPayload = {
      calendarProvider: account?.name ?? '',
      units: completedUnits,
    };
    apiService('/units', 'put', serialize(unitPayload))
      .then((res) => res.json())
      .then((response) => {
        try {
          const unitConnectionMapped = plainToClass(
            UnitConnectionContainer,
            response.data,
          );
          console.log('MAPPED: ', unitConnectionMapped);
          goNext(unitConnectionMapped);
        } catch (e) {
          console.error(e);
        }
      });
  };

  // Render Component

  return (
    <>
      <MainHeader>
        <SectionHeader>Add your calendars</SectionHeader>
        <SectionSubHeader>
          Please follow the instructions below to add your host calendar.
          <br />
          <i>This will not share any guest personal information with us.</i>
        </SectionSubHeader>
        {instructionsComponent()}
      </MainHeader>
      {renderUnits()}
      <ModalFooter>
        <StyledButton disabled={!readyToSubmit} onClick={submitUnitLinks}>
          Continue
        </StyledButton>
      </ModalFooter>
    </>
  );
};

export default AccountInstructions;
