import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { ModalFooter, StyledButton } from './SelectAccounts';
import UnitConnectionContainer from '../../../../../../models/UnitConnectionContainer';

// Props

interface SetupSuccessProps {
  unitConnection: UnitConnectionContainer;
  goNext: () => void;
}

// Styles

const MainHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding-top: 44px;
`;

const SectionHeader = styled.h2`
  font-size: 32px;
  font-weight: 600;
  margin: auto;
`;

const SectionSubHeader = styled.h2`
  font-size: 16px;
  margin: auto;
  margin-top: 18px;
  text-align: center;
`;

const Success = styled.div`
  margin: auto;
  margin-top: 16px;
  font-size: 100px;
  -webkit-user-drag: none;
`;

const FormHolder = styled.div`
  padding-left: 60px;
  padding-right: 60px;
  ${media.lessThan('medium')`
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

const FormHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;
`;

const FormHeaderText = styled.h3`
  font-weight: 600;
  font-size: 18px;
  width: 250px;
  text-align: center;
`;

const UnitScroller = styled.div`
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
`;

const UnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-items: center;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 42px;
  margin-bottom: 25px;
`;

const UnitBar = styled.div`
  width: 4px;
  height: 25px;
  border-radius: 2px;
  margin-right: 8px;
`;

const UnitName = styled.div`
  flex-grow: 1;
  font-weight: 600;
  font-size: 19px;
  width: 200px;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.lessThan('medium')`
    font-size: 12px;
  `}
`;

const UnitImported = styled.div`
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  width: 250px;
`;

// Component

const SetupSuccess: React.FC<SetupSuccessProps> = ({
  unitConnection,
  goNext,
}: SetupSuccessProps) => {
  // State
  const [unitConnectionContainer] = useState<UnitConnectionContainer>(
    unitConnection,
  );
  const [totalReservations, setTotalReservations] = useState(0);
  const [totalUnits, setTotalUnits] = useState(0);

  // Methods

  const continueCheck = () => {
    goNext();
    return;
  };

  const renderedUnits = (): JSX.Element => {
    const renderedList: JSX.Element[] = [];
    unitConnectionContainer.units.map((unitConn, index) => {
      return renderedList.push(
        <ListItem key={index}>
          <UnitBar
            style={{ backgroundColor: unitConn.unit?.color ?? '#fff' }}
          />
          <UnitName>{unitConn.unit?.name}</UnitName>
          <UnitImported>{unitConn.reservationsImported}</UnitImported>
        </ListItem>,
      );
    });
    return (
      <FormHolder>
        <FormHeader>
          <FormHeaderText>Unit</FormHeaderText>
          <FormHeaderText>Reservations Imported</FormHeaderText>
        </FormHeader>
        <UnitScroller>
          <UnitContainer>{renderedList}</UnitContainer>
        </UnitScroller>
      </FormHolder>
    );
  };

  // Networking

  React.useEffect(() => {
    setTotalReservations(unitConnectionContainer.totalReservations);
    setTotalUnits(unitConnectionContainer.units.length);
  }, [unitConnectionContainer]);

  return (
    <>
      <MainHeader>
        <SectionHeader>{'You’re connected!'}</SectionHeader>
        <SectionSubHeader>
          We successfully imported <b>{totalReservations} reservations</b> over
          the next year for <b>{totalUnits} units</b>.
          <br />
          Please make sure we matched the address to the right listing.
        </SectionSubHeader>
        <Success>
          <span role="img" aria-label="High five emoji">
            🙌
          </span>
        </Success>
      </MainHeader>
      {renderedUnits()}
      <ModalFooter>
        <StyledButton onClick={continueCheck}>Go to schedule</StyledButton>
      </ModalFooter>
    </>
  );
};

export default SetupSuccess;
