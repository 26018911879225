import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as BedSvg } from '../../../../images/icon-bedroom.svg';
import { ReactComponent as BathSvg } from '../../../../images/icon-bathroom.svg';
import { ReactComponent as LaundrySvg } from '../../../../images/icon-laundry.svg';
import { ReactComponent as TrashSvg } from '../../../../images/icon-trash.svg';
import Unit from '../../../../models/Unit';

// Props

interface UnitInfoProps {
  unit: Unit;
}

// Styles
const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  min-width: 500px;
  box-shadow: 0 8px 19px 2px ${(props) => props.theme.shadow};
  border-radius: 6px;
  min-height: 240px;
`;

const CardHeader = styled.h2`
  font-size: 18px;
  font-weight: 600;
`;

const CleaningStats = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

const CleaningDuration = styled.div``;
const UnitSummary = styled.div`
  min-width: 50%;
`;

const SummaryRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
`;

const SummaryItem = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  width: 130px;
`;

const ManageInfo = styled(Link)`
  position: absolute;
  right: 30px;
  bottom: 30px;
`;

const IconContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
  width: 22px;
  height: 22px;
`;

// Component

const UnitInfoCard: React.FC<UnitInfoProps> = ({ unit }: UnitInfoProps) => {
  return (
    <CardContainer>
      <CardHeader>Unit information</CardHeader>
      <CleaningStats>
        <CleaningDuration>
          Cleaning duration: {unit.formattedDuration()}
        </CleaningDuration>
        <UnitSummary>
          <SummaryRow>
            <SummaryItem>
              <IconContainer>
                <BedSvg style={{ maxWidth: '15px', maxHeight: '15px' }} />
              </IconContainer>
              {unit.numBedrooms} Bedroom
            </SummaryItem>
            <SummaryItem>
              <IconContainer>
                <BathSvg style={{ maxWidth: '15px', maxHeight: '15px' }} />
              </IconContainer>
              {unit.numBathrooms} Bath
            </SummaryItem>
          </SummaryRow>
          <SummaryRow>
            <SummaryItem>
              <IconContainer>
                <LaundrySvg style={{ maxWidth: '15px', maxHeight: '15px' }} />
              </IconContainer>
              Laundry in-unit
            </SummaryItem>
            {(unit.garbageLocationPhotoUrls !== undefined &&
              unit.garbageLocationPhotoUrls?.length) > 0 && (
              <SummaryItem>
                <IconContainer>
                  <TrashSvg style={{ maxWidth: '15px', maxHeight: '15px' }} />
                </IconContainer>
                Trash chute
              </SummaryItem>
            )}
          </SummaryRow>
        </UnitSummary>
      </CleaningStats>
      <ManageInfo to={`/unit/${unit.id}/settings`}>
        Manage Information
      </ManageInfo>
    </CardContainer>
  );
};

export default UnitInfoCard;
