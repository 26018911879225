import 'reflect-metadata';
import moment from 'moment';
import _, { isEmpty } from 'lodash';
import { Expose, Type } from 'class-transformer';
import { RowContentTransformable } from './DoorCode';
import { RowContent } from '../components/common/ui/EditableTableRow';
import { Auth } from 'aws-amplify';
import { isBlank, isEmailValid } from '../modules/utils/validation';

export enum UserStatus {
  JOINED = 'joined',
  INVITED = 'invited',
  EXPIRED = 'invite_expired',
  REMOVED = 'removed',
}

export enum UserRole {
  SUPER = 'super_admin',
  ADMIN = 'tenant_admin',
  MEMBER = 'tenant_user',
}

export default class ClientAppUser implements RowContentTransformable {
  @Expose() id?: string;
  @Expose() cognitoId?: string;
  @Expose() username?: string;
  @Expose() firstName = '';
  @Expose() lastName = '';
  @Expose() title?: string;
  @Expose() email = '';
  @Expose() photoUrls: string[] = [];
  @Expose() emailNotificationsEnabled = false;
  @Expose() workspaceRole?: UserRole;
  @Expose() role?: UserRole;
  @Expose() superAdmin?: boolean;
  @Expose() status?: UserStatus;
  @Type(() => Date)
  @Expose()
  createdAt?: Date;
  @Type(() => Date)
  @Expose()
  updatedAt?: Date;
  @Type(() => Date)
  @Expose()
  joinedDate?: Date;

  // Convenience Methods

  fullName(): string {
    if (isEmpty(this.firstName) && isEmpty(this.lastName)) return '';
    return this.firstName + ' ' + this.lastName;
  }

  permissions(): string {
    if (this.workspaceRole === UserRole.ADMIN) {
      return 'Admin';
    }
    if (this.workspaceRole === UserRole.MEMBER) {
      return 'Member';
    }
    if (this.workspaceRole === UserRole.SUPER) {
      return 'Client App Super Admin';
    }
    return '';
  }

  prettyStatus(): string {
    const prettyCreation = moment(this.createdAt).format('M/DD/YY h:mm:s a');
    return prettyCreation;
    // Eventually this will print out more detailed status' for a user
    return _.capitalize(this.status?.toString().replace('_', ' ') ?? '');
  }

  isAdmin(): boolean {
    return this.role === UserRole.ADMIN;
  }

  async isSuperAdmin(): Promise<boolean> {
    let isSuper = false;
    await Auth.currentSession().then((userSession) => {
      const groupsArray = userSession
        .getAccessToken()
        .payload['cognito:groups'].join(' ')
        .toLowerCase();
      isSuper = groupsArray.includes('admins');
    });
    this.superAdmin = isSuper;
    return isSuper;
  }

  async isCurrentUser(): Promise<boolean> {
    let isCurrent = false;
    await Auth.currentUserInfo().then((userInfo) => {
      isCurrent = userInfo.id === this.id;
    });
    return isCurrent;
  }

  validForSubmission(): boolean {
    return (
      isEmailValid(this.email) &&
      this.workspaceRole !== undefined &&
      !isEmpty(this.fullName())
    );
  }

  // RowContentTransformable

  getAsStrings(): string[] {
    const memberContents: string[] = [
      this.fullName() ?? '',
      this.permissions() ?? '',
      this.email ?? '',
      this.prettyStatus() ?? '',
    ];
    return memberContents;
  }

  setFromStrings(strings: string[]): ClientAppUser {
    const nameString: string = strings[0];
    this.firstName = nameString.split(' ')[0];
    this.lastName = nameString.split(' ')[1];

    this.email = strings[2];

    if (this.status === undefined) this.status = UserStatus.INVITED;

    if (this.workspaceRole === undefined && isBlank(strings[1])) {
      this.workspaceRole = UserRole.MEMBER;
    } else {
      const permissionString: string = strings[1];
      if (
        permissionString === 'Admin' ||
        permissionString === UserRole.ADMIN.toString()
      )
        this.workspaceRole = UserRole.ADMIN;
      if (
        permissionString === 'Member' ||
        permissionString === UserRole.MEMBER.toString()
      )
        this.workspaceRole = UserRole.MEMBER;
    }

    return this;
  }

  getAsRowContents(): RowContent[] {
    const userContents: RowContent[] = [
      {
        content: this.fullName(),
        placeholder: 'Full name',
      },
      {
        content: this.permissions(),
        rawValue: this.workspaceRole,
        dropdownValues: ['Admin', 'Member'],
      },
      {
        content: this.email,
        placeholder: 'name@email.com',
        emailValidation: true,
      },
      {
        content: this.prettyStatus(),
        rawValue: this.status,
        systemGenerated: true,
      },
    ];
    return userContents;
  }

  setFromRowContents(rowContents: RowContent[]): ClientAppUser {
    const nameString: string = rowContents[0].content;
    this.firstName = nameString.split(' ')[0];
    this.lastName = nameString.split(' ')[1];

    const permissionString: string = rowContents[1].rawValue ?? '';
    const typedPermission = permissionString as keyof typeof UserRole;
    this.workspaceRole = UserRole[typedPermission];

    this.email = rowContents[2].content;

    const statusString: string = rowContents[3].rawValue ?? '';
    const typedStatus = statusString as keyof typeof UserStatus;
    this.status = UserStatus[typedStatus];

    return this;
  }
}
