import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Unit, { UnitInfoStatus } from '../../models/Unit';
import Badge from '../common/ui/Badge';
import UnitNameWithBar from './UnitNameWithBar';

// Props

interface UnitNameButtonProps {
  unit?: Unit;
  bar?: boolean;
}

// Styles

const UnitInfo = styled.button`
  position: relative;
  font-size: 12px;
  font-style: italic;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  padding-top: 10px;
  background-color: transparent;
  border-width: 0px;

  &:hover {
    opacity: 0.6;
  }
`;

// Component

const UnitNameButton: React.FC<UnitNameButtonProps> = ({
  unit,
  bar = true,
}: UnitNameButtonProps) => {
  const [showUnit, setShowUnit] = useState<boolean>(false);

  // Component
  return (
    <>
      {showUnit && <Redirect push to={`/unit/${unit?.id}/`} />}
      <UnitInfo
        onClick={() => {
          setShowUnit(true);
        }}
      >
        <UnitNameWithBar bar={bar} unit={unit} style={{ marginRight: '0px' }} />
        {/* {unit?.infoStatus !== UnitInfoStatus.COMPLETE && (
          <Badge
            badgeCount="!"
            style={{ fontSize: '16px', top: '21px', right: '8px' }}
          />
        )} */}
      </UnitInfo>
    </>
  );
};

export default UnitNameButton;
