import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { ReactComponent as DisclosureSvg } from '../../images/icon-disclosure.svg';
import { isMobile } from 'react-device-detect';
import CleaningType from './CleaningType';
import UnitNameWithScheduleBar from './UnitNameWithScheduleBar';
import CommentPreview from './CommentPreview';
import Cleaning from '../../models/Cleaning';
import { Redirect } from 'react-router-dom';

// Props

interface CleaningInfoRowProps {
  label: string | undefined;
  cleaning: Cleaning;
}

// Styles

const ServiceItem = styled.button`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 48% 1fr 12px;
  border-style: none;
  background-color: transparent;
  &:hover {
    opacity: 0.6;
  }
`;

const ServiceItemColumn = styled.div`
  display: flex;
  flex-grow: 2;
  text-align: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  width: 50%;
`;

const ServiceItemTime = styled.div`
  display: grid;
  text-align: center;
  align-content: center;
  place-items: center;
  min-height: 27px;
`;

const ServiceItemAddons = styled.div`
  display: flex;
  flex-grow: 1;
  text-align: center;
  justify-content: space-evenly;
  justify-items: center;
  align-content: center;
  align-items: center;
  min-height: 27px;
  width: 15%;
  ${media.lessThan('medium')`
    display: none;
  `}
`;

const ServiceItemDisclosure = styled.div`
  display: flex;
  justify-items: center;
  align-content: center;
  vertical-align: center;
  padding-top: 5px;
  min-height: 27px;
  ${media.lessThan('medium')`
    position: absolute;
    right: 0px;
    padding-right: 30px;
  `}
`;

const CleaningTime = styled.p`
  font-size: 12px;
  font-weight: 600;
  margin: auto;
`;

// Component

const CleaningInfoRow: React.FC<CleaningInfoRowProps> = ({
  label,
  cleaning,
}: CleaningInfoRowProps) => {
  const [showUnit, setShowUnit] = useState<boolean>(false);

  return (
    <>
      {showUnit && (
        <Redirect push to={`/unit/${cleaning.unit?.id}/${cleaning.id}`} />
      )}
      <ServiceItem
        aria-label={label}
        onClick={() => {
          setShowUnit(true);
        }}
      >
        <ServiceItemColumn>
          <CleaningType cleaning={cleaning} style={{ marginRight: '12px' }} />
          <UnitNameWithScheduleBar cleaning={cleaning} />
          <CommentPreview cleaning={cleaning} />
        </ServiceItemColumn>
        <ServiceItemTime>
          <CleaningTime>
            {isMobile ? cleaning.cleaningTimeShort() : cleaning.cleaningTime()}
          </CleaningTime>
        </ServiceItemTime>
        <ServiceItemDisclosure>
          <DisclosureSvg style={{ width: '11px', height: '18px' }} />
        </ServiceItemDisclosure>
      </ServiceItem>
    </>
  );
};

export default CleaningInfoRow;
