import React from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components/macro';

export const SectionHeader = styled.h2`
  font-size: 35px;
  font-weight: 600;
  margin-left: 15px;
  margin-bottom: 40px;
`;

export const UnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${up('lg')} {
    flex-direction: row;
  }
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 115px;
  margin-left: 15px;
  margin-right: 30px;
  font-size: 20px;
`;
