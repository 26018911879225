import React from 'react';
import styled from 'styled-components';

// Props

interface ScheduleBarProps {
  color?: string;
  style: React.CSSProperties;
}

// Styles

const ColorBar = styled.div`
  width: 4px;
  height: 25px;
  border-radius: 2px;
  margin-right: 8px;
`;

// Components

const ScheduleBar: React.FC<ScheduleBarProps> = ({
  color,
  style,
}: ScheduleBarProps) => {
  return <ColorBar style={{ backgroundColor: color ?? '#fff', ...style }} />;
};

export default ScheduleBar;
