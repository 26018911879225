import 'reflect-metadata';
import { Expose } from 'class-transformer';

export default class OnboardStatus {
  @Expose() step = 0;
  @Expose() missingUnitDetails?: number;
  @Expose() inProgress?: boolean;

  public unitInformation(): string {
    if (this.missingUnitDetails === 1) {
      return `${this.missingUnitDetails} unit`;
    } else {
      return `${this.missingUnitDetails} units`;
    }
  }
}
