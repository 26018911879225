import 'reflect-metadata';
import Unit from './Unit';
import { Expose, Type } from 'class-transformer';

export default class UnitConnection {
  @Expose()
  reservationsImported = 0;

  @Type(() => Unit)
  @Expose()
  unit?: Unit;
}
