import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Button from '../../common/controls/Button';
import Modal from 'react-bootstrap/Modal';
import { UserContext } from '../../base/App';
import { NavLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import FormHeader from '../../common/ui/FormHeader';
import SettingsFormField from '../../common/ui/SettingsFormField';
import ImageRow from '../../common/controls/ImageRow';
import { isBlank, isEmailValid } from '../../../modules/utils/validation';
import { apiService } from '../../../modules/services/apiService';
import { plainToClassFromExist, serialize } from 'class-transformer';
import Status from '../../../models/Status';

// Styles

const SectionHeader = styled.h2`
  font-size: 35px;
  font-weight: 600;
  margin-left: 50px;
  margin-bottom: 40px;
`;

const UnitContainer = styled.div`
  display: flex;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 20px;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  justify-content: stretch;
  padding-bottom: 50px;
`;

const StyledLink = styled(NavLink)`
  position: relative;
  margin-bottom: 20px;
`;

const FormItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const FormItemLeft = styled(FormItem)`
  align-items: flex-start;
`;

const FormName = styled.div`
  font-weight: 600;
  min-width: 100px;
  margin-right: 30px;
`;

const FormNameGrow = styled(FormName)`
  width: 100px;
`;

const FormSubhead = styled.p`
  font-size: 18px;
  font-weight: 600;
  max-width: 680px;
`;

const BlueTableHeader = styled.div`
  display: flex;
  justify-content: stretch;
  width: 900px;
  background-color: ${(props) => props.theme.secondary};
  border-radius: 5px;
  color: white;
  font-weight: 700;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const TableSection = styled.div`
  width: 25%;
  text-align: center;
  white-space: nowrap;
`;

const BlueTableContent = styled.div`
  width: 900px;
  padding: 10px;
`;

const CommitChangesContainer = styled.div`
  display: flex;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 60px;
  width: 600px;
`;

const GrowableButton = styled(Button)`
  flex-grow: 1;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-left: 140px;
  width: 400px;
  margin-bottom: 30px;
`;

const TableRow = styled.div`
  position: relative;
  display: flex;
  justify-content: stretch;
`;

const RowContent = styled.div`
  width: 25%;
  text-align: center;
`;

const TableSeparator = styled.hr`
  max-width: 850px;
  color: ${(props) => props.theme.outline};
`;

const GroupedForm = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  max-width: 550px;
`;

const ChangePassError = styled.div`
  color: ${(props) => props.theme.primary};
`;

// Component

const Account: React.FC = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [adminAllowed, setAdminAllowed] = useState(false);
  const [status, setStatus] = useState<Status>();
  const [photos, setPhotos] = useState<string[]>([]);
  const [firstName, setFirstName] = useState<string>(
    currentUser?.firstName ?? '',
  );
  const [lastName, setLastName] = useState<string>(currentUser?.lastName ?? '');
  const [title, setTitle] = useState<string>(currentUser?.title ?? '');
  const [email, setEmail] = useState<string>(currentUser?.email ?? '');
  const [notifications, setNotifications] = useState<boolean>(
    currentUser?.emailNotificationsEnabled ?? false,
  );
  const [showChangePass, setShowChangePass] = useState<boolean>();
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [changePasswordError, setChangePasswordError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Methods

  const saveChanges = () => {
    if (isBlank(firstName) || isBlank(lastName) || !isEmailValid(email)) {
      alert('First name, last name, and a valid email address are required.');
      return;
    }

    if (currentUser) {
      currentUser.firstName = firstName;
      currentUser.lastName = lastName;
      currentUser.title = title;
      currentUser.email = email;
      currentUser.emailNotificationsEnabled = notifications;
      currentUser.photoUrls = photos;

      setIsSubmitting(true);

      const currentUserUpdate = serialize(currentUser);
      apiService('/me', 'put', currentUserUpdate)
        .then((res) => {
          if (res.ok) {
            // User updated
          } else {
            console.error(res.status);
          }
          setIsSubmitting(false);
        })
        .catch((e) => {
          setIsSubmitting(false);
        });
    }
  };

  const revertChanges = () => {
    loadCurrentUserInfo();
  };

  const changePassword = () => {
    setChangePasswordError('');
    setIsSubmitting(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then(() => {
        setShowChangePass(false);
        setIsSubmitting(false);
      })
      .catch((err) => {
        setIsSubmitting(false);
        setChangePasswordError('Unable to change password. Please try again.');
      });
  };

  const loadCurrentUserInfo = async () => {
    setPhotos(currentUser?.photoUrls ?? []);
    setFirstName(currentUser?.firstName ?? '');
    setLastName(currentUser?.lastName ?? '');
    setTitle(currentUser?.title ?? '');
    setNotifications(currentUser?.emailNotificationsEnabled ?? false);

    const cognitoUser = await Auth.currentUserInfo();
    if (cognitoUser) {
      setEmail(cognitoUser?.attributes?.email ?? '');
    }
  };

  const loadAccountStatus = () => {
    apiService('/status/1')
      .then((res) => res.json())
      .then((response) => {
        try {
          const defaultStatus = new Status();
          const statusMapped = plainToClassFromExist(
            defaultStatus,
            response.data,
          );
          setStatus(statusMapped);
        } catch (e) {
          console.error(e);
        }
      });
  };

  useEffect(() => {
    loadCurrentUserInfo();
    loadAccountStatus();
    setAdminAllowed(currentUser?.superAdmin ?? false);
  }, [currentUser]);

  // Render

  return (
    <Container fluid>
      <Modal
        show={showChangePass}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormItem>
            <FormNameGrow>Current Password</FormNameGrow>
            <SettingsFormField
              value={oldPassword}
              style={{ minWidth: '200px', marginRight: '20px' }}
              secure
              onChange={(e: any) => {
                setOldPassword(e.target.value);
              }}
            />
          </FormItem>
          <FormItem>
            <FormNameGrow>New Password</FormNameGrow>
            <SettingsFormField
              value={newPassword}
              style={{ minWidth: '200px', marginRight: '20px' }}
              secure
              onChange={(e: any) => {
                setNewPassword(e.target.value);
              }}
            />
          </FormItem>
          <FormItem>
            <FormNameGrow>Confirm New Password</FormNameGrow>
            <SettingsFormField
              value={confirmNewPassword}
              style={{ minWidth: '200px', marginRight: '20px' }}
              secure
              onChange={(e: any) => {
                setConfirmNewPassword(e.target.value);
              }}
            />
          </FormItem>
          {!isBlank(changePasswordError) && <ChangePassError></ChangePassError>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={changePassword}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Save changes
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              setShowChangePass(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <SectionHeader>Account Settings</SectionHeader>
      <UnitContainer>
        <LeftPanel>
          <StyledLink exact to="/account" activeClassName="unitActive">
            Profile
          </StyledLink>
          {!adminAllowed && (
            <StyledLink exact to="/team" activeClassName="unitActive">
              Team
            </StyledLink>
          )}
        </LeftPanel>
        <RightPanel>
          {!adminAllowed && (
            <ProfileContainer>
              <ImageRow
                photos={photos}
                singlePhoto
                showChangeButton
                style={{ borderRadius: '50%', width: '134px', height: '134px' }}
                setImages={setPhotos}
              />
            </ProfileContainer>
          )}
          {!adminAllowed && (
            <FormItem>
              <FormNameGrow>First Name</FormNameGrow>
              <SettingsFormField
                value={firstName}
                showError={isBlank(firstName)}
                onChange={(e: any) => {
                  setFirstName(e.target.value);
                }}
              />
            </FormItem>
          )}
          {!adminAllowed && (
            <FormItem>
              <FormNameGrow>Last Name</FormNameGrow>
              <SettingsFormField
                value={lastName}
                showError={isBlank(lastName)}
                onChange={(e: any) => {
                  setLastName(e.target.value);
                }}
              />
            </FormItem>
          )}
          {!adminAllowed && (
            <FormItem>
              <FormNameGrow>Title</FormNameGrow>
              <SettingsFormField
                value={title}
                onChange={(e: any) => {
                  setTitle(e.target.value);
                }}
              />
            </FormItem>
          )}
          <GroupedForm>
            <FormItem>
              <FormNameGrow>Email Address</FormNameGrow>
              <SettingsFormField
                value={email}
                showError={isBlank(email)}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
              />
            </FormItem>
            <Button
              variant="link"
              style={{ right: '0px', position: 'absolute', bottom: '0px' }}
              onClick={() => {
                setShowChangePass(true);
              }}
            >
              Change password
            </Button>
          </GroupedForm>
          {!adminAllowed && (
            <FormItemLeft>
              <FormName>Email Notifications</FormName>
              <BootstrapSwitchButton
                checked={notifications}
                onlabel="On"
                offlabel="Off"
                onstyle="secondary"
                offstyle="info"
                onChange={(checked: boolean) => {
                  setNotifications(checked);
                }}
              />
            </FormItemLeft>
          )}
          <Button
            variant="link"
            style={{
              color: '#6f85ff',
              textAlign: adminAllowed ? 'right' : 'center',
            }}
            onClick={async () => {
              await Auth.signOut();
            }}
          >
            Sign out of your account
          </Button>
          {!adminAllowed && (
            <>
              <FormHeader>Agreements</FormHeader>
              <FormSubhead>
                Below are the agreements we have on record for you. If you have
                any questions, please{' '}
                <a href="mailto:contactus@cleancio.com">contact us</a>.
              </FormSubhead>
              <BlueTableHeader>
                <TableSection style={{ width: '50%' }}>Document</TableSection>
                <TableSection style={{ width: '50%' }}>Link</TableSection>
              </BlueTableHeader>
              <BlueTableContent>
                <TableRow>
                  <RowContent style={{ width: '50%' }}>
                    Client Agreement
                  </RowContent>
                  <RowContent style={{ width: '50%' }}>
                    <a
                      href={status?.accountStatus?.agreementLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Agreement
                    </a>
                  </RowContent>
                </TableRow>
                <TableSeparator />
              </BlueTableContent>
              <CommitChangesContainer>
                <GrowableButton
                  variant="primary"
                  style={{ marginRight: '24px' }}
                  onClick={saveChanges}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Save changes
                </GrowableButton>
                <GrowableButton
                  variant="outline-primary"
                  onClick={revertChanges}
                >
                  Revert changes
                </GrowableButton>
              </CommitChangesContainer>
            </>
          )}
        </RightPanel>
      </UnitContainer>
    </Container>
  );
};

export default Account;
