import React from 'react';
import styled from 'styled-components';
import Unit from '../../models/Unit';

// Props

interface UnitBarProps {
  unit: Unit | undefined;
  style: React.CSSProperties;
}

// Styles

const ColorBar = styled.div`
  width: 4px;
  height: 25px;
  border-radius: 2px;
  margin-right: 8px;
`;

// Components

const UnitBar: React.FC<UnitBarProps> = ({ unit, style }: UnitBarProps) => {
  return (
    <ColorBar style={{ backgroundColor: unit?.color ?? '#fff', ...style }} />
  );
};

export default UnitBar;
