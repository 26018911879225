import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import { apiService } from '../../../modules/services/apiService';

type AuthImageProps = {
  url?: string;
  avatar?: boolean;
  style?: React.CSSProperties;
};

const AuthImage: React.FC<AuthImageProps> = (
  props: AuthImageProps,
  alt?: string,
  avatar = false,
) => {
  const [imageURL, setImageURL] = useState<string>('');
  const imgRef: React.Ref<HTMLImageElement> = React.createRef();
  const placeHolder =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=';

  // Networking

  const loadImage = async () => {
    if (props.url === undefined) return;

    try {
      if (props.avatar || props.url.includes('blob:')) {
        setImageURL(props.url);
        return;
      }

      const res = await apiService(`/getFile?url=${props.url}`);
      const blob = await res.blob();
      const objectURL = URL.createObjectURL(blob);
      setImageURL(objectURL);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    loadImage();
  }, []);

  const imageLoaded = (): boolean => {
    return imageURL.length > 0;
  };

  const altTag = (): string => {
    return typeof alt === 'string' ? alt : '';
  };

  // Component
  return (
    <Image
      src={imageLoaded() ? imageURL : placeHolder}
      alt={imageLoaded() ? altTag() : ''}
      style={{ objectFit: 'cover', ...props?.style }}
      ref={imgRef}
    />
  );
};

export default AuthImage;
