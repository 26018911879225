import React from 'react';

import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

interface ConnectProps {
  show: boolean;
  content: any;
  onHide: () => void;
}

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 800px;
`;

const ConnectAccounts: React.FC<ConnectProps> = ({
  show,
  onHide,
  content,
}: ConnectProps) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <MainContainer>{content}</MainContainer>
      </Modal.Body>
    </Modal>
  );
};

export default ConnectAccounts;
