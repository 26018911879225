import React, { useState } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { NotificationPayload } from 'firebase/messaging';
import Home from '../sections/home/Home';
import UnitActivity from '../sections/unit/UnitActivity';
import TopNav from '../common/navigation/TopNav';
import Account from '../sections/account/Account';
import AdminHome from '../sections/admin/AdminHome';
import authData from '../../modules/services/authParams';
import { plainToClass } from 'class-transformer';
import ClientAppUser from '../../models/ClientAppUser';
import UnitSettings from '../sections/unit/UnitSettings';
import Team from '../sections/account/Team';
import AdminClient from '../sections/admin/AdminClient';
import { fetchToken, onMessageListener } from '../../modules/utils/firebase';
import { apiService } from '../../modules/services/apiService';

interface UserContextProps {
  currentUser?: ClientAppUser;
  setCurrentUser?: React.Dispatch<
    React.SetStateAction<ClientAppUser | undefined>
  >;
}

interface GlobalContextProps {
  receivedNotification?: NotificationPayload | undefined;
  setReceivedNotification: React.Dispatch<
    React.SetStateAction<NotificationPayload | undefined>
  >;
  receivedNotificationCount?: number;
  setReceivedNotificationCount: React.Dispatch<React.SetStateAction<number>>;
}

export const UserContext = React.createContext<Partial<UserContextProps>>({});
export const GlobalContext = React.createContext<Partial<GlobalContextProps>>(
  {},
);

const App: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<ClientAppUser>();
  const [receivedNotification, setReceivedNotification] = useState<
    NotificationPayload
  >();
  const [receivedNotificationCount, setReceivedNotificationCount] = useState<
    number
  >(0);
  const value = { currentUser, setCurrentUser };
  const globalValue = {
    receivedNotification,
    setReceivedNotification,
    receivedNotificationCount,
    setReceivedNotificationCount,
  };

  fetchToken();

  onMessageListener()
    .then((payload) => {
      console.log('payload -->', payload);
      if (payload?.notification) {
        setReceivedNotification(payload?.notification);
        setReceivedNotificationCount((prevCount) => prevCount + 1);
      }
    })
    .catch((err) => console.log('failed: ', err));

  // Methods

  const loadCurrentUser = async () => {
    const auth = await authData();
    fetch(`${process.env.REACT_APP_API_BASE_URL}/me`, {
      headers: {
        accessToken: auth.accessToken,
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        try {
          const photoUrls = [];
          for (const photoUrl of response.data?.photoUrls ?? []) {
            if (!photoUrl.startsWith('https://files.podio.com')) {
              photoUrls.push(photoUrl);
              continue;
            }
            const res = await apiService(`/getFile?url=${photoUrl}`);
            const blob = await res.blob();
            const objectURL = URL.createObjectURL(blob);
            photoUrls.push(objectURL);
          }
          response.data.photoUrls = photoUrls;
          const currentUserMapped = plainToClass(ClientAppUser, response.data);
          await currentUserMapped.isSuperAdmin();
          setCurrentUser(currentUserMapped);
        } catch (e) {
          console.error(e);
        }
      });
  };

  // Networking

  React.useEffect(() => {
    loadCurrentUser();
  }, []);

  // Component

  return (
    <>
      <GlobalContext.Provider value={globalValue}>
        <UserContext.Provider value={value}>
          <Router>
            <TopNav />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path={'/unit/:id/settings'}>
                <UnitSettings />
              </Route>
              <Route path={['/unit/:id/:cleaning', '/unit/:id']}>
                <UnitActivity />
              </Route>
              <Route exact path="/account">
                <Account />
              </Route>
              <Route exact path="/team">
                <Team />
              </Route>
              <Route exact path="/cleancio/admin">
                <AdminHome />
              </Route>
              <Route path="/client/:id">
                <AdminClient />
              </Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Router>
        </UserContext.Provider>
      </GlobalContext.Provider>
    </>
  );
};

export default App;
