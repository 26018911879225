import React, { useEffect } from 'react';

const Unsubscribe: React.FC = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    if (!email) return;
    const url = `/unsubscribe`;

    fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
      method: 'POST',
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });
  }, []);

  return <div>Successfully Unsubscribed</div>;
};

export default Unsubscribe;
