import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import UnitNameButton from '../../../../../cleaning/UnitNameButton';
import CleaningType from '../../../../../cleaning/CleaningType';
import { CalendarProps } from '../Calendar';

// Styles

const MainContainer = styled.div`
  display: flex;
  margin-top: 65px;
  max-height: 400px;
  overflow-y: auto;
`;

const WeeklyView = styled.div`
  overflow-x: auto;
  min-height: 250px;
  height: 100%;
`;

const WeeklyViewScroll = styled.div`
  width: auto;
`;

const WeekDayName = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  padding-bottom: 10px;
`;

const DayHeaderRow = styled.div`
  display: flex;
`;

const DayCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  min-width: 84px;
  height: 60px;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;

const YesterdayCell = styled(DayCell)`
  color: ${(props) => props.theme.outline};
`;

const TodayCell = styled(DayCell)`
  color: ${(props) => props.theme.secondary};
  border-bottom: 2px solid ${(props) => props.theme.secondary};
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  min-width: 200px;
`;

const TotalCleanings = styled.div`
  color: #9c9c9c;
  font-size: 12px;
  font-weight: 700;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
`;

const CalendarColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const CleaningBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
`;

const CleaningBadgeRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalCleaningsIndicator = styled.div`
  color: #9c9c9c;
  font-size: 12px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BadgeContainer = styled.div`
  position: relative;
`;

const ReservationBar = styled.div`
  position: absolute;
  height: 14px;
  border-radius: 8px;
  right: 64px;
`;

// Component

const WeeklyCalendar: React.FC<CalendarProps> = ({
  schedule,
}: CalendarProps) => {
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());

  // Methods

  const firstWeek = (): JSX.Element => {
    const today = moment(currentDate);
    const weekStart = today.startOf('week');
    const endDate = moment().add(4, 'weeks');

    const numberOfDays = endDate.diff(today, 'days');
    const calendarDayColumns: JSX.Element[] = [];

    const loopDay = moment(weekStart);

    for (let day = 1; day < numberOfDays; day++) {
      const weekDayLoop = loopDay.format('ddd');
      const dayLoop = loopDay.format('DD');
      calendarDayColumns.push(
        calendarDayColumn(weekDayLoop, dayLoop, loopDay, day),
      );
      loopDay.add(1, 'day');
    }

    return <DayHeaderRow>{calendarDayColumns}</DayHeaderRow>;
  };

  const calendarDayColumn = (
    weekDay: string,
    weekDate: string,
    date: Moment,
    day: number,
  ): JSX.Element => {
    const header = calendarHeader(weekDay, weekDate, date);
    return (
      <CalendarColumn key={`calendarDayColumn-${day}`}>
        {header}
        {cleaningsPerDay(date)}
      </CalendarColumn>
    );
  };

  const calendarHeader = (weekDay: string, weekDate: string, date: Moment) => {
    if (date.isSame(currentDate, 'day')) {
      return (
        <TodayCell key={`${weekDay}-${weekDate}-0`}>
          <WeekDayName>{weekDay.substr(0, 1)}</WeekDayName>
          {weekDate}
        </TodayCell>
      );
    }
    if (date.isBefore(currentDate, 'day')) {
      return (
        <YesterdayCell key={`${weekDay}-${weekDate}-0`}>
          <WeekDayName>{weekDay.substr(0, 1)}</WeekDayName>
          {weekDate}
        </YesterdayCell>
      );
    }
    return (
      <DayCell key={`${weekDay}-${weekDate}-0`}>
        <WeekDayName>{weekDay.substr(0, 1)}</WeekDayName>
        {weekDate}
      </DayCell>
    );
  };

  const cleaningsPerDay = (date: Moment): JSX.Element => {
    const isToday = date.isSame(moment(), 'day');
    const cleaningBadges: JSX.Element[] = [];
    let totalCleanings = 0;
    schedule?.scheduleItems?.map((scheduleItem, index) => {
      const todayCleaning = scheduleItem.cleaningFor(date);
      const reservationWidth = todayCleaning?.reservation?.numberOfDays() ?? 0;
      if (todayCleaning !== undefined) totalCleanings++;
      cleaningBadges.push(
        <BadgeContainer key={index}>
          <CleaningBadge>
            {todayCleaning && (
              <CleaningType outline circle cleaning={todayCleaning} />
            )}
            <ReservationBar
              style={{
                backgroundColor: scheduleItem.unit?.color ?? '#86D1FA',
                width: `${reservationWidth * 84 - 44}px`,
              }}
            />
          </CleaningBadge>
        </BadgeContainer>,
      );
    });

    return (
      <CleaningBadgeRow
        style={{
          backgroundColor: isToday ? 'rgba(111, 133, 255, 0.2)' : 'transparent',
        }}
      >
        {cleaningBadges}
        <TotalCleaningsIndicator>{totalCleanings}</TotalCleaningsIndicator>
      </CleaningBadgeRow>
    );
  };

  const allUnits = (): JSX.Element => {
    const unitComps: JSX.Element[] = [];

    schedule?.scheduleItems?.map((scheduleItem) => {
      return unitComps.push(
        <UnitNameButton
          bar={false}
          key={scheduleItem.unit?.id}
          unit={scheduleItem.unit}
        />,
      );
    });

    return (
      <ListContainer>
        {unitComps}
        <TotalCleanings>Total Cleanings</TotalCleanings>
      </ListContainer>
    );
  };

  React.useEffect(() => {
    setCurrentDate(moment());
  }, []);

  // Render
  return (
    <MainContainer>
      {allUnits()}
      <WeeklyView>
        <WeeklyViewScroll>{firstWeek()}</WeeklyViewScroll>
      </WeeklyView>
    </MainContainer>
  );
};

export default WeeklyCalendar;
