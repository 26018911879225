import React, { useState, useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { plainToClassFromExist } from 'class-transformer';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import { up } from 'styled-breakpoints';
import styled from 'styled-components/macro';
import UnitSchedule from '../../../models/UnitSchedule';
import ScheduleCard from './views/ScheduleCard';
import UnitInfoCard from './views/UnitInfoCard';
import Cleaning from '../../../models/Cleaning';
import ReportsViewer from './views/ReportsViewer';
import Badge from '../../common/ui/Badge';
import { UnitInfoStatus } from '../../../models/Unit';
import { apiService } from '../../../modules/services/apiService';
import { SectionHeader, UnitContainer, LeftPanel } from './common';
import { GlobalContext } from '../../base/App';
// Props

interface RouteParams {
  id: string;
  cleaning: string;
}

// Styles

const RightPanel = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${up('lg')} {
    flex-direction: row;
  }
`;

const UnitTimeline = styled.div`
  flex-grow: 3;
`;

const UnitInformation = styled.div`
  flex-grow: 1;
  margin-bottom: 30px;
  ${up('lg')} {
    margin-bottom: 0;
    margin-top: 114px;
    margin-left: 50px;
  }
`;

const StyledLink = styled(NavLink)`
  position: relative;
  margin-bottom: 20px;
`;

const CleaningDayHeader = styled.h1`
  font-size: 35px;
  margin-bottom: 35px;

  &:nth-of-type(2n) {
    margin-top: 30px;
  }
`;

const EmptyState = styled.div`
  width: 630px;
  min-height: 500px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: center;
  font-weight: 700;
  color: ${(props) => props.theme.emptyState};
`;

// Component

const UnitActivity: React.FC = () => {
  const { id, cleaning } = useParams<RouteParams>();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [unitSchedule, setUnitSchedule] = useState<UnitSchedule>();
  const [selectedCleaning, setSelectedCleaning] = useState<Cleaning>();
  const [reportType, setReportType] = useState<string>('');
  const { receivedNotificationCount } = useContext(GlobalContext);
  // Networking

  const loadUnitSchedule = async () => {
    const yesterday = moment().subtract(1, 'day').endOf('day').toISOString();
    const weekAhead = moment().add(1, 'week').startOf('day').toISOString();

    try {
      const res = await apiService(
        `/unit/${id}/activity?after=${yesterday}&before=${weekAhead}`,
      );
      const json = await res.json();
      const defaultStatus = new UnitSchedule();
      const schedule = plainToClassFromExist(defaultStatus, json.data);
      setUnitSchedule(schedule);
      scrollToUnit();
    } catch (err: any) {
      console.error(err.message);
    }
  };

  React.useEffect(() => {
    loadUnitSchedule();
  }, []);

  React.useEffect(() => {
    loadUnitSchedule();
  }, [id]);

  React.useEffect(() => {
    loadUnitSchedule().then(() => {
      setModalShow(false);
    });
  }, [receivedNotificationCount]);

  // Methods

  const scrollToUnit = () => {
    if (cleaning) {
      const element = document.getElementById(cleaning);
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const unitCleanings = (cleaningsList?: Cleaning[]): JSX.Element[] => {
    const cleanings: JSX.Element[] = [];
    cleaningsList?.map((cleaning, index) => {
      if (unitSchedule?.unit) {
        cleaning.unit = unitSchedule?.unit;
        cleanings.push(
          <ScheduleCard
            unit={unitSchedule?.unit}
            cleaning={cleaning}
            showReportModal={(type: string) => {
              setReportType(type);
              setSelectedCleaning(cleaning);
              setModalShow(true);
            }}
            key={`${cleaning.cleaningName().replace(' ', '-')}-${index}`}
          />,
        );
      }
    });
    return cleanings;
  };

  const todayCleanings = (): JSX.Element => {
    const cleanings = unitSchedule?.todayCleanings();

    return (
      <>
        <CleaningDayHeader>Today</CleaningDayHeader>
        {unitCleanings(cleanings)}
        {cleanings?.length === 0 && <EmptyState>No cleanings today</EmptyState>}
      </>
    );
  };

  const futureCleanings = (): JSX.Element => {
    const cleanings = unitSchedule?.futureCleanings();
    return (
      <>
        <CleaningDayHeader>This week</CleaningDayHeader>
        {unitCleanings(cleanings)}
        {cleanings?.length === 0 && (
          <EmptyState>No cleanings this week</EmptyState>
        )}
      </>
    );
  };

  // Render

  return (
    <Container fluid>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ReportsViewer
          cleaning={selectedCleaning}
          type={reportType}
          onHide={() => setModalShow(false)}
        />
      </Modal>
      <SectionHeader>Services for {unitSchedule?.unit?.name}</SectionHeader>
      <UnitContainer>
        <LeftPanel>
          <StyledLink exact to={`/unit/${id}`} activeClassName="unitActive">
            Activity
          </StyledLink>
          <StyledLink
            exact
            to={`/unit/${id}/settings`}
            activeClassName="unitActive"
          >
            Details
            {/* {unitSchedule?.unit?.infoStatus !== UnitInfoStatus.COMPLETE && (
              <Badge badgeCount="!" style={{ top: '2px', left: '84px' }} />
            )} */}
          </StyledLink>
        </LeftPanel>
        <RightPanel>
          <UnitTimeline>
            {todayCleanings()}
            {futureCleanings()}
          </UnitTimeline>
          <UnitInformation>
            {unitSchedule?.unit !== undefined && (
              <UnitInfoCard unit={unitSchedule?.unit} />
            )}
          </UnitInformation>
        </RightPanel>
      </UnitContainer>
    </Container>
  );
};

export default UnitActivity;
