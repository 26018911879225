import 'reflect-metadata';
import moment, { Moment } from 'moment';
import { Expose, Type } from 'class-transformer';
import ScheduleItem from './ScheduleItem';
import Cleaning from './Cleaning';

export default class ScheduleContainer {
  @Type(() => ScheduleItem)
  @Expose()
  scheduleItems: ScheduleItem[] = [];

  cleanings(): Cleaning[] {
    const allCleanings: Cleaning[] = [];
    this.scheduleItems.map((scheduleItem) => {
      scheduleItem.cleanings?.map((cleaning) => {
        cleaning.unit = scheduleItem.unit;
        allCleanings.push(cleaning);
      });
    });
    return allCleanings;
  }

  cleaningFor(date: Moment): Cleaning | undefined {
    let foundCleaning = undefined;
    if (this.cleanings() !== undefined) {
      for (const cleaning of this.cleanings()) {
        if (moment(cleaning.date).isSame(date, 'day')) {
          foundCleaning = cleaning;
          break;
        }
      }
    }
    return foundCleaning;
  }

  futureCleanings(): Cleaning[] {
    const today = moment();
    const filteredCleanings = this.cleanings()?.filter((cleaning) => {
      const cleaningDate = moment(cleaning.date);
      return cleaningDate.isSameOrAfter(today, 'day');
    });

    const sortedCleanings = filteredCleanings.sort((lhs, rhs) => {
      const lhsMoment = moment(lhs.date);
      const rhsMoment = moment(rhs.date);
      return lhsMoment.isBefore(rhsMoment) ? -1 : 1;
    });

    return sortedCleanings ?? [];
  }
}
