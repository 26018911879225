import React from 'react';
import Cleaning from '../../models/Cleaning';
import styled from 'styled-components';
import { ReactComponent as CommentIconSvg } from '../../images/icon-comment.svg';
import media from 'styled-media-query';

interface CommentPreviewProps {
  cleaning?: Cleaning;
}

const CommentContainer = styled.div`
  position: relative;
  ${media.lessThan('medium')`
    display: none;
  `}
`;

const CommentText = styled.div`
  position: absolute;
  font-weight: 600;
  font-size: 8px;
  width: 100%;
  max-height: 15px;
  text-align: center;
  vertical-align: center;
  margin-top: 5px;
`;

const CommentPreview: React.FC<CommentPreviewProps> = ({
  cleaning,
}: CommentPreviewProps) => {
  const commentCount = (): string => {
    const comments = cleaning?.commentCount;
    if (comments === undefined) return '0';
    if (comments > 99) {
      return '99+';
    }
    return comments?.toString();
  };

  return (
    <CommentContainer>
      <CommentText>{commentCount()}</CommentText>
      <CommentIconSvg />
    </CommentContainer>
  );
};

export default CommentPreview;
