import 'reflect-metadata';
import _ from 'lodash';
import { Expose } from 'class-transformer';
import { RowContent } from '../components/common/ui/EditableTableRow';

export enum DoorCodeType {
  KEYPAD = 'Keypad',
  LOCKBOX = 'Lockbox',
}

export interface RowContentTransformable {
  id?: string;
  podioId?: string;
  getAsRowContents(): RowContent[];
  setFromRowContents(rowContents: RowContent[]): RowContentTransformable;
}

export default class DoorCode implements RowContentTransformable {
  @Expose() id?: string;
  @Expose() type?: DoorCodeType;
  @Expose() instructions?: string;
  @Expose() buildingCode?: number;
  @Expose() backupCode?: number;

  codeType(): string {
    return _.capitalize(this.type?.toString());
  }

  // Interface Methods

  getAsRowContents(): RowContent[] {
    const doorCodeContents: RowContent[] = [
      {
        content: this.codeType() ?? '',
        dropdownValues: [DoorCodeType.KEYPAD, DoorCodeType.LOCKBOX],
      },
      {
        content: this.instructions ?? '',
        style: { width: '35%' },
      },
      {
        content: this.buildingCode?.toString() ?? '',
      },
      {
        content: this.backupCode?.toString() ?? '',
      },
    ];
    return doorCodeContents;
  }

  getAsStrings(): string[] {
    const doorCodeContents: string[] = [
      this.codeType() ?? '',
      this.instructions ?? '',
      this.buildingCode?.toString() ?? '',
      this.backupCode?.toString() ?? '',
    ];
    return doorCodeContents;
  }

  setFromStrings(strings: string[]): DoorCode {
    const codeString: string = _.toUpper(strings[0]);
    const typedCodeString = codeString as keyof typeof DoorCodeType;
    this.type = DoorCodeType[typedCodeString];
    this.instructions = strings[1];
    this.buildingCode = Number(strings[2]);
    this.backupCode = Number(strings[3]);
    return this;
  }

  setFromRowContents(rowContents: RowContent[]): DoorCode {
    const codeString: string = _.toUpper(rowContents[0].content);
    const typedCodeString = codeString as keyof typeof DoorCodeType;

    this.type = DoorCodeType[typedCodeString];
    this.instructions = rowContents[1].content;
    this.buildingCode = Number(rowContents[2].content);
    this.backupCode = Number(rowContents[3].content);
    return this;
  }
}
