import React, { useState } from 'react';
import styled from 'styled-components';

import { plainToClass } from 'class-transformer';
import VRServiceContainer from '../../../../../../models/VRServiceContainer';
import VRService from '../../../../../../models/VRService';
import SelectableCard from '../../../../../common/ui/SelectableCard';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { ISelectable } from '../../../../../../interfaces/interfaces';

const vrServiceJson = require(`../../../../../../modules/services/vrServices.json`);

const MainHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding-top: 44px;
`;

const SectionHeader = styled.h2`
  font-size: 32px;
  font-weight: 600;
  margin: auto;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin: auto;
  margin-top: 18px;
  width: 60%;
`;

const LeftButton = styled(Button)`
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
`;

const RightButton = styled(Button)`
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
`;

const AccountContainer = styled.div`
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-left: ${isMobile ? 0 : 8}%;
  max-height: 550px;
  overflow-y: auto;
  margin: auto;
`;

export const ModalFooter = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  bottom: 0px;
  margin: auto;
`;

export const StyledButton = styled(Button)`
  margin: auto;
  margin-bottom: 55px;
`;

interface SelectAccountsProps {
  goNext: (account: ISelectable) => void;
}

const SelectAccounts: React.FC<SelectAccountsProps> = ({
  goNext,
}: SelectAccountsProps) => {
  const [vrServices, setVRServices] = useState<VRServiceContainer>();
  const [showOTA, setShowOTA] = useState<boolean>(true);
  const [showPMS, setShowPMS] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [finalAccount, setFinalAccount] = useState<ISelectable>();

  React.useEffect(() => {
    loadAccounts();
  }, []);

  const addSelection = (account: ISelectable, selected: boolean) => {
    if (selected) {
      setSelectedServices([account.id]);
      setFinalAccount(account);
    } else {
      setSelectedServices([]);
      setFinalAccount(undefined);
    }
  };

  const loadAccounts = () => {
    const mappedServices = plainToClass(VRServiceContainer, vrServiceJson);
    setVRServices(mappedServices);
  };

  const renderAccounts = (
    accounts: VRService[],
    type: string,
  ): JSX.Element[] => {
    const renderedList: JSX.Element[] = [];
    accounts?.map((account, index) => {
      const accountIndex = selectedServices.indexOf(account.id, 0);
      const accountSelected = accountIndex > -1;
      renderedList.push(
        <SelectableCard
          account={account}
          key={index}
          preselected={accountSelected}
          delegate={addSelection}
        />,
      );
    });
    const accountIndex = selectedServices.indexOf(`other-${type}`, 0);
    const accountSelected = accountIndex > -1;
    renderedList.push(
      <SelectableCard
        selectable={{ id: `other-${type}` }}
        key={`other-${type}`}
        preselected={accountSelected}
        delegate={addSelection}
      />,
    );
    return renderedList;
  };

  const validateSelection = () => {
    if (finalAccount !== undefined) goNext(finalAccount);
  };

  return (
    <>
      <MainHeader>
        <SectionHeader>Connect your account</SectionHeader>
        <StyledButtonGroup aria-label="Select Online Travel Agencies or Property Management Systems">
          <LeftButton
            variant={showOTA ? 'secondary' : 'outline-secondary'}
            size="sm"
            active={showOTA}
            onClick={() => {
              setShowOTA(true);
              setShowPMS(false);
            }}
          >
            <BrowserView>Online Travel Agencies</BrowserView>
            <MobileView>OTA</MobileView>
          </LeftButton>
          <RightButton
            variant={showPMS ? 'secondary' : 'outline-secondary'}
            size="sm"
            active={showPMS}
            onClick={() => {
              setShowOTA(false);
              setShowPMS(true);
            }}
          >
            <BrowserView>Property Management Systems</BrowserView>
            <MobileView>PMS</MobileView>
          </RightButton>
        </StyledButtonGroup>
      </MainHeader>
      <AccountContainer>
        {showOTA === true && renderAccounts(vrServices?.ota ?? [], 'ota')}
        {showPMS === true && renderAccounts(vrServices?.pms ?? [], 'pms')}
      </AccountContainer>
      <ModalFooter>
        <StyledButton
          disabled={finalAccount === undefined}
          onClick={validateSelection}
        >
          Continue
        </StyledButton>
      </ModalFooter>
    </>
  );
};

export default SelectAccounts;
