import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MoreIcon } from '../../../images/icon-more.svg';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Form from 'react-bootstrap/Form';
import { RowContentTransformable } from '../../../models/DoorCode';
import { isBlank, isEmailValid } from '../../../modules/utils/validation';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

// Props

interface EditableTableRowProps {
  rowContents: string[];
  originalRowContents: RowContent[];
  rowItem: RowContentTransformable;
  editable?: boolean;
  deletable?: boolean;
  validForSubmission?: boolean;
  additionalRows?: JSX.Element;
  updateItem: (contents: string[], rowItem: RowContentTransformable) => void;
  deleteItem?: (rowItem: RowContentTransformable) => void;
}

export interface RowContent {
  content: string;
  style?: React.CSSProperties;
  rawValue?: string;
  dropdownValues?: string[];
  systemGenerated?: boolean;
  placeholder?: string;
  emailValidation?: boolean;
  nonEditingState?: string;
}

// Styles

const TableRow = styled.div`
  position: relative;
  display: flex;
  justify-content: stretch;
`;

const RowContent = styled.div`
  flex-grow: 1;
  min-width: 25%;
  text-align: center;
`;

const TableSeparator = styled.hr`
  max-width: 850px;
  color: ${(props) => props.theme.outline};
`;

const StyledMoreIcon = styled(MoreIcon)`
  position: absolute;
  top: 9px;
  right: -20px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

const UnitPopover = styled.div`
  display: flex;
  flex-direction: column;
`;

const PopoverButton = styled.button`
  border-style: none;
  font-weight: 600;
  background-color: transparent;
  color: ${(props) => props.theme.secondary};

  &:hover {
    opacity: 0.8;
  }
`;

const DangerButton = styled(PopoverButton)`
  color: ${(props) => props.theme.primary};
`;

const StyledFormControl = styled(Form.Control)`
  width: 25%;
  box-shadow: 0 8px 19px 2px ${(props) => props.theme.shadow};
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  margin-top: -7px;
`;

const StyledDropdown = styled(DropdownButton)`
  width: 25%;
  box-shadow: 0 8px 19px 2px ${(props) => props.theme.shadow};
  border-width: 1px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center !important;
  margin-top: -9px;
`;

// Component

const EditableTableRow: React.FC<EditableTableRowProps> = ({
  rowContents,
  editable = true,
  deletable = true,
  originalRowContents,
  additionalRows,
  rowItem,
  validForSubmission = true,
  updateItem,
  deleteItem,
}: EditableTableRowProps) => {
  const firstInput = useRef<HTMLFormElement>(null);
  const [editing, setEditing] = useState<boolean>(false);
  const [currentRowContents, setCurrentRowContents] = useState<string[]>([
    rowContents[0],
    rowContents[1],
    rowContents[2] ?? '',
    rowContents[3] ?? '',
  ]);
  const [value1, setValue1] = useState<string>(rowContents[0] ?? '');
  const [value2, setValue2] = useState<string>(rowContents[1] ?? '');
  const [value3, setValue3] = useState<string>(rowContents[2] ?? '');
  const [value4, setValue4] = useState<string>(rowContents[3] ?? '');
  const valueNames = [value1, value2, value3, value4];
  const valueMap = [setValue1, setValue2, setValue3, setValue4];

  // Methods

  const popoverContent = (): JSX.Element => {
    return (
      <Popover id="popover-basic">
        <Popover.Content>
          <UnitPopover>
            {!editing && (
              <>
                <PopoverButton onClick={beginEditing}>Edit</PopoverButton>
                {deletable && (
                  <DangerButton
                    onClick={() => {
                      if (deleteItem !== undefined) deleteItem(rowItem);
                    }}
                  >
                    Delete
                  </DangerButton>
                )}
              </>
            )}
            {editing && (
              <>
                <PopoverButton onClick={commitSave}>Save</PopoverButton>
                <PopoverButton onClick={cancelEditing}>Cancel</PopoverButton>
              </>
            )}
          </UnitPopover>
        </Popover.Content>
      </Popover>
    );
  };

  const beginEditing = () => {
    setEditing(true);
  };

  const commitSave = () => {
    setEditing(false);
    updateItem([value1, value2, value3, value4], rowItem);
  };

  const saveEdits = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      commitSave();
    }
  };

  const cancelEditing = () => {
    setCurrentRowContents(rowContents);
    setEditing(false);
    if (contentsEmpty(rowContents)) {
      if (deletable && deleteItem !== undefined) deleteItem(rowItem);
    }
  };

  const contentsEmpty = (contentsToCheck: string[]): boolean => {
    let isEmpty = false;
    for (const contents of contentsToCheck) {
      isEmpty = isBlank(contents);
      if (isEmpty === false) {
        break;
      }
    }

    return isEmpty;
  };

  const rowValue = (index: number) => {
    if (originalRowContents[index] !== undefined) {
      if (originalRowContents[index].nonEditingState !== undefined) {
        return originalRowContents[index].nonEditingState;
      }
    }
    return valueNames[index];
  };

  const renderRowContent = (): JSX.Element[] => {
    const rows: JSX.Element[] = [];
    if (editing) {
      currentRowContents.map((rowContent, index) => {
        if (originalRowContents[index] === undefined) return;
        if (originalRowContents[index].systemGenerated) {
          rows.push(<RowContent key={index}>{rowContent}</RowContent>);
        } else {
          if (
            originalRowContents[index] !== undefined &&
            originalRowContents[index].dropdownValues !== undefined
          ) {
            const rowValue = valueNames[index];
            const dropdownRow = (
              <StyledDropdown
                title={isBlank(rowValue) ? 'Select option' : rowValue}
                key={index}
                onKeyPress={saveEdits}
                variant={'light'}
              >
                {originalRowContents[index].dropdownValues?.map(
                  (value, valueIndex) => {
                    return (
                      <Dropdown.Item
                        key={`dropdown-${valueIndex}`}
                        active={rowValue === value}
                        style={{ textAlign: 'center' }}
                        onClick={() => {
                          valueMap[index](value);
                        }}
                      >
                        {value}
                      </Dropdown.Item>
                    );
                  },
                )}
              </StyledDropdown>
            );
            rows.push(dropdownRow);
          } else {
            rows.push(
              <StyledFormControl
                key={index}
                value={valueNames[index]}
                style={originalRowContents[index].style}
                ref={index === 0 ? firstInput : null}
                placeholder={originalRowContents[index].placeholder}
                onChange={(e: any) => {
                  if (originalRowContents[index].emailValidation) {
                    validForSubmission = isEmailValid(e.target.value);
                  }
                  currentRowContents[index] = e.target.value;
                  valueMap[index](e.target.value);
                }}
                onKeyPress={saveEdits}
              />,
            );
          }
        }
      });
    } else {
      currentRowContents.map((rowContent, index) => {
        rows.push(<RowContent key={index}>{rowValue(index)}</RowContent>);
      });
    }

    return rows;
  };

  React.useEffect(() => {
    if (isBlank(rowContents[0])) {
      beginEditing();
    }
  }, []);

  React.useLayoutEffect(() => {
    if (contentsEmpty(currentRowContents)) {
      firstInput?.current?.focus();
    }
  });

  // Component

  return (
    <>
      <TableRow>
        {renderRowContent()}
        {editable && (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={popoverContent()}
            rootClose
          >
            <StyledMoreIcon />
          </OverlayTrigger>
        )}
        {additionalRows}
      </TableRow>
      <TableSeparator />
    </>
  );
};

export default EditableTableRow;
