import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DisclosureSvg } from '../../images/icon-disclosure.svg';
import { ReactComponent as SecondaryDisclosureSvg } from '../../images/icon-disclosure-secondary.svg';
import { ReactComponent as BrokenSvg } from '../../images/icon-broken.svg';
import { ReactComponent as RestockSvg } from '../../images/icon-restock.svg';
import { ReactComponent as GuestSvg } from '../../images/icon-guests.svg';
import { ReactComponent as OtherSvg } from '../../images/icon-other.svg';
import { ReactComponent as CleaningSvg } from '../../images/icon-cleaning.svg';
import Badge from '../common/ui/Badge';

// Props

interface ReportItemProps {
  name: string;
  type: string;
  badgeCount?: number;
  onPress: (type: string) => void;
}

// Styles

const ItemContainer = styled.div`
  display: flex;
  position: relative;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const IconContainer = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 12px;
  text-align: center;
`;

const StyledDisclosure = styled(DisclosureSvg)`
  position: absolute;
  top: 4px;
  right: 17px;
`;

const StyledSecondDisclosure = styled(SecondaryDisclosureSvg)`
  position: absolute;
  top: 4px;
  right: 17px;
`;

// Component

const ReportItemButton: React.FC<ReportItemProps> = ({
  name,
  type,
  badgeCount = 0,
  onPress,
}: ReportItemProps) => {
  const itemIcon = (): JSX.Element => {
    switch (type) {
      case 'broken':
        return <BrokenSvg />;
      case 'restock':
        return <RestockSvg />;
      case 'guests':
        return <GuestSvg />;
      case 'other':
        return <OtherSvg />;
      case 'cleaning':
        return <CleaningSvg />;
      default:
        return <></>;
    }
  };

  const badge = (): JSX.Element => {
    if (badgeCount === 0) return <></>;
    return (
      <Badge
        badgeCount={`${badgeCount}`}
        style={{
          top: type !== 'cleaning' ? '1px' : '5px',
          backgroundColor: type !== 'cleaning' ? '#fb7e70' : '#6f85ff',
        }}
      />
    );
  };

  return (
    <ItemContainer
      style={{
        color: type !== 'cleaning' ? '#000000' : '#6f85ff',
        width: type !== 'cleaning' ? '50%' : '100%',
        borderStyle: 'solid',
        borderWidth: type !== 'cleaning' ? '0px' : '2px',
        borderColor: type !== 'cleaning' ? 'transparent' : '#6f85ff',
        borderRadius: type !== 'cleaning' ? 0 : 5,
        padding: type !== 'cleaning' ? 0 : 5,
      }}
      onClick={() => {
        onPress(type);
      }}
    >
      <IconContainer>{itemIcon()}</IconContainer>
      {name}
      {badge()}
      {type === 'cleaning' && (
        <StyledSecondDisclosure style={{ marginTop: '5px' }} />
      )}
      {type !== 'cleaning' && <StyledDisclosure />}
    </ItemContainer>
  );
};

export default ReportItemButton;
