import 'reflect-metadata';
import moment from 'moment';
import { Expose, Transform, Type } from 'class-transformer';
import DoorCode, { RowContentTransformable } from './DoorCode';
import { isEmpty } from 'lodash';
import { RowContent } from '../components/common/ui/EditableTableRow';

export enum UnitInfoStatus {
  INCOMPLETE = 'missing',
  COMPLETE = 'complete',
}

export enum LinenRentalStatus {
  YES = 'yes',
  NO = 'no',
}

const dateTransformer = (dateObj: any): Date => {
  const newDate = moment(dateObj).toDate();
  return newDate;
};

export default class Unit implements RowContentTransformable {
  @Expose() id?: string;
  @Expose() podioId?: string;
  @Expose() name?: string;
  @Expose() color?: string;
  @Expose() clientAppEnabled = false;
  @Expose() infoStatus?: UnitInfoStatus;
  @Expose()
  averageTimeToClean = 0;
  @Transform((dateObj) => dateTransformer(dateObj))
  @Expose()
  checkInTime?: Date;
  @Transform((dateObj) => dateTransformer(dateObj))
  @Expose()
  checkOutTime?: Date;
  @Expose() calendarLink?: any;
  @Expose() calendarProvider?: string;
  @Expose() linenRentalStatus?: LinenRentalStatus;
  @Expose() address?: string;
  @Expose() apt?: string;
  @Expose() city?: string;
  @Expose() state?: string;
  @Expose() zip?: string;
  @Expose() numBathrooms?: number;
  @Expose() numBedrooms?: number;
  @Expose() wifiName?: string;
  @Expose() wifiPass?: string;
  @Expose() doorCodesField?: string;
  @Expose() garbageLocationInstructions?: string;
  @Expose() garbageLocationPhotoUrls: string[] = [];
  @Expose() mainEntrancePhotoUrls: string[] = [];
  @Expose() bedroomPhotoUrls: string[] = [];
  @Expose() livingRoomPhotoUrls: string[] = [];
  @Expose() bathroomPhotoUrls: string[] = [];
  @Expose() kitchenPhotoUrls: string[] = [];
  @Expose() lockboxLocationPhotoUrls: string[] = [];
  @Expose() buildingKeypadPhotoUrls: string[] = [];
  @Type(() => DoorCode)
  @Expose()
  @Expose()
  doorCodes: DoorCode[] = [];

  hasLinenRental(): boolean {
    return this.linenRentalStatus === LinenRentalStatus.YES;
  }

  calendarLinkStatus(): string {
    if (this.calendarLink === undefined || isEmpty(this.calendarLink)) {
      return 'Not added';
    } else {
      return 'Added';
    }
  }

  formattedDuration(): string {
    const duration = moment.duration(this.averageTimeToClean * 1000);
    const hours = moment.utc(duration.asMilliseconds()).format('h');
    const minutes = moment.utc(duration.asMilliseconds()).format('m');
    let minutesString;
    if (minutes !== '0') {
      minutesString = ` ${minutes}m`;
    }
    return `${hours}h${minutesString || ''}`;
  }

  formattedCheckInTime(): string {
    const timeFormat = 'h:mm a';
    return moment(this.checkInTime).format(timeFormat);
  }

  formattedCheckOutTime(): string {
    const timeFormat = 'h:mm a';
    return moment(this.checkOutTime).format(timeFormat);
  }

  // RowContentTransformable

  getAsStrings(): string[] {
    const unitContents: string[] = [
      this.name ?? '',
      this.calendarLinkStatus(),
      this.clientAppEnabled ? 'yes' : 'no',
    ];
    return unitContents;
  }

  setFromStrings(strings: string[]): Unit {
    const enableInClientApp: string = strings[0];
    this.clientAppEnabled = enableInClientApp.toLowerCase() === 'yes';
    return this;
  }

  getAsRowContents(): RowContent[] {
    const userContents: RowContent[] = [
      {
        content: this.name ?? '',
        systemGenerated: true,
      },
      {
        content: this.calendarLinkStatus(),
        rawValue: this.calendarLink,
        systemGenerated: true,
      },
      {
        content: this.clientAppEnabled ? 'yes' : 'no',
        dropdownValues: ['Yes', 'No'],
      },
    ];
    return userContents;
  }

  setFromRowContents(rowContents: RowContent[]): Unit {
    this.name = rowContents[0].content;
    this.calendarLink = rowContents[1].rawValue ?? '';
    this.clientAppEnabled = rowContents[2].content.toLowerCase() === 'yes';

    return this;
  }
}
