import 'reflect-metadata';
import Comment from './Comment';
import { Expose, Type } from 'class-transformer';

export default class CommentContainer {
  @Expose()
  unitID?: string;

  @Expose()
  cleaningID?: string;

  @Expose()
  totalComments = 0;

  @Type(() => Comment)
  @Expose()
  comments: Comment[] = [];
}
