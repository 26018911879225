import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Cleaning from '../../../../../../models/Cleaning';
import MonthlyCell from './MonthlyCell';
import DotText from '../../../../../common/ui/DotText';

interface MonthlyCalProps {
  cleanings: Cleaning[] | undefined;
}

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
`;

const CalendarTable = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const CalendarHeading = styled.th`
  text-align: center;
`;

const CalendarRow = styled.tr`
  height: 120px;
`;

const MonthYear = styled.div`
  display: flex;
  margin-bottom: 80px;
`;

const Legend = styled.div`
  display: flex;
  margin-left: 40px;
  justify-content: space-between;
`;

const Space = styled.div`
  width: 20px;
`;

// Component

const MonthlyCalendar: React.FC<MonthlyCalProps> = ({
  cleanings,
}: MonthlyCalProps) => {
  const [currentDate, setCurrentDate] = useState<moment.Moment>();
  const [currentMonth, setCurrentMonth] = useState<number>();
  const [currentYear, setCurrentYear] = useState<number>(0);
  const [allMonths] = useState<string[]>(moment.months());

  const calendarHeadings = (): JSX.Element[] => {
    const weekdayshort = moment.weekdaysShort();
    const weekdayshortname = weekdayshort.map((day) => {
      return <CalendarHeading key={`weekday-${day}`}>{day}</CalendarHeading>;
    });
    return weekdayshortname;
  };

  const startingCalendarDays = (): JSX.Element[] => {
    const blanks = [];
    for (let i = 0; i < firstDayOfMonth(); i++) {
      blanks.push(
        <td className="calendar-day empty" key={i}>
          {''}
        </td>,
      );
    }
    return blanks;
  };

  const remainingCalendarDays = (): JSX.Element[] => {
    const daysInMonth = [];
    for (let d = 1; d <= (currentDate?.daysInMonth() ?? 0); d++) {
      daysInMonth.push(calendarDayComponent(d));
    }
    return daysInMonth;
  };

  const fullCalendar = () => {
    const totalSlots = [...startingCalendarDays(), ...remainingCalendarDays()];
    const rows: any = [];
    let cells: JSX.Element[] = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row); // if index not equal 7 that means not go to next week
      } else {
        rows.push(cells); // when reach next week we contain all td in last week to rows
        cells = []; // empty container
        cells.push(row); // in current loop we still push current row to new container
      }
      if (i === totalSlots.length - 1) {
        // when end loop we add remain date
        rows.push(cells);
      }
    });

    const daysinmonth = rows.map((calendarCells: JSX.Element[], i: number) => {
      if (calendarCells.length === 0) return [];
      return (
        <CalendarRow key={`calendar-row-${i}`}>{calendarCells}</CalendarRow>
      );
    });

    return daysinmonth;
  };

  const firstDayOfMonth = (): number => {
    const firstDay = Number(moment(currentDate).startOf('month').format('d'));
    return firstDay;
  };

  const years = () => {
    if (currentDate !== undefined) {
      const nextten = moment()
        .set('year', moment().year())
        .add('year', 12)
        .format('Y');
      const dateArray = [];
      let currentDateObj = moment();
      const endDate = moment(nextten);
      while (currentDateObj <= endDate) {
        dateArray.push(moment(currentDateObj).format('YYYY'));
        currentDateObj = moment(currentDateObj).add(1, 'year');
      }
      return dateArray;
    }
    return null;
  };

  const monthDropdown = (): JSX.Element => {
    return (
      <DropdownButton title={monthName() ?? ''} variant={'light'}>
        {allMonths.map((month, index) => {
          return (
            <Dropdown.Item
              key={`dropdown-${index}`}
              active={currentMonth === index}
              onClick={() => {
                setCurrentDate(moment(currentDate).set('month', index));
                setCurrentMonth(index);
              }}
            >
              {month}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    );
  };

  const yearDropdown = (): JSX.Element => {
    const tenYears = years() ?? [];
    return (
      <DropdownButton title={tenYears[currentYear] ?? ''} variant={'light'}>
        {tenYears.map((year, index) => {
          return (
            <Dropdown.Item
              key={`dropdown-${index}`}
              active={currentYear === index}
              onClick={() => {
                setCurrentDate(moment(currentDate).set('year', Number(year)));
                setCurrentYear(index);
              }}
            >
              {year}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    );
  };

  const monthName = () => {
    if (allMonths !== undefined && currentMonth !== undefined) {
      return allMonths[currentMonth];
    }
  };

  const calendarDayComponent = (day: number): JSX.Element => {
    return (
      <MonthlyCell
        key={`day-${day}`}
        day={day}
        currentDate={currentDate}
        cleanings={cleanings}
      />
    );
  };

  React.useEffect(() => {
    setCurrentDate(moment());
    setCurrentMonth(moment().month());
  }, []);

  return (
    <CalendarContainer>
      <MonthYear>
        {monthDropdown()}
        {yearDropdown()}
        <Legend>
          <DotText color="#FB7E70" message="Same day check-in(s)" bold />
          <Space />
          <DotText color="#B9C4FF" message="Regular cleaning(s)" bold />
        </Legend>
      </MonthYear>
      <CalendarTable>
        <thead>
          <tr>{calendarHeadings()}</tr>
        </thead>
        <tbody>{fullCalendar()}</tbody>
      </CalendarTable>
    </CalendarContainer>
  );
};

export default MonthlyCalendar;
