import 'reflect-metadata';
import moment from 'moment';
import _ from 'lodash';
import { Expose, Type } from 'class-transformer';

export enum ReportType {
  BROKEN = 'broken',
  GUEST_IN_UNIT = 'guests',
  RESTOCK = 'restock',
  OTHER = 'other',
  CLEANING_PHOTOS = 'cleaning_photos',
}

export enum ReportSubCategory {
  FURNITURE = 'furniture',
  APPLIANCE = 'appliance',
  LINENS = 'linens',
  ALMOST_READY = 'almost_ready',
  STILL_PACKING = 'still_packing',
  LUGGAGE_INSIDE = 'luggage_inside',
  KITCHEN = 'kitchen',
  BATHROOM = 'bathroom',
  LAUNDRY = 'laundry',
  BEDROOM = 'bedroom',
  LIVING_ROOM = 'living_room',
  OTHER = 'other',
}

export default class Comment {
  @Expose() id?: string;
  @Expose() senderName?: string;
  @Expose() message?: string;
  @Expose() type?: ReportType;
  @Expose() subCategory?: ReportSubCategory;
  @Expose() avatar?: string;
  @Expose() createdBy?: string;
  @Type(() => Date)
  @Expose()
  createdDate?: Date;
  @Expose() photoUrls: string[] = [];

  getSenderInitials(): string {
    if (this.senderName === null || this.senderName === undefined) {
      return '';
    }
    const senderSplit = this.senderName.split(' ');
    const firstPart = senderSplit[0];
    if (senderSplit.length > 1) {
      const secondPart = senderSplit[1];
      return `${firstPart.substr(0, 1)}${secondPart.substr(0, 1)}`;
    }
    return `${firstPart.substr(0, 1)}`;
  }

  getSenderFirstName(): string {
    if (this.senderName === null || this.senderName === undefined) {
      return '';
    }
    return `${this.senderName.split(' ')[0]}`;
  }

  getAgo(): string {
    if (this.createdDate === undefined) return '';
    return moment(this.createdDate).fromNow(true);
  }

  getSubCategory(): string {
    return _.toUpper(this.subCategory?.replace('_', ' ') ?? '');
  }
}
