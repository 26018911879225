import { plainToClassFromExist, serialize } from 'class-transformer';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from '../../common/controls/Button';
import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ClientWorkspace from '../../../models/ClientWorkspace';
import { apiService } from '../../../modules/services/apiService';
import FormHeader from '../../common/ui/FormHeader';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import SettingsFormField from '../../common/ui/SettingsFormField';
import ClientAppUser from '../../../models/ClientAppUser';
import { isBlank, isUrlValid } from '../../../modules/utils/validation';
import EditableTableRow from '../../common/ui/EditableTableRow';
import Unit from '../../../models/Unit';
import { isEmpty } from 'lodash';
import ClientAgreement from '../../../models/ClientAgreement';
import Modal from 'react-bootstrap/Modal';
import { Redirect } from 'react-router-dom';

// Props

interface RouteParams {
  id: string;
}

// Styles

const SectionHeader = styled.h2`
  font-size: 35px;
  font-weight: 600;
  margin-left: 50px;
  margin-bottom: 40px;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 20px;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  justify-content: stretch;
  padding-bottom: 50px;
  width: 100%;
  max-width: 900px;
`;

const AdminContainer = styled.div`
  display: flex;
`;

const StyledLink = styled(NavLink)`
  position: relative;
  margin-bottom: 20px;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: stretch;
  width: 100%;
`;

const FormName = styled.div`
  font-weight: 600;
  min-width: 100px;
  margin-right: 30px;
`;

const FormNameGrow = styled(FormName)`
  width: 100px;
`;

const FormItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const FormItemLeft = styled(FormItem)`
  align-items: center;
  flex-grow: 1;
`;

const BlueTableHeader = styled.div`
  display: flex;
  justify-content: stretch;
  width: 900px;
  background-color: ${(props) => props.theme.secondary};
  border-radius: 5px;
  color: white;
  font-weight: 700;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const TableSection = styled.div`
  width: 25%;
  text-align: center;
  white-space: nowrap;
`;

const BlueTableContent = styled.div`
  width: 900px;
  padding: 10px;
`;

const CommitChangesContainer = styled.div`
  display: flex;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 60px;
  width: 600px;
`;

const AddTableRowButton = styled.button`
  font-style: italic;
  font-weight: 600;
  color: ${(props) => props.theme.secondary};
  margin-left: 40px;
  background-color: transparent;
  border-width: 0px;
  text-align: left;
  margin-bottom: 30px;
  max-width: 200px;

  &:hover {
    opacity: 0.8;
  }
`;

const GeneralContent = styled.div`
  margin-bottom: 30px;
`;

const RowContent = styled.div`
  min-width: 25%;
  flex-grow: 1;
  text-align: center;
`;

const GrowableButton = styled(Button)`
  flex-grow: 1;
`;

// Component

const AdminClient: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const [client, setClient] = useState<ClientWorkspace>();
  const [accountEnabled, setAccountEnabled] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [redirectToClientList, setRedirectToClientList] = useState(false);
  const [calendarSyncEnabled, setCalendarSyncEnabled] = useState(false);
  const [podioConnected, setPodioConnected] = useState(false);
  const [companyName, setCompanyName] = useState<string>('');
  const [workspaceURL, setWorkspaceURL] = useState<string>('');
  const [members, setMembers] = useState<ClientAppUser[]>([]);
  const [units, setUnits] = useState<Unit[]>([]);
  const [agreements, setAgreements] = useState<ClientAgreement[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [podioRefreshing, setPodioRefreshing] = useState<boolean>(false);

  // Row Contents

  const unitListContents = (): JSX.Element[] => {
    const rowContents: JSX.Element[] = [];

    units?.map((unit, index) => {
      rowContents.push(
        <EditableTableRow
          rowContents={unit.getAsStrings()}
          originalRowContents={unit.getAsRowContents()}
          rowItem={unit}
          deletable={false}
          updateItem={(contents, rowItem) => {
            const unitsCopy = [...units];
            const updatedItem = new Unit();
            updatedItem.id = rowItem.id;
            updatedItem.podioId = rowItem.podioId;
            updatedItem.setFromStrings(contents);
            let count = 0;
            for (const iUnit of units) {
              if (iUnit.id === updatedItem.id) {
                unitsCopy[count] = updatedItem;
                break;
              }
              count++;
            }

            setUnits(unitsCopy);
          }}
          additionalRows={
            <RowContent
              style={{ right: '0px', position: 'absolute', bottom: '0px' }}
            >
              <a
                href={`${client?.workspaceURL}/item/${unit.podioId}`.replace(
                  '//item',
                  '/item',
                )}
                target="_blank"
                rel="noreferrer"
              >
                View in Podio
              </a>
            </RowContent>
          }
          key={index}
        />,
      );
    });

    return rowContents;
  };

  const teamMemberContents = (): JSX.Element[] => {
    const rowContents: JSX.Element[] = [];

    members?.map((member, index) => {
      rowContents.push(
        <EditableTableRow
          rowContents={member.getAsStrings()}
          originalRowContents={member.getAsRowContents()}
          rowItem={member}
          validForSubmission={false}
          updateItem={(contents, rowItem) => {
            const membersCopy = [...members];
            const updatedItem = new ClientAppUser();
            updatedItem.id = rowItem.id;
            updatedItem.setFromStrings(contents);
            let count = 0;
            for (const iMember of members) {
              if (iMember.id === updatedItem.id) {
                membersCopy[count] = updatedItem;
                break;
              }
              count++;
            }

            setMembers(membersCopy);
          }}
          deleteItem={(memberToRemove) => {
            const newMembers = members.filter((item) => {
              return item.id !== memberToRemove.id;
            });
            setMembers(newMembers);
          }}
          key={member.id}
        />,
      );
    });

    return rowContents;
  };

  const agreementContents = (): JSX.Element[] => {
    const rowContents: JSX.Element[] = [];

    agreements?.map((agreement, index) => {
      rowContents.push(
        <EditableTableRow
          rowContents={agreement.getAsStrings()}
          originalRowContents={agreement.getAsRowContents()}
          rowItem={agreement}
          validForSubmission={false}
          updateItem={(contents, rowItem) => {
            const agreementsCopy = [...agreements];
            const updatedItem = new ClientAgreement();
            updatedItem.id = rowItem.id;
            updatedItem.setFromStrings(contents);
            let count = 0;
            for (const iAgreement of agreements) {
              if (iAgreement.id === updatedItem.id) {
                agreementsCopy[count] = updatedItem;
                break;
              }
              count++;
            }

            setAgreements(agreementsCopy);
          }}
          deleteItem={(agreementToRemove) => {
            setAgreements(
              agreements.filter((item) => item !== agreementToRemove),
            );
          }}
          additionalRows={
            agreement?.link ? (
              <RowContent
                style={{ right: '0px', position: 'absolute', bottom: '0px' }}
              >
                <a href={agreement.link} target="_blank" rel="noreferrer">
                  View Agreement
                </a>
              </RowContent>
            ) : (
              <></>
            )
          }
          key={agreement.id}
        />,
      );
    });

    return rowContents;
  };

  // Form Methods

  const addTempMember = () => {
    const newMember = new ClientAppUser();
    newMember.id = `TEMP-${Math.random() * 100000000}`;
    newMember.createdAt = moment().toDate();
    setMembers(members.concat([newMember]));
  };

  const addAgreement = () => {
    const tempAgreement = new ClientAgreement();
    tempAgreement.id = `TEMP-AGMT-${Math.random() * 100000000}`;
    setAgreements(agreements.concat(tempAgreement));
  };

  const connectPodio = () => {
    if (
      workspaceURL === undefined ||
      isEmpty(workspaceURL) ||
      !isUrlValid(workspaceURL) ||
      !workspaceURL.includes('podio.com')
    ) {
      alert('Please add a valid workspace URL.');
      return;
    }
    setPodioRefreshing(true);
    apiService(
      `/client/${id}/connect`,
      'post',
      JSON.stringify({ workspaceURL, accountEnabled, calendarSyncEnabled }),
    )
      .then((res) => {
        if (res.ok) {
          setPodioConnected(true);
        } else {
          alert(`Podio unable to connect.`);
          setPodioConnected(false);
        }
      })
      .catch((err) => {
        alert(`Podio unable to connect: ${err}`);
        setPodioConnected(false);
        console.error(err);
      })
      .finally(() => {
        setPodioRefreshing(false);
      });
  };

  // Submission Generate Methods

  const validateKeyFields = (): string[] => {
    const validationErrors: string[] = [];
    if (isEmpty(companyName)) {
      validationErrors.push('Please add a company name.');
    }

    if (isEmpty(workspaceURL) || !isUrlValid(workspaceURL)) {
      validationErrors.push('Please add a valid workspace URL.');
    }

    return validationErrors;
  };

  const validateMembers = (): string[] => {
    let membersValid = true;

    members.map((member) => {
      if (!member.validForSubmission()) {
        membersValid = false;
      }
    });

    return membersValid ? [] : ['Please ensure all User fields are correct.'];
  };

  // Send Form

  const saveChanges = () => {
    let validationErrors: string[] = [];
    validationErrors = validationErrors.concat(validateMembers());
    validationErrors = validationErrors.concat(validateKeyFields());

    if (validationErrors.length > 0) {
      alert(validationErrors.join('. '));
      return;
    }

    setSubmitting(true);

    const updatePayload = {
      accountEnabled,
      calendarSyncEnabled,
      companyName,
      workspaceURL,
      agreements: agreements,
      users: members,
      units: units,
    };

    apiService(`/client/${id}`, 'put', serialize(updatePayload))
      .then((res) => res.json())
      .then((response) => {
        try {
          const updatedClient = plainToClassFromExist(client, response.data);
          setClient(updatedClient);
          loadClient();
        } catch (e: any) {
          console.error(e);
          alert(`An error occurred: ${e.message}`);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.error(err);
        alert(`Error updating Client: ${err}`);
        setSubmitting(false);
      });
  };

  const deleteClient = () => {
    setConfirmDelete(true);
  };

  const completeDeleteClient = () => {
    apiService(`/client/${id}`, 'delete')
      .then((response) => {
        setRedirectToClientList(true);
      })
      .catch((err) => {
        console.error(err);
        alert(`Error deleting Client: ${err}`);
      });
  };

  // Networking

  const loadClient = async () => {
    apiService(`/client/${id}`)
      .then((res) => res.json())
      .then((response) => {
        try {
          const defaultClient = new ClientWorkspace();
          const loadedClient = plainToClassFromExist(
            defaultClient,
            response.data,
          );

          setAccountEnabled(loadedClient.accountEnabled);
          setCalendarSyncEnabled(loadedClient.calendarSyncEnabled);
          setCompanyName(loadedClient.companyName ?? '');
          setWorkspaceURL(loadedClient.workspaceURL ?? '');
          setMembers(loadedClient.users ?? []);
          setAgreements(loadedClient.agreements ?? []);
          setPodioConnected(loadedClient.podioConnected ?? false);
          setUnits(loadedClient.units ?? []);

          setClient(loadedClient);
        } catch (e) {
          console.error(e);
        }
      });
  };

  useEffect(() => {
    loadClient();
  }, [id]);

  // Render
  return (
    <Container fluid>
      {redirectToClientList && <Redirect push to={'/cleancio/admin'} />}
      <Modal show={confirmDelete} centered>
        <Modal.Header>
          <Modal.Title>Really delete this Client?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          All user accounts associated with this Client will also be removed.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              completeDeleteClient();
            }}
          >
            Delete
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              setConfirmDelete(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <SectionHeader>Client: {client?.companyName}</SectionHeader>
      <AdminContainer>
        <LeftPanel>
          <StyledLink exact to="/cleancio/admin" activeClassName="unitActive">
            Clients
          </StyledLink>
        </LeftPanel>
        <RightPanel>
          <FormHeader>Important Settings</FormHeader>
          <FormRow>
            <FormItemLeft>
              <FormName>Account Status</FormName>
              <BootstrapSwitchButton
                checked={accountEnabled}
                onlabel="On"
                offlabel="Off"
                onstyle="secondary"
                offstyle="info"
                onChange={(checked: boolean) => {
                  setAccountEnabled(checked);
                }}
              />
            </FormItemLeft>
            <FormItemLeft>
              <FormName>Calendar Sync</FormName>
              <BootstrapSwitchButton
                checked={calendarSyncEnabled}
                onlabel="On"
                offlabel="Off"
                onstyle="secondary"
                offstyle="info"
                onChange={(checked: boolean) => {
                  setCalendarSyncEnabled(checked);
                }}
              />
            </FormItemLeft>
          </FormRow>
          <FormItem>
            <FormNameGrow>Company Name</FormNameGrow>
            <SettingsFormField
              value={companyName}
              showError={isBlank(companyName)}
              placeholder="The name of the Client company (i.e., ShortStay)"
              onChange={(e: any) => {
                setCompanyName(e.target.value);
              }}
            />
          </FormItem>
          <FormItem>
            <FormNameGrow>Workspace URL</FormNameGrow>
            <SettingsFormField
              value={workspaceURL}
              showError={!isUrlValid(workspaceURL)}
              placeholder="Enter Podio workspace URL"
              onChange={(e: any) => {
                setWorkspaceURL(e.target.value);
              }}
            />
          </FormItem>
          <FormHeader>Users</FormHeader>
          <BlueTableHeader>
            <TableSection>Name</TableSection>
            <TableSection>Permissions</TableSection>
            <TableSection>Email Address</TableSection>
            <TableSection>Created</TableSection>
          </BlueTableHeader>
          <BlueTableContent>{teamMemberContents()}</BlueTableContent>
          <AddTableRowButton onClick={addTempMember}>
            + Add team member
          </AddTableRowButton>
          <FormHeader>Agreement</FormHeader>
          <BlueTableHeader>
            <TableSection>Document</TableSection>
            <TableSection>Link</TableSection>
            <TableSection></TableSection>
          </BlueTableHeader>
          <BlueTableContent>{agreementContents()}</BlueTableContent>
          {agreements.length === 0 && (
            <AddTableRowButton onClick={addAgreement}>
              + Add agreement
            </AddTableRowButton>
          )}
          <FormHeader>Podio App Connection</FormHeader>
          <GeneralContent>
            <p>
              Current status: {podioConnected ? 'Connected' : 'Disconnected'}
            </p>
            <Button
              variant="link"
              onClick={connectPodio}
              loading={podioRefreshing}
              loadingText="Refreshing"
            >
              Refresh Podio connection
            </Button>
          </GeneralContent>
          <FormHeader>Units</FormHeader>
          <BlueTableHeader>
            <TableSection>Unit Code</TableSection>
            <TableSection>Calendar Link</TableSection>
            <TableSection>Client App Enabled</TableSection>
            <TableSection></TableSection>
          </BlueTableHeader>
          {podioConnected && (
            <BlueTableContent>{unitListContents()}</BlueTableContent>
          )}
          {!podioConnected && (
            <AddTableRowButton disabled={true}>
              Please connect your Podio workspace. Units will then be loaded
              here.
            </AddTableRowButton>
          )}
          {podioConnected && units.length === 0 && (
            <AddTableRowButton disabled={true}>
              Units are being loaded. If none appear on page refresh, confirm
              that the Podio Workspace has Unit Codes.
            </AddTableRowButton>
          )}
          <CommitChangesContainer>
            <GrowableButton
              variant="primary"
              disabled={submitting}
              style={{ marginRight: '24px' }}
              onClick={saveChanges}
              loading={submitting}
              loaderVariant="light"
            >
              Save changes
            </GrowableButton>
            <GrowableButton
              variant="outline-primary"
              disabled={submitting}
              onClick={deleteClient}
            >
              Delete client
            </GrowableButton>
          </CommitChangesContainer>
        </RightPanel>
      </AdminContainer>
    </Container>
  );
};

export default AdminClient;
