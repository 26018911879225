import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import { NavLink, Redirect } from 'react-router-dom';
import { UserContext } from '../../base/App';
import ClientWorkspace from '../../../models/ClientWorkspace';
import { apiService } from '../../../modules/services/apiService';
import { plainToClass } from 'class-transformer';
import Button from 'react-bootstrap/Button';

const SectionHeader = styled.h2`
  font-size: 35px;
  font-weight: 600;
  margin-left: 50px;
  margin-bottom: 40px;
`;

const AdminContainer = styled.div`
  display: flex;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 20px;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  justify-content: stretch;
  padding-bottom: 50px;
`;

const StyledLink = styled(NavLink)`
  position: relative;
  margin-bottom: 20px;
`;

const BlueTableHeader = styled.div`
  display: flex;
  justify-content: stretch;
  width: 900px;
  background-color: ${(props) => props.theme.secondary};
  border-radius: 5px;
  color: white;
  font-weight: 700;
  padding: 10px;
  margin-bottom: 10px;
`;

const TableSection = styled.div`
  width: 25%;
  text-align: center;
  white-space: nowrap;
`;

const BlueTableContent = styled.div`
  width: 900px;
  padding: 10px;
`;

const TableRow = styled.div`
  position: relative;
  display: flex;
  justify-content: stretch;
`;

const RowContent = styled.div`
  width: 25%;
  text-align: center;
`;

const TableSeparator = styled.hr`
  max-width: 850px;
  color: ${(props) => props.theme.outline};
`;

const AddTableRowButton = styled.button`
  font-style: italic;
  font-weight: 600;
  color: ${(props) => props.theme.secondary};
  margin-left: 40px;
  background-color: transparent;
  border-width: 0px;
  text-align: left;

  &:hover {
    opacity: 0.8;
  }
`;

// Component

const AdminHome: React.FC = () => {
  const { currentUser } = useContext(UserContext);
  const [adminChecked, setAdminChecked] = useState(false);
  const [adminAllowed, setAdminAllowed] = useState(false);
  const [addingClient, setAddingClient] = useState(false);
  const [showClient, setShowClient] = useState<ClientWorkspace>();
  const [clients, setClients] = useState<ClientWorkspace[]>([]);

  // Methods

  const addClient = async () => {
    setAddingClient(true);
    try {
      setAddingClient(true);
      const res = await apiService('/clients', 'post', { clientName: '' });
      const json = await res.json();
      const newClient = plainToClass(ClientWorkspace, json.data);
      setShowClient(newClient);
    } catch (e) {
      console.error(e);
      setAddingClient(false);
    }
  };

  const clientContents = (): JSX.Element[] => {
    const rowContents: JSX.Element[] = [];

    clients?.map((client, index) => {
      rowContents.push(
        <div key={index}>
          <TableRow>
            <RowContent>{client.companyName}</RowContent>
            <RowContent>{client.activeUsers()}</RowContent>
            <RowContent>{client.status()}</RowContent>
            <RowContent>{client.enabled()}</RowContent>
            <RowContent
              style={{ position: 'absolute', right: '-80px', bottom: '-8px' }}
            >
              <Button
                variant="link"
                onClick={() => {
                  setShowClient(client);
                }}
              >
                View
              </Button>
            </RowContent>
          </TableRow>
          <TableSeparator />
        </div>,
      );
    });

    return rowContents;
  };

  const loadClients = async () => {
    try {
      const res = await apiService('/clients');
      const json = await res.json();
      const clients = plainToClass(
        ClientWorkspace,
        json.data as ClientWorkspace[],
      );
      setClients(clients);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (currentUser?.superAdmin) {
      setAdminAllowed(true);
      setAdminChecked(true);
      loadClients();
    }
  }, [currentUser]);

  // Render

  return (
    <>
      {showClient && <Redirect push to={`/client/${showClient.id}`} />}
      {adminChecked && (
        <Container fluid>
          {adminAllowed && (
            <>
              <SectionHeader>Admin Dashboard</SectionHeader>
              <AdminContainer>
                <LeftPanel>
                  <StyledLink
                    exact
                    to="/cleancio/admin"
                    activeClassName="unitActive"
                  >
                    Clients
                  </StyledLink>
                </LeftPanel>
                <RightPanel>
                  <BlueTableHeader>
                    <TableSection>Client Name</TableSection>
                    <TableSection>Users</TableSection>
                    <TableSection>Status</TableSection>
                    <TableSection>Account Enabled</TableSection>
                  </BlueTableHeader>
                  <BlueTableContent>{clientContents()}</BlueTableContent>
                  <>
                    <AddTableRowButton
                      disabled={addingClient}
                      onClick={addClient}
                    >
                      + Add client
                    </AddTableRowButton>
                  </>
                </RightPanel>
              </AdminContainer>
            </>
          )}
          {!adminAllowed && (
            <>
              <Redirect to="/" />
            </>
          )}
        </Container>
      )}
    </>
  );
};
export default AdminHome;
