import 'reflect-metadata';
import { Expose, Type } from 'class-transformer';
import VRService from './VRService';

export default class VRServiceContainer {
  @Type(() => VRService)
  @Expose()
  ota?: VRService[];
  @Type(() => VRService)
  @Expose()
  pms?: VRService[];

  searchFor(name: string): VRService | undefined {
    let foundVR = undefined;
    if (this.ota) {
      for (const service of this.ota) {
        if (service.name?.includes(name)) {
          foundVR = service;
          break;
        }
      }
    }
    if (this.pms) {
      for (const service of this.pms) {
        if (service.name?.includes(name)) {
          foundVR = service;
          break;
        }
      }
    }
    return foundVR;
  }
}
