import 'reflect-metadata';
import Comment from './Comment';
import { Expose, Type } from 'class-transformer';

export default class CleaningPhotosContainer {
  @Type(() => Comment)
  @Expose()
  bedroomOrBathroom: Comment[] = [];

  @Type(() => Comment)
  @Expose()
  kitchen: Comment[] = [];

  @Type(() => Comment)
  @Expose()
  living: Comment[] = [];

  @Type(() => Comment)
  @Expose()
  other: Comment[] = [];
}
