import React from 'react';
import styled from 'styled-components';

// Props

interface DotTextProps {
  color: string;
  message: string | undefined;
  bold?: boolean;
}

// Styles

const ActivityCircle = styled.div`
  border-radius: 50%;
  display: inline-block;
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
`;

const MessageContainer = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
`;

// Component

const DotText: React.FC<DotTextProps> = ({
  color,
  message,
  bold = false,
}: DotTextProps) => {
  return (
    <MessageContainer style={{ fontWeight: bold ? 700 : 400 }}>
      <ActivityCircle style={{ backgroundColor: color, marginRight: '8px' }} />
      {message}
    </MessageContainer>
  );
};

export default DotText;
