import { Auth } from 'aws-amplify';

interface AuthData {
  userInfo: object;
  accessToken: string;
}

const authData = async (): Promise<AuthData> => {
  const cognitoSession = await Auth.currentSession();
  const userInfo = await Auth.currentUserInfo();
  const accessToken = cognitoSession.getAccessToken().getJwtToken();
  return {
    userInfo,
    accessToken,
  };
};

export default authData;
