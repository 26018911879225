import React from 'react';
import BSButton from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

interface ButtonProps {
  children: React.ReactNode;
  loading?: boolean;
  loadingText?: string;
  loaderVariant?: string;
  variant?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
}

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  loadingText,
  loaderVariant = 'primary',
  ...props
}: ButtonProps) => (
  <BSButton {...props}>
    {loading ? (
      <div>
        {loadingText && `${loadingText} `}
        <Spinner
          as="span"
          animation="border"
          variant={loaderVariant}
          size="sm"
          style={{ verticalAlign: 'middle' }}
        />
      </div>
    ) : (
      children
    )}
  </BSButton>
);

export default Button;
