import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Button from '../../common/controls/Button';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import ClientAppUser from '../../../models/ClientAppUser';
import { UserContext } from '../../base/App';
import { apiService } from '../../../modules/services/apiService';
import { plainToClass, serialize } from 'class-transformer';
import EditableTableRow from '../../common/ui/EditableTableRow';
import { isEmailValid } from '../../../modules/utils/validation';

// Styles

const SectionHeader = styled.h2`
  font-size: 35px;
  font-weight: 600;
  margin-left: 50px;
  margin-bottom: 40px;
`;

const UnitContainer = styled.div`
  display: flex;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 20px;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  justify-content: stretch;
  padding-bottom: 50px;
`;

const StyledLink = styled(NavLink)`
  position: relative;
  margin-bottom: 20px;
`;

const BlueTableHeader = styled.div`
  display: flex;
  justify-content: stretch;
  width: 900px;
  background-color: ${(props) => props.theme.secondary};
  border-radius: 5px;
  color: white;
  font-weight: 700;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const TableSection = styled.div`
  width: 25%;
  text-align: center;
  white-space: nowrap;
`;

const BlueTableContent = styled.div`
  width: 900px;
  padding: 10px;
`;

const GrowableButton = styled(Button)`
  flex-grow: 1;
`;

const FormSubhead = styled.p`
  font-size: 18px;
  font-weight: 600;
  max-width: 680px;
`;

const CommitChangesContainer = styled.div`
  display: flex;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 60px;
  width: 600px;
`;

const AddTableRowButton = styled.button`
  font-style: italic;
  font-weight: 600;
  color: ${(props) => props.theme.secondary};
  margin-left: 40px;
  background-color: transparent;
  border-width: 0px;
  text-align: left;
  max-width: 200px;

  &:hover {
    opacity: 0.8;
  }
`;

// Component

const Team: React.FC = () => {
  const { currentUser } = useContext(UserContext);
  const [members, setMembers] = useState<ClientAppUser[]>([]);
  const [oldMembers, setOldMembers] = useState<ClientAppUser[]>([]);
  const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Methods

  const saveChanges = () => {
    if (oldMembers.length !== members.length) {
      let passesValidation = true;
      for (const member of members) {
        if (!isEmailValid(member.email)) {
          passesValidation = false;
          break;
        }
      }
      if (!passesValidation) {
        alert('Please make sure all emails are valid.');
      } else {
        setIsSubmitting(true);
        const serializedMembers = serialize({ users: members });
        apiService('/users', 'put', serializedMembers)
          .then(() => {
            setIsSubmitting(false);
          })
          .catch((e) => {
            console.error(e);
            alert(
              `Unable to save your changes. Please try again. (Error: ${e})`,
            );
            setIsSubmitting(false);
          });
      }
    }
  };

  const revertChanges = () => {
    loadUsers();
  };

  const canUserEdit = (member: ClientAppUser): boolean => {
    const isNotCurrent = member.id !== currentUser?.id;
    const isAdmin = currentUserIsAdmin;
    return (isNotCurrent && isAdmin) ?? false;
  };

  const teamMemberContents = (): JSX.Element[] => {
    const rowContents: JSX.Element[] = [];

    members?.map((member, index) => {
      rowContents.push(
        <EditableTableRow
          rowContents={member.getAsStrings()}
          originalRowContents={member.getAsRowContents()}
          rowItem={member}
          editable={canUserEdit(member)}
          updateItem={(contents, rowItem) => {
            const membersCopy = [...members];
            const updatedItem = new ClientAppUser();
            updatedItem.id = rowItem.id;
            updatedItem.setFromStrings(contents);
            let count = 0;
            for (const iMember of members) {
              if (iMember.id === updatedItem.id) {
                membersCopy[count] = updatedItem;
                break;
              }
              count++;
            }

            setMembers(membersCopy);
          }}
          deleteItem={(memberToRemove) => {
            setMembers(members.filter((item) => item !== memberToRemove));
          }}
          key={member.id}
        />,
      );
    });

    return rowContents;
  };

  const addRowItem = () => {
    const newMember = new ClientAppUser();
    newMember.id = `TEMP-${Math.random() * 100000000}`;
    setMembers(members.concat([newMember]));
  };

  // Networking

  const loadUsers = () => {
    apiService('/users')
      .then((res) => res.json())
      .then((response) => {
        try {
          const users = plainToClass(
            ClientAppUser,
            response.data as ClientAppUser[],
          );

          setCurrentUserIsAdmin(currentUser?.isAdmin() ?? false);

          setMembers(users);
          setOldMembers(users);
        } catch (e) {
          console.error(e);
        }
      });
  };

  React.useEffect(() => {
    loadUsers();
  }, [currentUser]);

  // Render

  return (
    <Container fluid>
      <SectionHeader>Account Settings</SectionHeader>
      <UnitContainer>
        <LeftPanel>
          <StyledLink exact to="/account" activeClassName="unitActive">
            Profile
          </StyledLink>
          <StyledLink exact to="/team" activeClassName="unitActive">
            Team
          </StyledLink>
        </LeftPanel>
        <RightPanel>
          <FormSubhead>
            Below shows who is on your team. Before inviting or making a user
            Admin, please be aware that they will have full access and can make
            any changes to your account. If you have any questions, please{' '}
            <a href="mailto:contactus@cleancio.com">contact us</a>.
          </FormSubhead>
          <BlueTableHeader>
            <TableSection>Name</TableSection>
            <TableSection>Permissions</TableSection>
            <TableSection>Email Address</TableSection>
            <TableSection>Created</TableSection>
          </BlueTableHeader>
          <BlueTableContent>{teamMemberContents()}</BlueTableContent>
          {currentUserIsAdmin && (
            <>
              <AddTableRowButton onClick={addRowItem}>
                + Add team member
              </AddTableRowButton>
              <CommitChangesContainer>
                <GrowableButton
                  variant="primary"
                  style={{ marginRight: '24px' }}
                  disabled={isSubmitting}
                  onClick={saveChanges}
                  loading={isSubmitting}
                >
                  Save changes
                </GrowableButton>
                <GrowableButton
                  variant="outline-primary"
                  disabled={isSubmitting}
                  onClick={revertChanges}
                >
                  Revert changes
                </GrowableButton>
              </CommitChangesContainer>
            </>
          )}
        </RightPanel>
      </UnitContainer>
    </Container>
  );
};

export default Team;
