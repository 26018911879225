import React from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

// Props

interface ToggleButtonProps {
  label: string;
  leftActive: boolean;
  rightActive: boolean;
  leftContent: JSX.Element | string;
  rightContent: JSX.Element | string;
  leftClick?: () => void;
  rightClick?: () => void;
  style?: React.CSSProperties;
}

// Styles

const StyledButtonGroup = styled(ButtonGroup)`
  margin: auto;
`;

const BaseButton = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
`;

const LeftButton = styled(BaseButton)`
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
`;

const RightButton = styled(BaseButton)`
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
`;

// Component

const ToggleButton: React.FC<ToggleButtonProps> = ({
  label,
  leftActive,
  rightActive,
  leftContent,
  rightContent,
  leftClick,
  rightClick,
  style,
}: ToggleButtonProps) => {
  return (
    <StyledButtonGroup aria-label={label} style={style}>
      <LeftButton
        variant={leftActive ? 'secondary' : 'outline-secondary'}
        size="sm"
        active={leftActive}
        onClick={leftClick}
      >
        {leftContent}
      </LeftButton>
      <RightButton
        variant={rightActive ? 'secondary' : 'outline-secondary'}
        size="sm"
        active={rightActive}
        onClick={rightClick}
      >
        {rightContent}
      </RightButton>
    </StyledButtonGroup>
  );
};

export default ToggleButton;
