import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import './index.scss';
import AuthAppState from './components/base/auth/AuthAppState';
import * as serviceWorker from './modules/utils/serviceWorker';
import Unsubscribe from './components/sections/Unsubscribe';

const isDevelopment = process.env.NODE_ENV === 'development';

Sentry.init({
  dsn:
    'https://32615482549747dc8f1e31b5798d54c7@o345028.ingest.sentry.io/5373744',
  enabled: !isDevelopment,
  debug: isDevelopment,
  environment: process.env.APP_TARGET,
});

export const theme = {
  brand: '#3ab4cb',
  light: `#ffffff`,
  nav: `#ffffff`,
  text: `#000000`,
  primary: '#fb7e70',
  secondary: '#6f85ff',
  secondarylight: '#B9C4FF',
  focusSecondary: '#EBEEFF',
  success: '#27d9a1',
  danger: '#ff6748',
  outline: `#7F7F7F`,
  border: `#EAEAEA`,
  shadow: `#f1f1f1`,
  emptyState: `#969696`,
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/unsubscribe">
          <Unsubscribe />
        </Route>
        <Route path="*">
          <ThemeProvider theme={theme}>
            <AuthAppState />
          </ThemeProvider>
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
