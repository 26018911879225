import 'reflect-metadata';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
// import OnboardingBar from './widgets/onboarding/OnboardingBar';
import Status from '../../../models/Status';
import Unit from '../../../models/Unit';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import Calendar from './widgets/calendar/Calendar';
import UpcomingServices from './widgets/services/UpcomingServices';
import ConnectAccounts from './widgets/onboarding/ConnectAccounts';
import SelectAccounts from './widgets/onboarding/modals/SelectAccounts';
import AccountInstructions from './widgets/onboarding/modals/AccountInstructions';
import SetupSuccess from './widgets/onboarding/modals/SetupSuccess';
import { ISelectable } from '../../../interfaces/interfaces';
import UnitConnectionContainer from '../../../models/UnitConnectionContainer';
import media from 'styled-media-query';
import { apiService } from '../../../modules/services/apiService';
import { UserContext, GlobalContext } from '../../base/App';
import { Redirect } from 'react-router-dom';
import ScheduleContainer from '../../../models/ScheduleContainer';

// Styles

const SectionHeader = styled.h2`
  font-size: 35px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const BottomSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  justify-items: stretch;
  padding-bottom: 40px;
  ${media.lessThan('large')`
    grid-template-columns: 1fr;
  `}
`;

// Component

const Home: React.FC = () => {
  const { receivedNotificationCount } = useContext(GlobalContext);
  const { currentUser } = useContext(UserContext);
  const [status, setStatus] = useState<Status>();
  const [schedule, setSchedule] = useState<ScheduleContainer>();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [redirectAdmin, setRedirectAdmin] = useState<boolean>(false);
  const [redirectUnit, setRedirectUnit] = useState<Unit>();
  const [onboardProgress, setOnboardProgress] = useState<number>(0);
  const [unitConnection, setUnitConnection] = useState<
    UnitConnectionContainer
  >();
  const [selectedAccount, setSelectedAccount] = useState<ISelectable>();
  const [loadingSchedule, setLoadingSchedule] = useState<boolean>(false);

  // Networking

  const loadStatus = async (version = '1') => {
    apiService(`/status/${version}`)
      .then((res) => res.json())
      .then((response) => {
        try {
          const defaultStatus = new Status();
          const statusMapped = plainToClassFromExist(
            defaultStatus,
            response.data,
          );
          setStatus(statusMapped);
        } catch (e) {
          console.error(e);
        }
      });
  };

  const loadSchedule = async () => {
    if (loadingSchedule) return;
    setLoadingSchedule(true);
    apiService('/weekly')
      .then((res) => res.json())
      .then((response) => {
        try {
          const scheduleMapped = plainToClass(ScheduleContainer, response.data);
          setSchedule(scheduleMapped);
        } catch (e) {
          console.error(e);
        } finally {
          setLoadingSchedule(false);
        }
      });
  };

  const selectAccount = (account: ISelectable) => {
    setSelectedAccount(account);
    advanceOnboarding();
  };

  const advanceOnboarding = () => {
    const progress = onboardProgress + 1;
    setOnboardProgress(progress);
  };

  const completeOnboarding = (unitConnection: UnitConnectionContainer) => {
    const progress = onboardProgress + 1;
    setUnitConnection(unitConnection);
    setOnboardProgress(progress);
  };

  const onboardingContent = (progress: number) => {
    switch (progress) {
      case 0:
        return <SelectAccounts goNext={selectAccount} />;
      case 1:
        return (
          <AccountInstructions
            account={selectedAccount}
            goNext={completeOnboarding}
          />
        );
      case 2:
        if (unitConnection === undefined) {
          console.error('Missing unit.');
          return;
        }
        return (
          <SetupSuccess
            unitConnection={unitConnection}
            goNext={advanceOnboarding}
          />
        );
      case 3:
        setModalShow(false);
        setOnboardProgress(4);
        loadStatus('2');
        break;
      default:
        // Done
        break;
    }
  };

  // Effect

  React.useEffect(() => {
    if (currentUser?.superAdmin) {
      setRedirectAdmin(true);
    } else {
      loadStatus('1');
      loadSchedule();
    }
  }, [currentUser]);

  React.useEffect(() => {
    loadSchedule();
  }, [receivedNotificationCount]);

  // Render

  return (
    <>
      {redirectUnit && (
        <Redirect push to={`/unit/${redirectUnit.id}/settings`} />
      )}
      {redirectAdmin && <Redirect push to={'/cleancio/admin'} />}
      <Container fluid>
        {status?.onboardStatus?.inProgress === true && (
          <>
            <ConnectAccounts
              show={modalShow}
              content={onboardingContent(onboardProgress)}
              onHide={() => setModalShow(false)}
            />
            {/* <OnboardingBar
              onboardStatus={status.onboardStatus}
              onConnectAccounts={() => {
                setModalShow(true);
              }}
              onFinishUnits={() => {
                const incompleteUnits =
                  schedule?.scheduleItems?.filter((scheduleItem) => {
                    return scheduleItem?.unit?.calendarLink === undefined;
                  }) ?? [];
                // TODO: [PROD] Remove schedule sync
                apiService('/syncSchedule');
                // console.log('Schedule items: ', schedule?.scheduleItems);
                // console.log('Incomplete units: ', incompleteUnits);
                if (incompleteUnits?.length > 0) {
                  setRedirectUnit(incompleteUnits[0].unit);
                }
              }}
            /> */}
          </>
        )}
        <SectionHeader>Schedule</SectionHeader>
        <Calendar schedule={schedule} />
        <BottomSection>
          <UpcomingServices schedule={schedule} />
        </BottomSection>
      </Container>
    </>
  );
};

export default Home;
