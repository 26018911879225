export function isUrlValid(userInput: string): boolean {
  const regexQuery =
    '^(http|https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
  const url = new RegExp(regexQuery, 'i');
  return url.test(userInput);
}

export function isBlank(str: string): boolean {
  return !str || /^\s*$/.test(str);
}

export function isEmailValid(email: string): boolean {
  const reg = /^[^@\s]+@[^@\s\.]+\.[^@\.\s]+$/;
  if (!reg.test(email)) return false;
  return true;
}
