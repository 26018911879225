import 'reflect-metadata';
import moment from 'moment';
import Unit from './Unit';
import { theme } from '../index';
import { Expose, Transform, Type } from 'class-transformer';
import Comment from './Comment';
import Reservation from './Reservation';

export enum CleaningStatus {
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
}

export enum AssignmentType {
  REGULAR = 'regular',
  HOT_TURNOVER = 'hot_turnover',
  DEEP_CLEANING = 'deep_cleaning',
  HOT_DEEP_CLEANING = 'hot_deep_cleaning',
}

export enum AddOnType {
  NONE = 'none',
  LINENS = 'linens',
  LCO = 'lco',
  ECI = 'eci',
}

const dateTransformer = (dateObj: any): Date => {
  const newDate = moment(dateObj).toDate();
  return newDate;
};

export default class Cleaning {
  @Expose() id?: string;
  @Expose() status?: CleaningStatus;
  @Expose() type?: AssignmentType;
  @Expose() commentCount?: number;
  @Type(() => Reservation)
  @Expose()
  reservation?: Reservation;
  @Type(() => Comment)
  @Expose()
  lastComment?: Comment;
  @Type(() => Date)
  @Expose()
  clockInTime?: Date;
  @Type(() => Date)
  @Expose()
  clockOutTime?: Date;
  @Type(() => Date)
  @Expose()
  estimatedCompletion?: Date;
  @Transform((dateObj) => dateTransformer(dateObj))
  @Expose()
  date?: Date;
  @Type(() => Unit)
  @Expose()
  unit?: Unit;
  @Expose() addOns: AddOnType[] = [AddOnType.NONE];
  @Expose()
  totalReportsBroken = 0;
  @Expose()
  totalReportsRestock = 0;
  @Expose()
  totalReportsGuests = 0;
  @Expose()
  totalReportsOther = 0;
  @Expose()
  totalCleaningPhotos = 0;

  eligibleToEditServices(): boolean {
    if (this.isComplete() || this.isInProgress()) return false;
    const cleaningDate = moment(this.date);
    if (cleaningDate.isSameOrBefore(moment(), 'day')) return false;
    return true;
  }

  isComplete(): boolean {
    return this.status === CleaningStatus.COMPLETE;
  }

  isInProgress(): boolean {
    return this.status === CleaningStatus.IN_PROGRESS;
  }

  duration(): string {
    return moment(this.clockInTime).from(this.clockOutTime, true);
  }

  progress(): number {
    const now = moment();
    const duration = moment.duration(this.unit?.averageTimeToClean);
    const startDate = moment(this.clockInTime);
    const endDate = moment(this.clockInTime).add(duration, 'hours');

    const percentage_complete =
      (now.unix() - startDate.unix()) / (endDate.unix() - startDate.unix());
    return percentage_complete;
  }

  cleaningServices(): string[] {
    const availableServices = ['regular', 'deep_cleaning'];
    const serviceNames = availableServices.map((service) => {
      return this.cleaningNameWith(service);
    });
    return serviceNames;
  }

  cleaningTime(): string {
    if (this.status === CleaningStatus.IN_PROGRESS) {
      return 'In progress';
    }
    if (this.status === CleaningStatus.COMPLETE) {
      return 'Complete';
    }
    const formattedDate = moment(this.date).format('MMM Do h:mm a');
    return formattedDate;
  }

  cleaningTimeShort(): string {
    if (this.status === CleaningStatus.IN_PROGRESS) {
      return 'In progress';
    }
    if (this.status === CleaningStatus.COMPLETE) {
      return 'Complete';
    }
    const formattedDate = moment(this.date).format('M/DD h:mm a');
    return formattedDate;
  }

  cleaningName(): string {
    return this.cleaningNameWith(this.type);
  }

  cleaningNameWith(type?: string): string {
    switch (type) {
      case 'hot_turnover':
      case 'hot_deep_cleaning':
        return 'Cleaning with Same Day Check-In';
      case 'deep_cleaning':
        return 'Deep Cleaning';
      default:
        return 'Regular Cleaning';
    }
  }

  isHot(): boolean {
    return this.type === 'hot_turnover' || this.type === 'hot_deep_cleaning';
  }

  color(): string {
    switch (this.type) {
      case 'hot_turnover':
        return theme.primary;
      default:
        return theme.secondary;
    }
  }

  formattedDate(): string {
    return moment(this.date).format('MMMM Do h:mm a');
  }

  formattedStartTime(): string {
    const timeFormat = 'h:mm a';
    return moment(this.date).format(timeFormat);
  }

  formattedEndTime(): string {
    const timeFormat = 'h:mm a';
    if (this.isComplete() && this.clockOutTime !== undefined) {
      return moment(this.clockOutTime).format(timeFormat);
    }
    return moment(this.estimatedCompletion).format(timeFormat);
  }
}
