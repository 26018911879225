import React from 'react';
import styled from 'styled-components';
import Badge from './Badge';

// Props
interface FormHeaderProps {
  alertMessage?: string;
  children: JSX.Element | string;
}

// Styles

const FormContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 28px;
`;

const FormHead = styled.div`
  font-size: 35px;
`;

const AlertText = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.primary};
  margin-left: 20px;
  max-width: 450px;
`;

// Component

const FormHeader: React.FC<FormHeaderProps> = ({
  alertMessage,
  children,
}: FormHeaderProps) => {
  return (
    <FormContainer>
      <FormHead>{children}</FormHead>
      {(alertMessage ?? '').length > 0 && (
        <Badge
          badgeCount="!"
          style={{
            position: 'relative',
            right: '0px',
            top: '0px',
            marginLeft: '30px',
          }}
        />
      )}
      {(alertMessage ?? '').length > 0 && <AlertText>{alertMessage}</AlertText>}
    </FormContainer>
  );
};

export default FormHeader;
