import authData from './authParams';

console.log(process.env.REACT_APP_API_BASE_URL);

export const apiService = async (
  url: string,
  method = 'get',
  body = {},
): Promise<Response> => {
  const auth = await authData();
  if (method === 'get') {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
      headers: {
        accessToken: auth.accessToken,
      },
      method: method,
    });
  }
  const fetchRequest = fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
    headers: {
      accessToken: auth.accessToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: method,
    body: JSON.stringify(body),
  });
  return fetchRequest;
};

export const apiServiceMultipart = async (
  url: string,
  body: FormData,
): Promise<Response> => {
  const auth = await authData();
  const fetchRequest = fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
    headers: {
      accessToken: auth.accessToken,
      Accept: 'application/json',
    },
    method: 'post',
    body: body,
  });
  return fetchRequest;
};

export const apiServiceForm = async (
  url: string,
  body: FormData,
): Promise<Response> => {
  const auth = await authData();
  const fetchRequest = fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
    headers: {
      accessToken: auth.accessToken,
      Accept: 'application/json',
    },
    method: 'post',
    body: body,
  });
  return fetchRequest;
};
