import 'reflect-metadata';
import Cleaning from './Cleaning';
import { Expose, Type } from 'class-transformer';
import moment, { Moment } from 'moment';
import Unit from './Unit';

export default class ScheduleItem {
  @Type(() => Unit)
  @Expose()
  unit?: Unit;
  @Type(() => Cleaning)
  @Expose()
  cleanings?: Cleaning[];

  cleaningFor(date: Moment): Cleaning | undefined {
    let foundCleaning = undefined;
    if (this.cleanings !== undefined) {
      for (const cleaning of this.cleanings) {
        if (moment(cleaning.date).isSame(date, 'day')) {
          foundCleaning = cleaning;
          break;
        }
      }
    }
    return foundCleaning;
  }

  futureCleanings(): Cleaning[] {
    const today = moment();
    const filteredCleanings = this.cleanings?.filter((cleaning) => {
      const cleaningDate = moment(cleaning.date);
      return cleaningDate.isSameOrAfter(today, 'day');
    });

    return filteredCleanings ?? [];
  }
}
