import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import styled from 'styled-components';
import cleancioLogo from '../../../images/logo-cleancio.png';
import { ReactComponent as NotificationIconSvg } from '../../../images/icon-notification.svg';
import cleancioLogoRetina from '../../../images/logo-cleancio@2x.png';
import { isMobile } from 'react-device-detect';

import React, { useContext, useState } from 'react';
import { plainToClass } from 'class-transformer';
import { GlobalContext, UserContext } from '../../base/App';
import { NavLink } from 'react-router-dom';
import Activity from '../../sections/home/widgets/activity/Activity';
import ActivityFeed from '../../../models/ActivityFeed';
import { apiService } from '../../../modules/services/apiService';

const StyledLogo = styled.img`
  margin-left: ${isMobile ? 0 : 15}px;
  margin-top: ${isMobile ? -16 : -16}px;
  height: ${isMobile ? 25 : 32}px;
`;

const StyledNavbar = styled(Navbar)`
  vertical-align: middle;
  box-shadow: 0 8px 19px 2px ${(props) => props.theme.shadow};
  padding-top: ${isMobile ? 20 : 20}px;
  padding-bottom: ${isMobile ? 20 : 20}px;
  margin-bottom: 40px;
  background-color: ${(props) => props.theme.nav};
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 100;
`;

const NotificationIconWrapper = styled(Nav.Item)`
  display: grid;
  place-items: center;
  position: relative;
  padding: 0 20px;
  &[data-has-unread='true'] {
    &:after {
      content: '';
      position: absolute;
      top: 8px;
      right: calc(50% + 3px);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fb7e70;
    }
  }
`;

const ActivityPopupBackground = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 9;
`;

interface NavProps {
  blank?: boolean;
}

const TopNav: React.FC<NavProps> = ({ blank = false }: NavProps) => {
  const {
    receivedNotification,
    setReceivedNotification,
    setReceivedNotificationCount,
  } = useContext(GlobalContext);
  const { currentUser } = useContext(UserContext);
  const [activityFeed, setActivityFeed] = useState<ActivityFeed>();
  const [activityFeedVisibility, setActivityFeedVisibility] = useState<boolean>(
    false,
  );

  React.useEffect(() => {
    if (setReceivedNotification === undefined) return;
    if (!receivedNotification) return;

    loadActivity();

    setReceivedNotificationCount &&
      setReceivedNotificationCount((prevCount) => prevCount + 1);
  }, [receivedNotification]);

  React.useEffect(() => {
    if (!currentUser?.superAdmin) {
      loadActivity();
    }
  }, [currentUser]);

  const loadActivity = async () => {
    apiService('/activity')
      .then((res) => res.json())
      .then((response) => {
        try {
          const activityFeedMapped = plainToClass(ActivityFeed, response.data);

          setActivityFeed(activityFeedMapped);
        } catch (e) {
          console.error(e);
        }
      });
  };

  // Render

  return (
    <StyledNavbar>
      <Navbar.Brand href="/">
        <StyledLogo
          src={cleancioLogo}
          srcSet={`${cleancioLogoRetina} 2x`}
          alt="Cleancio Logo"
        />
      </Navbar.Brand>
      {!blank && !currentUser?.superAdmin && (
        <Nav
          className={isMobile ? '' : 'justify-content-end'}
          style={{
            width: '100%',
          }}
          activeKey={window.location.pathname}
          justify={isMobile}
        >
          <NotificationIconWrapper
            data-has-unread={activityFeed?.hasUnreadActivity}
          >
            <NotificationIconSvg
              style={{ cursor: 'pointer', height: '24px' }}
              onClick={() => {
                setActivityFeedVisibility(!activityFeedVisibility);
              }}
            />
            <ActivityPopupBackground
              onClick={() => {
                setActivityFeedVisibility(false);
              }}
              visible={activityFeedVisibility}
            />
            <Activity
              feed={activityFeed}
              visible={activityFeedVisibility}
              loadActivity={loadActivity}
            />
          </NotificationIconWrapper>
          <Nav.Item>
            <NavLink className="nav-link" exact to="/">
              Schedule
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink className="nav-link" exact to="/account">
              Account
            </NavLink>
          </Nav.Item>
        </Nav>
      )}
      {currentUser?.superAdmin && (
        <Nav
          className={isMobile ? '' : 'justify-content-end'}
          style={{
            width: '100%',
          }}
          activeKey={window.location.pathname}
          justify={isMobile}
        >
          <Nav.Item>
            <NavLink className="nav-link" exact to="/cleancio/admin">
              Admin
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink className="nav-link" exact to="/account">
              Account
            </NavLink>
          </Nav.Item>
        </Nav>
      )}
    </StyledNavbar>
  );
};

export default TopNav;
