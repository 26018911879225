import 'reflect-metadata';
import Unit from './Unit';
import { Expose, Type } from 'class-transformer';
import moment from 'moment';
import Cleaning from './Cleaning';

export enum ActorType {
  CLEANING_COMPLETED = 'cleaning_completed',
  CLEANING_INPROGRESS = 'cleaning_inprogress',
  CLEANING_SCHEDULED = 'cleaning_scheduled',
  NEW_REPORT_ADDED = 'new_report_added',
  NEW_COMMENT_ADDED = 'new_comment_added',
  OTHER = 'other',
}

export enum ActivityLevel {
  NEW = 'new',
  INFO = 'info',
  WARN = 'warn',
  SUCCESS = 'success',
}

export default class ActivityItem {
  @Expose() id?: string;
  @Expose() level?: ActivityLevel;
  @Expose() message?: string;
  @Expose() actorType?: ActorType;
  @Expose() isRead?: boolean;
  @Type(() => Date)
  @Expose()
  createdAt?: Date;
  @Type(() => Unit)
  @Expose()
  unit?: Unit;
  @Type(() => Cleaning)
  @Expose()
  cleaning?: Cleaning;

  activityTime(): string {
    const formattedDate = moment(this.createdAt).format('MMM Do h:mm a');
    return formattedDate;
  }

  activityTimeShort(): string {
    const formattedDate = moment(this.createdAt).format('M/DD h:mm a');
    return formattedDate;
  }
}
