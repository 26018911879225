import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Cleaning, { AddOnType } from '../../../../models/Cleaning';
import { theme } from '../../../../index';
import Unit from '../../../../models/Unit';
import CleaningType from '../../../cleaning/CleaningType';
import AuthImage from '../../../common/images/AuthImage';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import CommentsModule from './CommentsModule';
import { ReactComponent as BedSvg } from '../../../../images/icon-bedroom.svg';
import { ReactComponent as GreenCheck } from '../../../../images/icon-check-complete.svg';
import ReportItemButton from '../../../cleaning/ReportItemButton';
import { apiService } from '../../../../modules/services/apiService';
import { serialize } from 'class-transformer';

// Props

export interface ScheduleCardProps {
  unit: Unit;
  cleaning: Cleaning;
  showReportModal: (type: string) => void;
}

// Styles

const CardContainer = styled.div`
  position: relative;
  width: 630px;
  margin-bottom: 50px;
`;

const CleaningLine = styled.div`
  position: absolute;
  width: 4px;
  height: 100%;
  border-radius: 2px;
`;

const InnerCardContainer = styled.div`
  box-shadow: 0 8px 19px 2px ${(props) => props.theme.shadow};
  border-radius: 6px;
  margin-top: 4px;
  margin-left: 30px;
  min-height: 500px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
`;

const CleaningIconAndName = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

const CleaningName = styled.div`
  margin-left: 4px;
  font-size: 16px;
  font-weight: 600;
`;

const CleaningDateTime = styled.div`
  color: ${(props) => props.theme.brand};
  font-size: 18px;
  font-weight: 700;
`;

const CleaningStatsRow = styled.div`
  display: flex;
`;

const EstimatedCompletion = styled.div`
  color: #868686;
  font-size: 14px;
`;

const RightText = styled.div`
  margin-left: 15px;
  font-size: 14px;
  color: #868686;
`;

const ServiceDetails = styled.div`
  display: flex;
  align-items: center;
  justify-items: stretch;
  margin-top: 32px;
`;

const TypeInfo = styled.div`
  font-weight: 600;
  margin-right: 40px;
`;

const StyledDropdown = styled(DropdownButton)`
  flex-grow: 1;
`;

const StyledHR = styled.div`
  margin-top: 40px;
  margin-bottom: 10px;
`;

const ServiceAddons = styled.div`
  margin-bottom: 40px;
`;

const AddonTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const CommentsSection = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const SummaryItem = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  margin-left: 12px;
  width: 130px;
`;

const IconContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
  width: 22px;
  height: 22px;
`;

const LeftText = styled.div`
  margin-right: 20px;
  color: #868686;
  font-size: 14px;
`;

const CleaningStatusCard = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  position: relative;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
`;

const CleaningBar = styled.div`
  margin-top: 4px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.success};
  width: 344px;
  height: 19px;
`;

const CleaningBarProgress = styled(CleaningBar)`
  opacity: 50%;
  background-color: ${(props) => props.theme.secondary};
`;

const BarContainer = styled.div`
  position: relative;
`;

const CleaningBarTotal = styled(CleaningBar)`
  position: absolute;
  background-color: ${(props) => props.theme.secondary};
`;

const Checkmark = styled.div`
  position: absolute;
  bottom: -5px;
  left: -38px;
`;

const ReportHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const ReportRow = styled.div`
  display: flex;
  justify-items: stretch;
`;

// Component

const ScheduleCard: React.FC<ScheduleCardProps> = ({
  unit,
  cleaning,
  showReportModal,
}: ScheduleCardProps) => {
  const [currentService, setCurrentService] = useState<number>(0);

  // Methods

  const servicesDropdown = (): JSX.Element => {
    if (!cleaning.eligibleToEditServices()) {
      return <></>;
    }
    return (
      <ServiceDetails>
        <TypeInfo>Service Type</TypeInfo>
        <StyledDropdown
          title={
            cleaning.cleaningServices()[currentService] ??
            cleaning.cleaningName()
          }
          variant={'light'}
        >
          {cleaning.cleaningServices().map((service, index) => {
            return (
              <Dropdown.Item
                key={`service-${index}`}
                active={currentService === index}
                onClick={() => {
                  setCurrentService(index);
                  updateService(index);
                }}
              >
                {service}
              </Dropdown.Item>
            );
          })}
        </StyledDropdown>
      </ServiceDetails>
    );
  };

  const updateService = (index: number): void => {
    const selectedService = serialize({
      requestedService: cleaning.cleaningServices()[index],
    });
    apiService(`/cleaning/${cleaning.id}`, 'put', selectedService)
      .then(() => {
        // Silently updates
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addons = (): JSX.Element => {
    if (
      cleaning.addOns?.length > 0 &&
      cleaning.addOns[0] === AddOnType.LINENS
    ) {
      return (
        <>
          <AddonTitle>Addons</AddonTitle>
          <SummaryItem>
            <IconContainer>
              <BedSvg style={{ maxWidth: '15px', maxHeight: '15px' }} />
            </IconContainer>
            Linen Rental
          </SummaryItem>
        </>
      );
    } else {
      return <></>;
    }
  };

  const cleaningLeftSideText = (): JSX.Element => {
    if (cleaning.isComplete()) {
      return (
        <>
          <LeftText>
            Cleaning Duration: <b>{cleaning.duration()}</b>
          </LeftText>
        </>
      );
    }
    return (
      <EstimatedCompletion>
        Estimated service completion time: <b>{cleaning.formattedEndTime()}</b>
      </EstimatedCompletion>
    );
  };

  const cleaningRightSideText = (): JSX.Element => {
    if (cleaning.isComplete()) {
      return (
        <RightText>
          Completed at: <b>{cleaning.formattedEndTime()}</b>
        </RightText>
      );
    }
    if (cleaning.isHot()) {
      return (
        <RightText>
          Guest check-in time: <b>{unit.formattedCheckInTime()}</b>
        </RightText>
      );
    }
    return <></>;
  };

  const centerContent = (): JSX.Element => {
    if (cleaning.isComplete()) {
      return (
        <>
          <CleaningStatusCard>
            Cleaning complete
            <Checkmark>
              <GreenCheck />
            </Checkmark>
            <CleaningBar />
          </CleaningStatusCard>
          {reportContent()}
        </>
      );
    }

    if (cleaning.isInProgress()) {
      return (
        <>
          <CleaningStatusCard>
            Cleaning in progress
            <BarContainer>
              <CleaningBarTotal
                style={{
                  width: `${Math.min(344 * cleaning.progress(), 344)}px`,
                }}
              />
              <CleaningBarProgress />
            </BarContainer>
          </CleaningStatusCard>
          {reportContent()}
        </>
      );
    }
    return (
      <AuthImage
        style={{ width: '100%', height: '200px' }}
        url={unit.mainEntrancePhotoUrls?.[0]}
      />
    );
  };

  const reportContent = (): JSX.Element => {
    return (
      <>
        <ReportHeader>Reports</ReportHeader>
        <ReportRow>
          <ReportItemButton
            name="Broken"
            type="broken"
            onPress={showReports}
            badgeCount={cleaning.totalReportsBroken}
          />
          <ReportItemButton
            name="Guest in unit"
            type="guests"
            onPress={showReports}
            badgeCount={cleaning.totalReportsGuests}
          />
        </ReportRow>
        <ReportRow>
          <ReportItemButton
            name="Restock"
            type="restock"
            onPress={showReports}
            badgeCount={cleaning.totalReportsRestock}
          />
          <ReportItemButton
            name="Other"
            type="other"
            onPress={showReports}
            badgeCount={cleaning.totalReportsOther}
          />
        </ReportRow>
        <ReportRow>
          <ReportItemButton
            name="View cleaning photos"
            type="cleaning"
            onPress={showReports}
            badgeCount={cleaning.totalCleaningPhotos}
          />
        </ReportRow>
      </>
    );
  };

  const showReports = (type: string): void => {
    showReportModal(type);
  };

  // Render

  return (
    <CardContainer id={cleaning.id}>
      <CleaningLine
        style={{ backgroundColor: cleaning?.color() ?? theme.secondary }}
      />
      <CleaningIconAndName>
        <CleaningType cleaning={cleaning} />
        <CleaningName style={{ color: cleaning.color() }}>
          {cleaning.cleaningName()}
        </CleaningName>
      </CleaningIconAndName>
      <InnerCardContainer>
        <CleaningDateTime>{cleaning.formattedDate()}</CleaningDateTime>
        <CleaningStatsRow>
          {cleaningLeftSideText()}
          {cleaningRightSideText()}
        </CleaningStatsRow>
        {centerContent()}
        <StyledHR>
          <hr />
        </StyledHR>
        <ServiceAddons>{addons()}</ServiceAddons>
        <CommentsSection>
          <CommentsModule cleaning={cleaning} />
        </CommentsSection>
      </InnerCardContainer>
    </CardContainer>
  );
};

export default ScheduleCard;
