import React, { useState } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { diff } from 'deep-object-diff';
import media from 'styled-media-query';
import { up } from 'styled-breakpoints';
import Container from 'react-bootstrap/Container';
import { NavLink, Prompt, useParams } from 'react-router-dom';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Button from '../../common/controls/Button';
import Modal from 'react-bootstrap/Modal';
import ImageRow from '../../common/controls/ImageRow';
import EditableTableRow from '../../common/ui/EditableTableRow';
import { apiService } from '../../../modules/services/apiService';
import Unit, { LinenRentalStatus } from '../../../models/Unit';
import { plainToClass } from 'class-transformer';
import DoorCode from '../../../models/DoorCode';
import FormHeader from '../../common/ui/FormHeader';
import SettingsFormField from '../../common/ui/SettingsFormField';
import VRServiceContainer from '../../../models/VRServiceContainer';
import { isBlank, isUrlValid } from '../../../modules/utils/validation';
import vrServiceJson from '../../../modules/services/vrServices.json';
import Spinner from '../../common/ui/Spinner';
import { SectionHeader, UnitContainer, LeftPanel } from './common';

function formHasChanges(current: any, next: any): boolean {
  const nextDiff = diff(current as object, next as object);
  next = _.omitBy(nextDiff, _.isNil);
  return Object.keys(next).length > 0;
}

function htmlDecode(input: string) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent ?? '';
}

// Props

interface RouteParams {
  id: string;
  cleaning: string;
}

// Styles

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  justify-content: stretch;
  padding-bottom: 50px;
`;

const StyledLink = styled(NavLink)`
  position: relative;
  margin-bottom: 20px;
`;

const FormSubhead = styled.p`
  font-size: 18px;
  font-weight: 600;
  max-width: 680px;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const FormName = styled.div`
  font-weight: 600;
  min-width: 100px;
  margin-right: 30px;
`;

const FormNameGrow = styled(FormName)`
  width: 100px;
`;

const GroupedForm = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  max-width: 550px;
`;

const FormItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const FormItemSmall = styled(FormItem)`
  width: 260px;
`;

const FormItemLeft = styled(FormItem)`
  align-items: flex-start;
`;

const StaticField = styled.div`
  font-size: 16px;
`;

const BlueTableHeader = styled.div`
  display: flex;
  justify-content: stretch;
  width: 900px;
  background-color: ${(props) => props.theme.secondary};
  border-radius: 5px;
  color: white;
  font-weight: 700;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const TableSection = styled.div`
  width: 25%;
  text-align: center;
  white-space: nowrap;
`;

const BlueTableContent = styled.div`
  max-width: 900px;
  padding: 10px;
`;

const AddTableRowButton = styled.button`
  font-style: italic;
  font-weight: 600;
  color: ${(props) => props.theme.secondary};
  margin-left: 40px;
  background-color: transparent;
  border-width: 0px;
  width: 50px;
  margin-bottom: 30px;

  &:hover {
    opacity: 0.8;
  }
  ${up('md')} {
    width: 100px;
  }
`;

const CommitChangesContainer = styled.div`
  display: flex;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 60px;
  width: 600px;
`;

const GrowableButton = styled(Button)`
  flex-grow: 1;
`;

const Step = styled.h2`
  font-size: 20px;
  font-weight: 600;
  ${media.lessThan('medium')`
      font-size: 14px;
      min-width: 50px;
    `}
`;

const Content = styled.div`
  font-size: 16px;
  margin-left: 65px;

  ${media.lessThan('medium')`
      margin-left: 20px;
    `}
`;

const InfoText = styled.p`
  font-weight: bold;
  font-style: italic;
  border-bottom: solid 1px #eee;
  font-size: 12px;
  padding-bottom: 10px;
`;

// Component

const UnitSettings: React.FC = () => {
  const { id } = useParams<RouteParams>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [changesMade, setChangesMade] = useState<boolean>(false);

  const [originalUnit, setOriginalUnit] = useState<Unit>();
  const [unit, setUnit] = useState<Unit>();
  const [previousUnit, setPreviousUnit] = useState<Unit>();

  const [calendarLink, setCalendarLink] = useState<string>('');
  const [linenRental, setLinenRental] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');
  const [apt, setApt] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [addressState, setAddressState] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [bedrooms, setBedrooms] = useState<number>(0);
  const [bathrooms, setBathrooms] = useState<number>(0);
  const [wifiName, setWifiName] = useState<string>('');
  const [wifiPass, setWifiPass] = useState<string>('');
  const [garbageInstructions, setGarbageInstructions] = useState<string>('');
  const [garbageLocationPhotos, setGarbageLocationPhotos] = useState<string[]>(
    [],
  );
  const [mainEntrancePhotos, setMainEntrancePhotos] = useState<string[]>([]);
  const [bedroomPhotos, setBedroomPhotos] = useState<string[]>([]);
  const [livingRoomPhotos, setLivingRoomPhotos] = useState<string[]>([]);
  const [kitchenPhotos, setKitchenPhotos] = useState<string[]>([]);
  const [bathroomPhotos, setBathroomPhotos] = useState<string[]>([]);
  const [lockboxLocationPhotos, setLockboxLocationPhotos] = useState<string[]>(
    [],
  );
  const [buildingKeypadPhotos, setBuildingKeypadPhotos] = useState<string[]>(
    [],
  );
  const [doorCodes, setDoorCodes] = useState<DoorCode[]>([]);
  const [confirmRevert, setConfirmRevert] = useState<boolean>(false);
  const [viewInstructions, setViewInstructions] = useState<boolean>(false);
  const [doorCodesField, setDoorCodesField] = useState<string>('');

  // Methods
  const doorCodeContents = (): JSX.Element[] => {
    const contents: JSX.Element[] = [];
    doorCodes?.map((doorCode) => {
      contents.push(
        <EditableTableRow
          rowContents={doorCode.getAsStrings()}
          originalRowContents={doorCode.getAsRowContents()}
          rowItem={doorCode}
          updateItem={(contents, rowItem) => {
            const doorCodesCopy = [...doorCodes];
            const updatedItem = new DoorCode();
            updatedItem.id = rowItem.id;
            updatedItem.setFromStrings(contents);
            let count = 0;
            for (const iDoorCode of doorCodes) {
              if (iDoorCode.id === updatedItem.id) {
                doorCodesCopy[count] = updatedItem;
                break;
              }
              count++;
            }

            setDoorCodes(doorCodesCopy);
          }}
          deleteItem={(doorCodeToRemove) => {
            setDoorCodes(doorCodes.filter((item) => item !== doorCodeToRemove));
          }}
          key={doorCode.id}
        />,
      );
    });

    return contents;
  };

  const addRowItem = () => {
    setChangesMade(true);
    const newDoorCode = new DoorCode();
    newDoorCode.id = (Math.random() * 10000).toString();
    setDoorCodes(doorCodes.concat([newDoorCode]));
  };

  const importantInfoMissing = (): boolean => {
    return isBlank(calendarLink);
  };

  const unitInfoMissing = (): boolean => {
    return (
      isBlank(address) ||
      isBlank(city) ||
      isBlank(zip) ||
      bedrooms === 0 ||
      bathrooms === 0 ||
      isBlank(wifiName) ||
      isBlank(wifiPass) ||
      isBlank(garbageInstructions)
    );
  };

  const photosMissing = (): boolean => {
    return (
      mainEntrancePhotos?.length === 0 ||
      bedroomPhotos?.length === 0 ||
      livingRoomPhotos?.length === 0 ||
      bathroomPhotos?.length === 0
    );
  };

  const accessDetailsMissing = (): boolean => {
    return (
      doorCodes?.length === 0 ||
      lockboxLocationPhotos?.length === 0 ||
      buildingKeypadPhotos?.length === 0
    );
  };

  const unitInformationMissing = (): string => {
    if (unitInfoMissing())
      return 'Please enter details so our team can be efficient and report issues to you in real-time';
    return '';
  };

  const unitPhotosMissing = (): string => {
    if (photosMissing())
      return 'Please enter details so we can clean your unit as you like it (Optional)';
    return '';
  };

  const doorCodesAndKeysMissing = (): string => {
    if (accessDetailsMissing())
      return 'Please enter details so we can access your property & unit';
    return '';
  };

  const instructionsForAccount = (): JSX.Element[] => {
    const renderedList: JSX.Element[] = [];
    if (unit?.calendarProvider) {
      const mappedServices = plainToClass(VRServiceContainer, vrServiceJson);
      const vrService = mappedServices.searchFor(unit?.calendarProvider);
      vrService?.instructions?.map((step, index) => {
        renderedList.push(
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            <Step>{`Step ${index + 1}`}</Step>
            <Content dangerouslySetInnerHTML={{ __html: step }} />
          </div>,
        );
      });
    }
    return renderedList;
  };

  const showInstructions = () => {
    setViewInstructions(true);
  };

  const revertChanges = () => {
    setConfirmRevert(true);
  };

  // Networking
  const loadUnit = async () => {
    try {
      setIsLoading(true);
      const res = await apiService(`/unit/${id}`);
      const json = await res.json();
      const unitMapped = plainToClass(Unit, json.data);

      // Parse address '123 X Street, Chicago, IL 60605, USA'

      if (unitMapped?.address?.split(',') !== undefined) {
        if (unitMapped.address?.split(',').length > 1) {
          const city = unitMapped.address?.split(',')[1].replace(' ', '');
          unitMapped.city = city;
        }
        if (unitMapped.address?.split(',').length >= 2) {
          const stateZip = unitMapped.address?.split(',')[2].replace(' ', '');
          if (stateZip.split(' ').length > 1) {
            const state = stateZip.split(' ')[0];
            const zip = stateZip.split(' ')[1];
            unitMapped.state = state;
            unitMapped.zip = zip;
            setAddressState(state);
          } else {
            const zip = stateZip.split(' ')[0];
            unitMapped.zip = zip;
          }
        }
      }

      setUnit(unitMapped);
      setPreviousUnit(unitMapped);
      setCalendarLink(unitMapped?.calendarLink?.url ?? '');
      setLinenRental(unitMapped.hasLinenRental());
      setAddress(unitMapped.address?.split(',')[0] ?? '');
      setApt(unitMapped.apt ?? '');
      setCity(unitMapped.city ?? '');
      setZip(unitMapped.zip ?? '');
      setBedrooms(unitMapped.numBedrooms ?? 0);
      setBathrooms(unitMapped.numBathrooms ?? 0);
      setWifiName(unitMapped.wifiName ?? '');
      setWifiPass(unitMapped.wifiPass ?? '');
      setGarbageInstructions(unitMapped.garbageLocationInstructions ?? '');
      setGarbageLocationPhotos(unitMapped.garbageLocationPhotoUrls);
      setMainEntrancePhotos(unitMapped.mainEntrancePhotoUrls);
      setBedroomPhotos(unitMapped.bedroomPhotoUrls);
      setLivingRoomPhotos(unitMapped.livingRoomPhotoUrls);
      setBathroomPhotos(unitMapped.bathroomPhotoUrls);
      setKitchenPhotos(unitMapped.bathroomPhotoUrls);
      setLockboxLocationPhotos(unitMapped.lockboxLocationPhotoUrls);
      setBuildingKeypadPhotos(unitMapped.buildingKeypadPhotoUrls);
      setDoorCodes(unitMapped.doorCodes);
      setDoorCodesField(htmlDecode(unitMapped.doorCodesField ?? ''));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const saveChanges = async () => {
    /*
    if (importantInfoMissing() || unitInfoMissing() || doorCodes.length === 0) {
      alert(
        'Fields under Important Settings and Unit Information are required. You must also have at least 1 door code.',
      );
      return;
    }*/

    let unitForUpdate = {} as any;
    unitForUpdate.id = unit?.id;

    // Form Fields
    unitForUpdate.calendarLink = calendarLink;
    unitForUpdate.linenRentalStatus = linenRental
      ? LinenRentalStatus.YES
      : LinenRentalStatus.NO;
    unitForUpdate.address = addressState
      ? `${address}, ${city}, ${addressState} ${zip}`
      : `${address}, ${city}, ${zip}`;
    unitForUpdate.apt = apt;
    unitForUpdate.city = city;
    unitForUpdate.zip = zip;
    unitForUpdate.numBathrooms = bathrooms;
    unitForUpdate.numBedrooms = bedrooms;
    unitForUpdate.wifiName = wifiName;
    unitForUpdate.doorCodesField = doorCodesField;
    unitForUpdate.wifiPass = wifiPass;
    unitForUpdate.garbageLocationInstructions = garbageInstructions;

    // Diff old record and new record.
    unitForUpdate = diff(unit as object, unitForUpdate);
    unitForUpdate = _.omitBy(unitForUpdate, _.isNil);

    unitForUpdate.mainEntrancePhotoUrls = mainEntrancePhotos;
    unitForUpdate.bedroomPhotoUrls = bedroomPhotos;
    unitForUpdate.livingRoomPhotoUrls = livingRoomPhotos;
    unitForUpdate.bathroomPhotoUrls = bathroomPhotos;
    unitForUpdate.lockboxLocationPhotoUrls = lockboxLocationPhotos;
    unitForUpdate.buildingKeypadPhotoUrls = buildingKeypadPhotos;
    unitForUpdate.garbageLocationPhotoUrls = garbageLocationPhotos;
    unitForUpdate.doorCodes = doorCodes;

    if (!formHasChanges(unit, unitForUpdate)) return;

    try {
      setSubmitting(true);
      const res = await apiService(`/unit/${unit?.id}`, 'put', unitForUpdate);
      if (!res.ok) {
        console.error(res.status);
      }
    } catch (e) {
      console.error(e);
    }
    setSubmitting(false);
    setChangesMade(false);
  };

  React.useEffect(() => {
    loadUnit();
  }, []);

  // Render

  return (
    <React.Fragment>
      <Prompt
        when={changesMade}
        message="There are unsaved changes. Continue and lose changes?"
      />
      <Container fluid>
        <Modal
          show={confirmRevert || viewInstructions}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {confirmRevert && <>Revert Changes?</>}
              {viewInstructions && (
                <>{`${unit?.calendarProvider} Instructions`}</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confirmRevert && (
              <p>
                This will remove all changes you&apos;ve made to unit settings.
              </p>
            )}
            {viewInstructions && <>{instructionsForAccount()}</>}
          </Modal.Body>
          <Modal.Footer>
            {confirmRevert && (
              <Button
                variant="primary"
                onClick={() => {
                  loadUnit();
                  setConfirmRevert(false);
                }}
              >
                Revert my changes
              </Button>
            )}
            <Button
              variant="outline-secondary"
              onClick={() => {
                if (confirmRevert) setConfirmRevert(false);
                if (viewInstructions) setViewInstructions(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <SectionHeader>Settings for {unit?.name}</SectionHeader>
        <UnitContainer>
          <LeftPanel>
            <StyledLink exact to={`/unit/${id}`} activeClassName="unitActive">
              Activity
            </StyledLink>
            <StyledLink
              exact
              to={`/unit/${id}/settings`}
              activeClassName="unitActive"
            >
              Details
            </StyledLink>
          </LeftPanel>
          <RightPanel>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {/* <FormItemLeft>
                  <FormName>Linen Rental</FormName>
                  <BootstrapSwitchButton
                    checked={linenRental}
                    onlabel="On"
                    offlabel="Off"
                    onstyle="secondary"
                    offstyle="info"
                    onChange={(checked: boolean) => {
                      setChangesMade(true);
                      setLinenRental(checked);
                    }}
                  />
                </FormItemLeft> */}
                <FormHeader alertMessage={unitInformationMissing()}>
                  Unit Information
                </FormHeader>
                <FormItem>
                  <FormNameGrow>Address</FormNameGrow>
                  <SettingsFormField
                    value={address}
                    readOnly
                    showError={isBlank(address)}
                    onChange={(e: any) => {
                      setChangesMade(true);
                      setAddress(e.target.value);
                    }}
                  />
                </FormItem>
                <FormItem>
                  <FormNameGrow>Unit #</FormNameGrow>
                  <SettingsFormField
                    value={apt}
                    readOnly
                    onChange={(e: any) => {
                      setChangesMade(true);
                      setApt(e.target.value);
                    }}
                  />
                </FormItem>
                <FormRow>
                  <FormItemSmall>
                    <FormNameGrow>City</FormNameGrow>
                    <SettingsFormField
                      small
                      value={city}
                      readOnly
                      showError={isBlank(city)}
                      onChange={(e: any) => {
                        setChangesMade(true);
                        setCity(e.target.value);
                      }}
                    />
                  </FormItemSmall>
                  <FormItemSmall style={{ marginLeft: '30px' }}>
                    <FormNameGrow>Zip</FormNameGrow>
                    <SettingsFormField
                      small
                      value={zip}
                      readOnly
                      showError={isBlank(zip)}
                      onChange={(e: any) => {
                        setChangesMade(true);
                        setZip(e.target.value);
                      }}
                    />
                  </FormItemSmall>
                </FormRow>
                <FormRow>
                  <FormItemSmall>
                    <FormNameGrow>Bedrooms</FormNameGrow>
                    <SettingsFormField
                      small
                      value={`${bedrooms}`}
                      readOnly
                      showError={bedrooms === 0}
                      onChange={(e: any) => {
                        setChangesMade(true);
                        setBedrooms(Number(e.target.value));
                      }}
                    />
                  </FormItemSmall>
                  <FormItemSmall style={{ marginLeft: '30px' }}>
                    <FormNameGrow>Bathrooms</FormNameGrow>
                    <SettingsFormField
                      small
                      value={`${bathrooms}`}
                      readOnly
                      showError={bathrooms === 0}
                      onChange={(e: any) => {
                        setChangesMade(true);
                        setBathrooms(Number(e.target.value));
                      }}
                    />
                  </FormItemSmall>
                </FormRow>
                <InfoText>
                  * If you want to change above information please{' '}
                  <a href="mailto:team@cleancio.com">contact us</a>
                </InfoText>
                <FormItem>
                  <FormNameGrow>Wifi</FormNameGrow>
                  <div style={{ flexGrow: 1 }}>
                    <SettingsFormField
                      value={wifiName}
                      showError={isBlank(wifiName)}
                      placeholder="Network name"
                      onChange={(e: any) => {
                        setChangesMade(true);
                        setWifiName(e.target.value);
                      }}
                    />
                    <SettingsFormField
                      value={wifiPass}
                      showError={isBlank(wifiPass)}
                      placeholder="Password"
                      style={{ marginTop: '30px' }}
                      onChange={(e: any) => {
                        setChangesMade(true);
                        setWifiPass(e.target.value);
                      }}
                    />
                  </div>
                </FormItem>
                <FormHeader alertMessage={doorCodesAndKeysMissing()}>
                  Door Codes &amp; Keys
                </FormHeader>
                <FormRow>
                  <FormItem>
                    <FormNameGrow>Lockbox Location Photo</FormNameGrow>
                    <ImageRow
                      photos={lockboxLocationPhotos}
                      singlePhoto
                      setImages={(i: any) => {
                        setChangesMade(true);
                        setLockboxLocationPhotos(i);
                      }}
                    />
                  </FormItem>
                  <FormItem style={{ marginLeft: '70px' }}>
                    <FormNameGrow>Building Keypad Photo</FormNameGrow>
                    <ImageRow
                      photos={buildingKeypadPhotos}
                      singlePhoto
                      setImages={(i: any) => {
                        setChangesMade(true);
                        setBuildingKeypadPhotos(i);
                      }}
                    />
                  </FormItem>
                </FormRow>
                <FormRow>
                  <FormItem>
                    <FormName>Door code</FormName>
                    <SettingsFormField
                      value={doorCodesField}
                      renderTextArea
                      onChange={(e: any) => {
                        setChangesMade(true);
                        setDoorCodesField(e.target.value);
                      }}
                      style={{
                        flexGrow: 1,
                        boxShadow: '0 8px 19px 2px #f1f1f1',
                        fontSize: '16px',
                        color: '000000',
                        minHeight: '100px',
                        minWidth: '400px',
                        maxWidth: '420px',
                        resize: 'none',
                        overflowY: 'auto',
                      }}
                    />
                  </FormItem>
                </FormRow>
                {/* <BlueTableHeader>
                  <TableSection>Type</TableSection>
                  <TableSection style={{ width: '35%' }}>
                    Instructions
                  </TableSection>
                  <TableSection>Building Code</TableSection>
                  <TableSection>Door Code</TableSection>
                </BlueTableHeader>
                <BlueTableContent>{doorCodeContents()}</BlueTableContent>
                <AddTableRowButton onClick={addRowItem}>
                  Add&nbsp;+
                </AddTableRowButton> */}
                <FormHeader>Unit Photos</FormHeader>
                <FormSubhead>
                  Please add photos for all rooms. This helps our housekeeping
                  team to stage your unit according to your specifications and
                  to report any lost or missing items.
                </FormSubhead>
                <FormItem>
                  <FormNameGrow>Main Entrance</FormNameGrow>
                  <ImageRow
                    photos={mainEntrancePhotos}
                    setImages={(i: any) => {
                      setChangesMade(true);
                      setMainEntrancePhotos(i);
                    }}
                  />
                </FormItem>
                <FormItem>
                  <FormNameGrow>Bedroom</FormNameGrow>
                  <ImageRow
                    photos={bedroomPhotos}
                    setImages={(i: any) => {
                      setChangesMade(true);
                      setBedroomPhotos(i);
                    }}
                  />
                </FormItem>
                <FormItem>
                  <FormNameGrow>Bathroom</FormNameGrow>
                  <ImageRow
                    photos={bathroomPhotos}
                    setImages={(i: any) => {
                      setChangesMade(true);
                      setBathroomPhotos(i);
                    }}
                  />
                </FormItem>
                <FormItem>
                  <FormNameGrow>Kitchen</FormNameGrow>
                  <ImageRow
                    photos={kitchenPhotos}
                    setImages={(i: any) => {
                      setChangesMade(true);
                      setKitchenPhotos(i);
                    }}
                  />
                </FormItem>
                <FormItem>
                  <FormNameGrow>Living Room</FormNameGrow>
                  <ImageRow
                    photos={livingRoomPhotos}
                    setImages={(i: any) => {
                      setChangesMade(true);
                      setLivingRoomPhotos(i);
                    }}
                  />
                </FormItem>
                <FormItem>
                  <FormNameGrow>Garbage Instructions</FormNameGrow>
                  <SettingsFormField
                    value={garbageInstructions}
                    showError={garbageInstructions.length === 0}
                    renderTextArea
                    onChange={(e: any) => {
                      setChangesMade(true);
                      setGarbageInstructions(e.target.value);
                    }}
                    style={{
                      flexGrow: 1,
                      boxShadow: '0 8px 19px 2px #f1f1f1',
                      fontSize: '16px',
                      color: '000000',
                      minHeight: '42px',
                      minWidth: '400px',
                      maxWidth: '420px',
                      resize: 'none',
                      overflowY: 'auto',
                    }}
                  />
                </FormItem>
                <FormItem>
                  <FormNameGrow>Garbage Location Photo</FormNameGrow>
                  <ImageRow
                    photos={garbageLocationPhotos}
                    setImages={(i: any) => {
                      setChangesMade(true);
                      setGarbageLocationPhotos(i);
                    }}
                  />
                </FormItem>
                <FormHeader>Additional Settings</FormHeader>
                {/* <FormRow>
                  <FormItemSmall>
                    <FormNameGrow>
                      Check out
                      <br />
                      Time
                    </FormNameGrow>
                    <StaticField>11:00 am</StaticField>
                  </FormItemSmall>
                  <FormItemSmall>
                    <FormNameGrow>
                      Check in
                      <br />
                      Time
                    </FormNameGrow>
                    <StaticField>4:00 pm</StaticField>
                  </FormItemSmall>
                </FormRow> */}
                <GroupedForm>
                  <FormItem>
                    <FormNameGrow>Calendar Link</FormNameGrow>
                    <SettingsFormField
                      value={calendarLink}
                      showError={!isUrlValid(calendarLink)}
                      placeholder="Enter url"
                      onChange={(e: any) => {
                        setChangesMade(true);
                        setCalendarLink(e.target.value);
                      }}
                    />
                  </FormItem>
                  {unit?.calendarProvider && (
                    <Button
                      variant="link"
                      style={{
                        right: '0px',
                        position: 'absolute',
                        bottom: '0px',
                      }}
                      onClick={showInstructions}
                    >
                      View instructions
                    </Button>
                  )}
                </GroupedForm>
                <CommitChangesContainer>
                  <GrowableButton
                    variant="primary"
                    disabled={submitting || !changesMade}
                    style={{ marginRight: '24px' }}
                    onClick={saveChanges}
                    loading={submitting}
                    loaderVariant="light"
                  >
                    Save changes
                  </GrowableButton>
                  <GrowableButton
                    variant="outline-primary"
                    disabled={submitting || !changesMade}
                    onClick={revertChanges}
                  >
                    Revert changes
                  </GrowableButton>
                </CommitChangesContainer>
              </>
            )}
          </RightPanel>
        </UnitContainer>
      </Container>
    </React.Fragment>
  );
};

export default UnitSettings;
