import { plainToClassFromExist } from 'class-transformer';
import React, { useState } from 'react';
import styled from 'styled-components';
import Cleaning from '../../../../models/Cleaning';
import Comment from '../../../../models/Comment';
import ReportContainer from '../../../../models/ReportContainer';
import authData from '../../../../modules/services/authParams';
import CleaningType from '../../../cleaning/CleaningType';
import AuthImage from '../../../common/images/AuthImage';
import {
  ModalFooter,
  StyledButton,
} from '../../home/widgets/onboarding/modals/SelectAccounts';

// Props

interface ReportProps {
  cleaning?: Cleaning;
  type: string;
  onHide: () => void;
}

// Styles

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 800px;
`;

const MainHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding-top: 44px;
`;

const SectionHeader = styled.h2`
  font-size: 32px;
  font-weight: 600;
  margin: auto;
`;

const CleaningDateTime = styled.div`
  color: ${(props) => props.theme.brand};
  font-size: 18px;
  font-weight: 700;
  margin: auto;
`;

const CleaningIconAndName = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 20px;
`;

const CleaningName = styled.div`
  margin-left: 4px;
  font-size: 16px;
  font-weight: 600;
`;

const PhotoArea = styled.div`
  margin: auto;
  overflow-y: auto;
  width: 630px;
  height: 500px;
  padding-right: 40px;
`;

const PhotoHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.brand};
  display: flex;
  justify-items: stretch;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 40px;
`;

const Line = styled.div`
  margin-left: 20px;
  height: 1px;
  background-color: ${(props) => props.theme.brand};
  flex-grow: 1;
`;

const PhotoGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: start;
`;

const SmallPhoto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Component

const ReportsViewer: React.FC<ReportProps> = ({
  cleaning,
  type,
  onHide,
}: ReportProps) => {
  const [reportContainer, setReportContainer] = useState<ReportContainer>();

  const loadReports = async () => {
    const auth = await authData();
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/cleaning/${cleaning?.id}/comments?type=reports&limit=100`,
      {
        headers: {
          accessToken: auth.accessToken,
        },
      },
    )
      .then((res) => res.json())
      .then((response) => {
        try {
          const defaultContainer = new ReportContainer();
          const reportContainer = plainToClassFromExist(
            defaultContainer,
            response.data,
          );
          setReportContainer(reportContainer);
        } catch (e) {
          console.error(e);
        }
      });
  };

  React.useEffect(() => {
    loadReports();
  }, []);

  const reportSections = () => {
    const sections: JSX.Element[] = [];

    sections.push(
      photoForSection(reportContainer?.broken, 'BROKEN'),
      photoForSection(reportContainer?.guests, 'GUEST IN UNIT'),
      photoForSection(reportContainer?.restock, 'RESTOCK'),
      photoForSection(reportContainer?.other, 'OTHER'),
    );

    return sections;
  };

  const photoSections = (): JSX.Element[] => {
    const sections: JSX.Element[] = [];

    sections.push(
      photoForSection(
        reportContainer?.cleaningPhotos?.bedroomOrBathroom,
        'BEDROOM / BATHROOM',
      ),
      photoForSection(reportContainer?.cleaningPhotos?.kitchen, 'KITCHEN'),
      photoForSection(reportContainer?.cleaningPhotos?.living, 'LIVING'),
      photoForSection(reportContainer?.cleaningPhotos?.other, 'OTHER'),
    );

    return sections;
  };

  const photoForSection = (
    section: Comment[] | undefined,
    title: string,
  ): JSX.Element => {
    const sectionPhotos: JSX.Element[] = [];
    section?.map((comment, index) => {
      const subCategory = comment.getSubCategory();
      comment?.photoUrls.forEach((url) => {
        sectionPhotos.push(
          <SmallPhoto key={index + Math.random() + 1000}>
            <AuthImage
              url={url}
              style={{ width: '100px', height: '100px', borderRadius: '8px' }}
            />
            {type === 'cleaning' ? '' : subCategory}
          </SmallPhoto>,
        );
      });
    });

    return (
      <div key={title}>
        {sectionPhotos.length > 0 && (
          <>
            <PhotoHeader>
              {title} <Line />
            </PhotoHeader>
            <PhotoGrid>{sectionPhotos}</PhotoGrid>
          </>
        )}
      </div>
    );
  };

  // Render

  return (
    <MainContainer>
      <MainHeader>
        <SectionHeader>
          {type === 'cleaning' ? 'Cleaning Photos' : 'Reports'}
        </SectionHeader>
        <CleaningIconAndName>
          <CleaningType cleaning={cleaning} />
          <CleaningName style={{ color: cleaning?.color() }}>
            {cleaning?.cleaningName()}
          </CleaningName>
        </CleaningIconAndName>
        <CleaningDateTime>{cleaning?.formattedDate()}</CleaningDateTime>
      </MainHeader>
      <PhotoArea>
        {type === 'cleaning' ? photoSections() : reportSections()}
      </PhotoArea>
      <ModalFooter>
        <StyledButton onClick={onHide}>Close</StyledButton>
      </ModalFooter>
    </MainContainer>
  );
};

export default ReportsViewer;
