import { initializeApp } from 'firebase/app';
import {
  MessagePayload,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import authData from '../services/authParams';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
async function updateToken(token: string): Promise<void> {
  console.log('Sending new fcm token to server...');
  const { accessToken } = await authData();
  await fetch(`${process.env.REACT_APP_API_BASE_URL}/me/notification-id`, {
    method: 'POST',
    headers: {
      accessToken: accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      notificationId: token,
    }),
    mode: 'cors',
  });
}

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export function fetchToken(): void {
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        updateToken(currentToken);
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.',
        );
        // Get permission and try again
        requestPermission();
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
}

function requestPermission() {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      fetchToken();
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
}

export const onMessageListener = (): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
