import React from 'react';
import styled from 'styled-components/macro';
import spinnerSvg from '../../../images/spinner.svg';

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 550px;
  min-width: 300px;
  min-height: 200px;
`;

const SpinnerIcon = styled.img.attrs({
  src: spinnerSvg,
})`
  width: 40px;
  height: 40px;
`;

const Spinner: React.FC = () => (
  <SpinnerWrapper>
    <SpinnerIcon />
  </SpinnerWrapper>
);

export default Spinner;
