import 'reflect-metadata';
import { Expose } from 'class-transformer';
import { RowContentTransformable } from './DoorCode';
import { RowContent } from '../components/common/ui/EditableTableRow';

export default class ClientAgreement implements RowContentTransformable {
  @Expose() id?: string;
  @Expose() name?: string;
  @Expose() link?: string;

  // RowContentTransformable

  getAsStrings(): string[] {
    const memberContents: string[] = [this.name ?? '', this.link ?? ''];
    return memberContents;
  }

  setFromStrings(strings: string[]): ClientAgreement {
    this.name = strings[0];
    this.link = strings[1];

    return this;
  }

  getAsRowContents(): RowContent[] {
    const userContents: RowContent[] = [
      {
        content: this.name ?? '',
        placeholder: 'Name of document',
      },
      {
        content: this.link ?? '',
        placeholder: 'Valid URL to client agreement',
        nonEditingState: this.link ? 'Added' : 'Missing',
      },
    ];
    return userContents;
  }

  setFromRowContents(rowContents: RowContent[]): ClientAgreement {
    this.name = rowContents[0].content;
    this.link = rowContents[1].content;

    return this;
  }
}
