import { plainToClassFromExist } from 'class-transformer';
import React, { useContext, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';
import Cleaning from '../../../../models/Cleaning';
import Comment from '../../../../models/Comment';
import CommentContainer from '../../../../models/CommentContainer';
import { apiService } from '../../../../modules/services/apiService';
import authData from '../../../../modules/services/authParams';
import { isBlank } from '../../../../modules/utils/validation';
import { UserContext, GlobalContext } from '../../../base/App';
import AuthImage from '../../../common/images/AuthImage';
import { ReactComponent as EditIcon } from '../../../../images/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../images/trash-icon.svg';
import ProgressBar from 'react-bootstrap/ProgressBar';

// Props

interface CommentsModuleProps {
  cleaning: Cleaning;
}

// Styles

const PreviousCommentsSection = styled.div`
  font-size: 14px;
`;

const CommentModuleContainer = styled.div`
  width: 100%;
`;

const CommentArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
`;

const CommentCard = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 20px;
`;

const CommentLeft = styled.div``;

const Avatar = styled.div`
  width: 33px;
  height: 33px;
  margin-right: 10px;
`;
const SenderName = styled.div`
  font-size: 12px;
`;

const CommentRight = styled.div`
  flex-grow: 1;
  position: relative;
`;

const CommentDate = styled.div`
  position: absolute;
  font-size: 10px;
  right: 8px;
  bottom: 4px;
`;

const Message = styled.div`
  border-radius: 6px;
  background-color: #ebf7f9;
  padding: 10px;
  padding-bottom: 24px;
  width: 100%;
`;

const AddComment = styled.div`
  display: flex;
`;

const CommentField = styled.div`
  width: 100%;
`;

const Posting = styled(Spinner)`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const FormGroup = styled(Form.Group)`
  position: relative;
`;

const CommentActions = styled.div`
  width: 35px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const EditButton = styled(EditIcon)`
  width: 14px;
  color: #75aeb7;
  cursor: pointer;

  &:hover {
    color: #95d2dc;
  }
`;

const DeleteButton = styled(DeleteIcon)`
  width: 14px;
  color: #75aeb7;
  cursor: pointer;

  &:hover {
    color: #95d2dc;
  }
`;

const CommentLoader = styled(ProgressBar)`
  height: 4px;
  position: absolute;
  top: 0;
  width: 100%;
`;

interface IResponseComment {
  photoUrls: string[];
}

// Component

const CommentsModule: React.FC<CommentsModuleProps> = ({
  cleaning,
}: CommentsModuleProps) => {
  const { currentUser } = useContext(UserContext);
  const [numComments, setNumComments] = useState<number>(0);
  const [userComment, setUserComment] = useState<string>('');
  const [comments, setComments] = useState<Comment[]>([]);
  const [posting, setPosting] = useState<boolean>(false);
  const [loadingComment, setLoadingComment] = useState<string[]>([]);
  const [activeComment, setActiveComment] = useState<string>('');

  const commentRef = useRef<HTMLTextAreaElement>(null);

  // Methods

  const previousComments = (): JSX.Element => {
    if (cleaning.commentCount === undefined) {
      return <></>;
    }
    if (comments.length >= numComments) {
      return <></>;
    }
    return (
      <PreviousCommentsSection>
        <Button
          variant="link"
          className="nav-link-grey"
          onClick={() => {
            loadComments();
          }}
        >
          {`See previous ${numComments - 1} comments`}
        </Button>
      </PreviousCommentsSection>
    );
  };

  const updatePhotoUrls = async (comments: any) => {
    const promises = comments.map(async (comment: IResponseComment) => {
      const photoUrls = [];

      if (!comment.photoUrls.length) {
        comment.photoUrls = [];
        return comment;
      }

      if (!comment.photoUrls[0].startsWith('https://files.podio.com')) {
        photoUrls.push(comment.photoUrls[0]);
        comment.photoUrls = photoUrls;
        return comment;
      }

      const res = await apiService(`/getFile?url=${comment.photoUrls[0]}`);
      const blob = await res.blob();
      const objectURL = URL.createObjectURL(blob);
      photoUrls.push(objectURL);
      comment.photoUrls = photoUrls;
      return comment;
    });
    return await Promise.all(promises);
  };

  const loadComments = async (onlyLastComment = false) => {
    const auth = await authData();

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/cleaning/${cleaning.id}/comments?type=comments`,
      {
        headers: {
          accessToken: auth.accessToken,
        },
      },
    )
      .then((res) => res.json())
      .then(async (response) => {
        try {
          const defaultContainer = new CommentContainer();

          response.data.comments = await updatePhotoUrls(
            response.data.comments,
          );

          const commentContainer = plainToClassFromExist(
            defaultContainer,
            response.data,
          );
          const commentCount = commentContainer.comments.length;
          let newComments: Comment[] = [...commentContainer.comments];
          if (onlyLastComment) {
            const lastComment = commentContainer.comments[commentCount - 1];
            if (lastComment?.id !== currentUser?.id) {
              newComments = [...comments, lastComment];
            }
          }

          if (onlyLastComment) {
            setNumComments(numComments + 1);
          } else {
            setNumComments(newComments.length);
          }
          setComments(newComments);
        } catch (e) {
          console.error(e);
        }
      });
  };

  const addComment = (): JSX.Element => {
    if (currentUser === undefined || currentUser.photoUrls === undefined) {
      return <></>;
    }
    return (
      <AddComment>
        <Avatar>
          <AuthImage
            avatar
            url={
              currentUser?.photoUrls?.length > 0
                ? currentUser?.photoUrls[0]
                : ''
            }
            key={`avatar-photo-current-user-${Math.random() * 1000}`}
            style={{ width: '33px', height: '33px', borderRadius: '50%' }}
          />
        </Avatar>
        <CommentField>
          <FormGroup controlId="commentTextarea">
            <Form.Control
              ref={commentRef}
              type="text"
              as="textarea"
              maxLength={240}
              style={{ resize: 'none', height: '40px', overflowY: 'auto' }}
              value={userComment}
              placeholder="Add a comment"
              onChange={(event: any) => {
                setUserComment(event.target.value);
              }}
              onKeyPress={submitComment}
              disabled={posting}
            />
            {posting && (
              <Posting animation="border" variant="primary" size="sm" />
            )}
          </FormGroup>
        </CommentField>
      </AddComment>
    );
  };

  const submitComment = (event: any): void => {
    if (isBlank(userComment)) return;
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      const body = {
        comment: userComment,
        userId: currentUser?.id,
        userName: currentUser?.fullName(),
      };
      setPosting(true);

      if (activeComment) {
        editComment(activeComment, body);
      } else {
        apiService(`/cleaning/${cleaning.id}/comments`, 'post', body)
          .then((res) => res.json())
          .then((response) => {
            try {
              const comment = new Comment();
              const newComment = plainToClassFromExist(comment, response.data);
              setUserComment('');
              setNumComments(numComments + 1);
              setComments([...comments, newComment]);
            } catch (e) {
              console.error(e);
            } finally {
              setPosting(false);
            }
          });
      }
    }
  };

  const deleteComment = async (commentId: string | undefined) => {
    if (!commentId) return;

    setLoadingComment([...loadingComment, commentId]);

    const response = await apiService(
      `/cleaning/${cleaning.id}/comments/${commentId}`,
      'delete',
    );
    const { error } = await response.json();

    if (error) {
      console.error(error);
    } else {
      setComments((comments) => comments.filter((c) => c.id !== commentId));
    }
    setLoadingComment((loaders) => loaders.filter((c) => c !== commentId));
  };

  const handleEditClick = (commentId: string | undefined) => {
    if (!commentId) return;

    const commentToEdit = comments.find((c) => c.id === commentId);
    if (commentToEdit) {
      setActiveComment(commentId);
      setUserComment(commentToEdit?.message ?? '');
      commentRef.current?.focus();
    }
  };

  const editComment = async (commentId: string | undefined, body: any) => {
    if (!commentId) return;

    setLoadingComment([...loadingComment, commentId]);

    const response = await apiService(
      `/cleaning/${cleaning.id}/comments/${commentId}`,
      'PATCH',
      body,
    );
    const { error } = await response.json();

    if (error) {
      console.error(error);
    } else {
      const oldComment = comments.find((c) => c.id === commentId);
      const newComment = plainToClassFromExist(new Comment(), oldComment);
      newComment.message = body.comment;
      setComments((comments) =>
        comments.map((c) => (c.id === commentId ? newComment : c)),
      );
    }
    setActiveComment('');
    setUserComment('');
    setLoadingComment((loaders) => loaders.filter((c) => c !== commentId));
    setPosting(false);
  };

  // Networking

  // Set initial state

  React.useEffect(() => {
    if (cleaning.lastComment && cleaning.commentCount) {
      setComments([cleaning.lastComment]);
      setNumComments(cleaning.commentCount);
    }
  }, [cleaning]);

  // Component

  return (
    <CommentModuleContainer>
      {previousComments()}
      <CommentArea>
        {comments.map((comment) => (
          <CommentCard key={comment.createdDate?.toString()}>
            <CommentLeft>
              <Avatar>
                <AuthImage
                  avatar
                  url={
                    comment.avatar ??
                    (comment?.photoUrls?.length > 0 ? comment.photoUrls[0] : '')
                  }
                  style={{ width: '33px', height: '33px', borderRadius: '50%' }}
                />
              </Avatar>
              <SenderName>{comment.getSenderFirstName()}</SenderName>
            </CommentLeft>
            <CommentRight>
              <Message>{comment.message}</Message>
              <CommentDate>{comment.getAgo()}</CommentDate>
              {loadingComment.includes(comment.id as string) && (
                <CommentLoader variant="primary" animated now={100} />
              )}
            </CommentRight>
            {comment.createdBy === currentUser?.id &&
              !loadingComment.includes(comment.id as string) && (
                <CommentActions>
                  <EditButton onClick={() => handleEditClick(comment.id)} />
                  <DeleteButton onClick={() => deleteComment(comment.id)} />
                </CommentActions>
              )}
          </CommentCard>
        ))}
        {addComment()}
      </CommentArea>
    </CommentModuleContainer>
  );
};

export default CommentsModule;
