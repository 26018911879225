import React from 'react';
import styled from 'styled-components';
import { CalendarProps } from '../calendar/Calendar';
import media from 'styled-media-query';
import CleaningInfoRow from '../../../../cleaning/CleaningInfoRow';

// Styles

const SectionHeader = styled.h2`
  font-size: 18px;
  font-weight: 600;
`;

const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 40px;
  padding: 30px;
  box-shadow: 0 8px 19px 2px ${(props) => props.theme.shadow};
  border-radius: 6px;
  min-height: 200px;
  ${media.greaterThan('large')`
    min-height: 400px;
  `}
`;

const ServiceHeader = styled.div`
  display: flex;
  justify-content: stretch;
`;

const ServiceColumn = styled.div`
  flex-grow: 1;
  text-align: center;
  font-style: italic;
`;

const ServiceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const ServiceColumnAddons = styled(ServiceColumn)`
  ${media.lessThan('medium')`
    display: none;
  `}
`;

const EmptyState = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: center;
  font-weight: 700;
  color: ${(props) => props.theme.emptyState};
`;

// Component

const UpcomingServices: React.FC<CalendarProps> = ({
  schedule,
}: CalendarProps) => {
  const emptyState = (): JSX.Element | null => {
    if (schedule?.futureCleanings().length === 0) {
      return <EmptyState>No upcoming services</EmptyState>;
    }
    return null;
  };

  return (
    <ModuleContainer>
      <SectionHeader>Upcoming Services</SectionHeader>
      <ServiceHeader>
        <ServiceColumn style={{ width: '48%' }}>
          <div style={{ width: '200px' }}>Unit</div>
        </ServiceColumn>
        <ServiceColumn style={{ width: '52%' }}>Cleaning Time</ServiceColumn>
      </ServiceHeader>
      {emptyState()}
      <ServiceContainer>
        {schedule?.futureCleanings().map((cleaning, index) => {
          return (
            <CleaningInfoRow
              key={index}
              label={cleaning.unit?.name}
              cleaning={cleaning}
            />
          );
        })}
      </ServiceContainer>
    </ModuleContainer>
  );
};

export default UpcomingServices;
