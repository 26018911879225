import 'reflect-metadata';
import { Expose } from 'class-transformer';
import { ISelectable } from '../interfaces/interfaces';

export default class VRService implements ISelectable {
  @Expose()
  id = '';
  @Expose()
  name?: string;
  @Expose()
  width = 0;
  @Expose()
  height = 0;
  @Expose()
  logo = '';
  @Expose()
  instructions?: string[];
}
