import 'reflect-metadata';
import Comment from './Comment';
import { Expose, Type } from 'class-transformer';
import CleaningPhotosContainer from './CleaningPhotosContainer';

export default class ReportContainer {
  @Expose()
  unitID?: string;

  @Expose()
  cleaningID?: string;

  @Expose()
  totalReports = 0;

  @Type(() => Comment)
  @Expose()
  broken: Comment[] = [];

  @Type(() => Comment)
  @Expose()
  guests: Comment[] = [];

  @Type(() => Comment)
  @Expose()
  restock: Comment[] = [];

  @Type(() => Comment)
  @Expose()
  other: Comment[] = [];

  @Type(() => CleaningPhotosContainer)
  @Expose()
  cleaningPhotos?: CleaningPhotosContainer;
}
