import React from 'react';
import Unit from '../../models/Unit';
import UnitBar from './UnitBar';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

// Props

interface UnitNameProps {
  unit?: Unit;
  style?: React.CSSProperties;
  bar?: boolean;
}

// Styles

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UnitName = styled.div`
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Component

const UnitNameWithBar: React.FC<UnitNameProps> = ({
  unit,
  style,
  bar = true,
}: UnitNameProps) => {
  return (
    <NameContainer>
      {bar && <UnitBar unit={unit} style={{ marginRight: '8px' }} />}
      <UnitName style={{ marginRight: isMobile ? '16px' : '50px', ...style }}>
        {unit?.name}
      </UnitName>
    </NameContainer>
  );
};

export default UnitNameWithBar;
