import React from 'react';
import './AuthAppState.scss';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from '../../../aws-exports';
import App from '../App';
import TopNav from '../../common/navigation/TopNav';
import styled from 'styled-components';
import { ReactComponent as LeafSvg } from '../../../images/graphic-bg-leaf.svg';
import { ReactComponent as PlantSvg } from '../../../images/graphic-bg-plant.svg';
import CustomLogin from './CustomLogin';

Amplify.configure(awsconfig);

const MainContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  display: grid;
  align-content: start;
`;

const ElementContainer = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0px;
  width: 100%;
  height: 100%;
`;

const LeafElement = styled(LeafSvg)`
  position: absolute;
  top: 180px;
  left: 0px;
`;

const PlantElement = styled(PlantSvg)`
  position: absolute;
  top: 200px;
  right: -80px;
`;

const AuthStateApp: React.FC = () => {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object | undefined>();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <App />
  ) : (
    <>
      <ElementContainer>
        <LeafElement />
        <PlantElement />
      </ElementContainer>
      <MainContainer>
        <TopNav blank />

        <AmplifyAuthenticator usernameAlias="email">
          <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp />
        </AmplifyAuthenticator>
      </MainContainer>
    </>
  );
};

export default AuthStateApp;
