import 'reflect-metadata';
import _ from 'lodash';
import moment from 'moment';
import { Expose, Type } from 'class-transformer';
import { RowContentTransformable } from './DoorCode';
import { RowContent } from '../components/common/ui/EditableTableRow';
import ClientAppUser from './ClientAppUser';
import ClientAgreement from './ClientAgreement';
import Unit from './Unit';

export default class ClientWorkspace implements RowContentTransformable {
  @Expose() id?: string;
  @Expose() tenantId?: string;
  @Expose() companyName = '';
  @Expose() workspaceURL = '';
  @Expose() title?: string;
  @Expose() accountEnabled = false;
  @Expose() calendarSyncEnabled = false;
  @Expose() podioConnected = false;
  @Expose() onboardingStatus?: number;
  @Expose() tier?: string;
  @Expose() timezone?: string;
  @Type(() => ClientAgreement)
  @Expose()
  agreements?: ClientAgreement[];
  @Type(() => ClientAppUser)
  @Expose()
  users?: ClientAppUser[];
  @Type(() => Unit)
  @Expose()
  units?: Unit[];
  @Type(() => Date)
  @Expose()
  createdAt?: Date;

  // Convenience

  status(): string {
    const formattedDate = moment(this.createdAt).format('MMM, DD YYYY');
    return `Created ${formattedDate}`;
  }

  enabled(): string {
    return this.accountEnabled ? 'Yes' : 'No';
  }

  activeUsers(): number {
    return this.users?.length ?? 0;
  }

  // RowContentTransformable

  getAsStrings(): string[] {
    const contents: string[] = [
      this.companyName,
      `${this.activeUsers()}`,
      this.status(),
      this.accountEnabled ? 'Yes' : 'No',
    ];
    return contents;
  }

  setFromStrings(strings: string[]): ClientWorkspace {
    this.companyName = strings[0];
    return this;
  }

  getAsRowContents(): RowContent[] {
    const clientContents: RowContent[] = [
      {
        content: this.companyName,
      },
      {
        content: `${this.activeUsers()}`,
        systemGenerated: true,
      },
      {
        content: this.status(),
        rawValue:
          moment(this.createdAt).format('YYYY-DD-MM') ??
          moment().format('YYYY-DD-MM'),
        systemGenerated: true,
      },
      {
        content: this.accountEnabled ? 'Yes' : 'No',
        rawValue: `${this.accountEnabled}`,
        systemGenerated: true,
      },
    ];
    return clientContents;
  }

  setFromRowContents(rowContents: RowContent[]): ClientWorkspace {
    this.companyName = rowContents[0].content;
    this.createdAt = moment(rowContents[2].rawValue).toDate();
    this.accountEnabled = rowContents[3].rawValue === 'true';

    return this;
  }
}
