import 'reflect-metadata';
import moment from 'moment';
import { Expose, Type } from 'class-transformer';

export default class Reservation {
  @Expose() id?: string;
  @Expose() calendarLink?: string;
  @Expose() calendarProvider?: string;
  @Type(() => Date)
  @Expose()
  startDate?: Date;
  @Type(() => Date)
  @Expose()
  endDate?: Date;

  numberOfDays(): number {
    const start = moment(this.startDate);
    const end = moment(this.endDate);

    return end.diff(start, 'days');
  }
}
