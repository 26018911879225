import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiServiceForm } from '../../../modules/services/apiService';
import AuthImage from '../images/AuthImage';

// Props

interface ImageRowProps {
  photos: string[];
  singlePhoto?: boolean;
  showChangeButton?: boolean;
  style?: React.CSSProperties;
  setImages: React.Dispatch<React.SetStateAction<string[]>>;
}

// Styles

const AddImage = styled.label`
  font-size: 16px;
  font-weight: 600;
  width: 104px;
  height: 107px;
  background-color: transparent;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.primary};
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ImageLabel = styled.label`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ChangeText = styled.div`
  color: ${(props) => props.theme.primary};
  font-weight: 700;
  width: 100%;
  text-align: center;
  margin-top: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const AddText = styled.div`
  width: 100%;
  color: ${(props) => props.theme.primary};
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
`;

// Component

const ImageRow: React.FC<ImageRowProps> = ({
  photos = [],
  singlePhoto = false,
  showChangeButton = false,
  style,
  setImages,
}: ImageRowProps) => {
  const fileInput = createRef<HTMLInputElement>();
  const [newPhotos, setNewPhotos] = useState<string[]>([]);
  const [latestUpload, setLatestUpload] = useState<any>();
  const [randomID, setRandomID] = useState<number>(
    Math.random() * 100000000000000,
  );

  // Upload Photos

  const handleFiles = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const target = e.target.id as string;
    const file = e.target.files[0];
    const newPhoto = URL.createObjectURL(file);
    if (singlePhoto && photos.length > 0) {
      setImages([newPhoto]);
      setRandomID(Math.random() * 100000000000000);
    } else {
      if (target.includes('add')) {
        setImages(photos.concat([newPhoto]));
      } else {
        const indexEnd = target.indexOf('-');
        const trimmedIndex = target.substring(0, indexEnd);
        const index = Number(trimmedIndex);
        photos[index] = newPhoto;
        setRandomID(Math.random() * 100000000000000);
      }
    }
    uploadPhoto(file, newPhoto);
  };

  const uploadPhoto = (file: any, newPhoto: string) => {
    if (file === undefined) return;
    const formData = new FormData();
    formData.append('unitPhoto', file, file.name);

    apiServiceForm('/uploadFile', formData)
      .then((res) => res.json())
      .then((response) => {
        setLatestUpload({
          fileUrl: response.fileUrl,
          fileId: response.fileId,
          newPhoto,
          file,
        });
      })
      .catch((error) => console.error(error));
  };

  // Render Photos
  const renderPhotos = (): JSX.Element => {
    const existingPhotos = photoElementsForArray(photos);

    return (
      <ImageContainer>
        {existingPhotos}
        {(!singlePhoto || existingPhotos.length === 0) && (
          <>
            <AddImage
              htmlFor={`add-${randomID}`}
              key={`add-${randomID}`}
              style={style}
            >
              <AddText>Add</AddText>
            </AddImage>
            <input
              hidden
              id={`add-${randomID}`}
              type="file"
              ref={fileInput}
              accept="image/*"
              onChange={handleFiles}
            />
          </>
        )}
      </ImageContainer>
    );
  };

  const photoElementsForArray = (array?: string[]): JSX.Element[] => {
    const photoElements: JSX.Element[] = [];
    array?.map((photo, index) => {
      photoElements.push(
        <div key={`${index}-${randomID}`}>
          <ImageLabel
            htmlFor={`${index}-${randomID}`}
            key={`${index}-${randomID}`}
          >
            <AuthImage
              url={photo}
              style={{
                width: '104px',
                height: '107px',
                borderRadius: '5px',
                marginRight: singlePhoto ? '0px' : '30px',
                marginBottom: singlePhoto ? '0px' : '30px',
                ...style,
              }}
            />
            {showChangeButton && <ChangeText>Change photo</ChangeText>}
            <input
              hidden
              id={`${index}-${randomID}`}
              type="file"
              ref={fileInput}
              accept="image/*"
              onChange={handleFiles}
            />
          </ImageLabel>
        </div>,
      );
    });
    return photoElements;
  };

  useEffect(() => {
    if (latestUpload) {
      photos.find((photoName, idx) => {
        if (photoName === latestUpload?.newPhoto) {
          photos[idx] = latestUpload?.fileUrl;
          setImages(photos);
        }
        setLatestUpload(undefined);
      });
    }
  }, [photos, latestUpload]);

  // Component

  return <>{renderPhotos()}</>;
};

export default ImageRow;
