import React from 'react';

import Form from 'react-bootstrap/Form';
import styled, { CSSProperties } from 'styled-components';

interface FormFieldProps {
  size?: string;
  type?: string;
  placeholder?: string;
  style?: CSSProperties;
  onChange: (e: any) => void;
}

const FormFieldContainer = styled.div`
  display: flex;
  justify-items: center;
  flex-grow: 1;
`;

const StyledFormControl = styled(Form.Control)`
  flex-grow: 1;
  box-shadow: 0 8px 19px 2px ${(props) => props.theme.shadow};
  font-size: 16px;
  min-height: 60px;
`;

const ShadowFormField: React.FC<FormFieldProps> = ({
  size = 'lg',
  type = 'text',
  placeholder = 'Add calendar link (https://www....)',
  style,
  onChange,
}: FormFieldProps) => {
  return (
    <FormFieldContainer style={style}>
      <StyledFormControl
        size={size}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
      />
    </FormFieldContainer>
  );
};

export default ShadowFormField;
