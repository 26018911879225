import React from 'react';
import Cleaning, {
  AssignmentType,
  CleaningStatus,
} from '../../models/Cleaning';
import { ReactComponent as RegularCleaningSvg } from '../../images/icon-cleaning-regular.svg';
import { ReactComponent as TurnoverCleaningSvg } from '../../images/icon-cleaning-turnover.svg';
import { ReactComponent as CleaningSvg } from '../../images/icon-cleaning.svg';
import { ReactComponent as GreenCheck } from '../../images/icon-check-complete.svg';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

interface CleaningTypeProps {
  cleaning?: Cleaning;
  style?: React.CSSProperties;
  circle?: boolean;
  outline?: boolean;
}

const IconContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
`;

const CleaningType: React.FC<CleaningTypeProps> = ({
  cleaning,
  style,
  circle = false,
  outline = false,
}: CleaningTypeProps) => {
  const shouldShowCircle = (): boolean => {
    return circle && !cleaning?.isComplete();
  };

  const isInprogressHotTurnover = (): boolean => {
    const isInProgress = cleaning?.status === CleaningStatus.IN_PROGRESS;
    return isInProgress && cleaning?.type === AssignmentType.HOT_TURNOVER;
  };

  const imageForType = (): JSX.Element => {
    if (cleaning?.isComplete()) {
      return (
        <GreenCheck
          style={{
            minWidth: isMobile || circle ? '16px' : '24px',
            minHeight: isMobile || circle ? '16px' : '24px',
            ...style,
          }}
        />
      );
    }
    switch (cleaning?.type) {
      case AssignmentType.HOT_TURNOVER:
        return (
          <TurnoverCleaningSvg
            style={{
              minWidth: isMobile || circle ? '14px' : '20px',
              minHeight: isMobile || circle ? '16px' : '24px',
              ...style,
            }}
          />
        );
      case AssignmentType.DEEP_CLEANING:
        return (
          <CleaningSvg
            style={{
              minWidth: isMobile || circle ? '14px' : '20px',
              minHeight: isMobile || circle ? '16px' : '24px',
              ...style,
            }}
          />
        );
      default:
        return (
          <RegularCleaningSvg
            style={{
              minWidth: isMobile || circle ? '14px' : '20px',
              minHeight: isMobile || circle ? '16px' : '24px',
              ...style,
            }}
          />
        );
    }
  };

  const cleaningColor = (): string => {
    return cleaning?.color() ?? '#7287F7';
  };

  return (
    <IconContainer
      style={{
        borderWidth: shouldShowCircle() ? '2px' : '0px',
        borderStyle: shouldShowCircle() ? 'solid' : 'none',
        borderColor: shouldShowCircle() ? cleaningColor() : 'transparent',
        outline:
          outline && isInprogressHotTurnover() ? '2px #f5c02c solid' : 'none',
      }}
    >
      {imageForType()}
    </IconContainer>
  );
};

export default CleaningType;
